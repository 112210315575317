import React from "react";
import Tooltip from "@material-ui/core/Tooltip";
import Button from "@material-ui/core/Button";

import styled from "styled-components";
import styles from "../../Styles";

const Container = styled.div`
  display: flex;
  align-items: center;
  background-color: white;
  min-height: 60px;
  width: 100%;
  margin-bottom: 40px;

  .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media (max-width: ${(props) => props.styles.screenSizes.medium}) {
      flex-direction: column;
      padding: 10px;
    }
  }

  .btn-ui {
    border-radius: 30px;
    font-size: 12px;
    font-weight: bold;
  }

  .container-weeked-date {
    display: flex;
    align-items: center;
    gap: 1rem;

    .date {
      color: ${(props) => props.styles.colors.secondary};
      font-weight: bold;
      font-size: 14px;
      text-align: center;

      @media (max-width: ${(props) => props.styles.screenSizes.desktop}) {
        flex-grow: 2;
        order: 1;
        margin-right: 0;
        margin-bottom: 10px;
        margin-top: 10px;
      }
    }

    button {
      background-color: ${(props) => props.styles.colors.primary};
      border: 0;
      border-radius: 50px;
      padding: 4px 7px 4px 7px;
      /*       T    R    B    L */
      @media (max-width: ${(props) => props.styles.screenSizes.desktop}) {
        margin-left: 10px;
        order: 0;
      }

      &:last-child {
        @media (max-width: ${(props) => props.styles.screenSizes.desktop}) {
          margin-right: 10px;
          order: 2;
        }
      }

      &:hover {
        cursor: pointer;
      }

      &:disabled {
        background-color: #dddddd;
      }

      &:focus {
        outline: 0;
      }

      .arrow {
        border: solid white;
        border-width: 0 3px 3px 0;
        display: inline-block;
        padding: 3px;

        &.right {
          left: -2px;
          position: relative;
          transform: rotate(-45deg);
          -webkit-transform: rotate(-45deg);
        }

        &.left {
          transform: rotate(135deg);
          -webkit-transform: rotate(135deg);
        }
      }
    }
  }
`;

export const WeekSelector = (props) => {
  return (
    <Container styles={styles}>
      <div className="global-container container">
        <div className="container-weeked-date">
          <Tooltip title="Botão para voltar para a semana anterior">
            <button alt="Previous" onClick={props.onClickPrevious} data-testid="semana-anterior">
              <span className="arrow left" />
            </button>
          </Tooltip>
          <Tooltip title="Semana selecionada">
            <span className="date">
              {props.week.start.toFormat("dd/MM/yyyy")} -{" "}
              {props.week.end.toFormat("dd/MM/yyyy")}
            </span>
          </Tooltip>
          <Tooltip title="Botão para mudar para a próxima semana">
            <button alt="Next" onClick={props.onClickNext} data-testid="proxima-semana">
              <span className="arrow right" />
            </button>
          </Tooltip>
        </div>
        <Tooltip title="Botão minhas tarefas">
          <Button
            onClick={props.onClickConfigWeek}
            variant="contained"
            color="primary"
            size="small"
            disableElevation
            className="btn-ui"
            data-testid="minhas-tarefas"
          >
            Minhas Tarefas
          </Button>
        </Tooltip>
      </div>
    </Container>
  );
};
