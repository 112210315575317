import React from "react";
import { Route } from "react-router-dom";
import { RouteBreadcrumb } from "./Components/RouteBreadcrumb";

const RenderRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) => {
        const hasBreadcrumbName = rest.breadcrumbName !== "";
        return (
          <div>
            {hasBreadcrumbName && <RouteBreadcrumb />}
            <Component {...props} />
          </div>
        );
      }}
    />
  );
};

export default RenderRoute;
