import { DateTime } from "luxon";

const PT_BR_FORMAT = "dd/MM/yyyy";

export function parseISO(value) {
  return DateTime.fromISO(value);
}

export function parsePTBR(value) {
  return DateTime.fromFormat(value, PT_BR_FORMAT);
}
