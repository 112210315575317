import React from 'react';
import QueryBuilderIcon from '@material-ui/icons/QueryBuilder';
import PermIdentityIcon from '@material-ui/icons/PermIdentity';
import FolderOpenIcon from '@material-ui/icons/FolderOpen';
import TodayOutlinedIcon from '@material-ui/icons/TodayOutlined';
import ErrorOutlineOutlinedIcon from '@material-ui/icons/ErrorOutlineOutlined';
import FaceIcon from '@material-ui/icons/Face';
import InsertChartOutlinedTwoToneIcon from '@material-ui/icons/InsertChartOutlinedTwoTone';
import WatchLaterOutlinedIcon from '@material-ui/icons/WatchLaterOutlined';

const menuStatic = [
  {
    name: 'timesheet',
    description: 'Timesheet',
    path: '/timesheet',
    profiles: [],
    children: [],
    icon: <QueryBuilderIcon color="primary" />
  },
  {
    name: 'project',
    description: 'Projetos',
    path: '/project',
    profiles: ['administrator', 'manager'],
    children: [],
    icon: <FolderOpenIcon color="primary" />
  },
  {
    name: 'client',
    path: '/client',
    description: 'Clientes',
    profiles: ['administrator'],
    children: [],
    icon: <PermIdentityIcon color="primary" />
  },
  {
    name: 'user',
    description: 'Usuários',
    path: '/user',
    profiles: ['administrator'],
    children: [],
    icon: <FaceIcon color="primary" />
  },
  {
    name: 'queriesReports',
    path: '',
    description: 'Consultas e Relatórios',
    profiles: [],
    children: [
      {
        name: 'generalReportOfHours',
        path: '/report/projectAppointments',
        description: 'Relatório Geral de Horas',
        profiles: ['administrator', 'manager'],
        children: [],
        icon: <WatchLaterOutlinedIcon color="primary" />
      },
      {
        name: 'pendingReport',
        path: '/report/userPending',
        description: 'Relatório de Pendências',
        profiles: ['administrator', 'manager'],
        children: [],
        icon: <ErrorOutlineOutlinedIcon color="primary" />
      },
      {
        name: 'queryOfHoursWorked',
        path: '/report/userAppointments',
        description: 'Consulta de Horas Trabalhadas',
        profiles: [],
        children: [],
        icon: <TodayOutlinedIcon color="primary" />
      },
    ],
    icon:  <InsertChartOutlinedTwoToneIcon color="primary" />
  },
]

export const getMenuByProfile = (profile) => filterMenu(menuStatic, profile)

const filterMenu = (menu, profile) => {
  const getChildrenByProfile = (result, object) => {
    if (
      !object.children.length &&
      (object.profiles.includes(profile.toLowerCase()) ||
        !object.profiles.length)
    ) {
      result.push(object)
      return result
    }
    if (object.children.length > 0) {
      const children = object.children.reduce(getChildrenByProfile, [])
      if (children.length) result.push({ ...object, children })
    }
    return result
  }
  return menu.reduce(getChildrenByProfile, [])
}

