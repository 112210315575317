import { useState } from "react";

const validateErrorsDefault = {
  isRequired: (required, value) =>
    Boolean(required) && !value ? "Campo obrigatório" : undefined,
  minLength: (min, value) =>
    value.length < min
      ? `O campo deve ter no máximo ${min} caracteres`
      : undefined,
  maxLength: (max, value) =>
    value.length > max
      ? `O campo deve ter no máximo ${max} caracteres`
      : undefined,
  notNegativeNumber: (value) =>
    value < 0 ? "O campo não pode ser negativo" : undefined,
};

const useFormValidate = (
  initialValues,
  optionsValidate = {},
  callbackSuccess = null
) => {
  const [values, setValues] = useState(initialValues);
  const [errors, setErrors] = useState({});

  const validateField = (field, value) => {
    if (optionsValidate && optionsValidate[field]) {
      const validationsField = optionsValidate[field];
      let breakFirstError = false;
      for (const keyValidation in validationsField) {
        switch (keyValidation) {
          case "isRequired":
            const required = validateErrorsDefault[keyValidation](
              validationsField[keyValidation],
              value
            );
            if (required) {
              breakFirstError = true;
              setErrors({ ...errors, [field]: required });
            } else {
              const { [field]: unset, ...newErrors } = errors;
              setErrors(newErrors);
            }
            break;
          case "minLength":
            const minLength = validateErrorsDefault[keyValidation](
              validationsField[keyValidation],
              value
            );
            if (minLength) {
              breakFirstError = true;
              setErrors({ ...errors, [field]: minLength });
            } else {
              const { [field]: unset, ...newErrors } = errors;
              setErrors(newErrors);
            }
            break;
          case "maxLength":
            const maxLength = validateErrorsDefault[keyValidation](
              validationsField[keyValidation],
              value
            );
            if (maxLength) {
              breakFirstError = true;
              setErrors({ ...errors, [field]: maxLength });
            } else {
              const { [field]: unset, ...newErrors } = errors;
              setErrors(newErrors);
            }
            break;
          case "notNegativeNumber":
            const notNegativeNumber =
              validateErrorsDefault[keyValidation](value);
            if (notNegativeNumber) {
              breakFirstError = true;
              setErrors({ ...errors, [field]: notNegativeNumber });
            } else {
              const { [field]: unset, ...newErrors } = errors;
              setErrors(newErrors);
            }
            break;
          case "custom":
            const custom = validationsField[keyValidation].isInvalid(value);
            if (custom) {
              breakFirstError = true;
              if (validationsField[keyValidation].messageComparationFields) {
                const fieldReturn =
                  validationsField[keyValidation].messageComparationFields
                    .fieldReturnValue;

                setErrors({
                  ...errors,
                  [field]: validationsField[
                    keyValidation
                  ].messageComparationFields.message(values[fieldReturn]),
                });
              } else {
                setErrors({
                  ...errors,
                  [field]: validationsField[keyValidation].message,
                });
              }
            } else {
              const { [field]: unset, ...newErrors } = errors;
              setErrors(newErrors);
            }
            break;
          default:
            break;
        }
        if (breakFirstError) break;
        else continue;
      }
    }
  };

  const handleChange = (event) => {
    if (event.persist) event.persist();
    const { name, value } = event.target;
    validateField(name, value);
    setValues({ ...values, [name]: value });
  };

  const handleSubmit = (event) => {
    if (event) event.preventDefault();
    if (Object.keys(errors).length) return;
    else callbackSuccess(values);
  };

  return {
    values,
    errors,
    handleChange,
    handleSubmit,
  };
};

export default useFormValidate;
