import React, { useEffect, useState } from "react";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { Button } from "../Button";
import { Container } from "@material-ui/core";
import axios from "../../Engine/Infra/CustomAxios";
import { CustomModal } from "../CustomModal";
import { useDispatch } from "react-redux";
import { setAlert, setLoading } from "../../store/App/App.actions";

import "./styles.css";

export const ProjectCollaboratorModal = ({
  currentUser,
  handleTable,
  projectId,
  isOpen,
  handleCloseModal,
}) => {
  const dispatch = useDispatch();
  const [modalTitle] = useState("Vincular Colaborador ao Projeto");
  const [userIdError, setUserIdError] = useState("");
  const [jornadaError, setJornadaError] = useState("");
  const [users, setUsers] = useState([]);
  const [form, setForm] = useState({
    projectId: projectId,
    userId: currentUser?.userId,
    userName: currentUser?.userName,
    jornada: currentUser?.jornada,
  });

  const validateForm = () => {
    let validate = true;

    if (!form.jornada) {
      setJornadaError("Campo obrigatório");
      validate = false;
    }
    if (form.jornada > 24 || form.jornada < 0) {
      setJornadaError("O campo deve ser entre 0 - 24");
      validate = false;
    }
    if (!form.userId) {
      setUserIdError("Campo obrigatório");
      validate = false;
    }

    return validate;
  };

  const handleInputUser = (userId) => {
    setForm({ ...form, userId: userId });
    if (!userId) {
      setUserIdError("Campo obrigatório");
      return;
    }
    setUserIdError("");
  };
  const handleInputJornada = (event) => {
    const val = event.target.value;
    setForm({ ...form, jornada: val });
    if (!val) {
      setJornadaError("Campo obrigatório");
      return;
    }
    if (val > 24 || val < 0) {
      setJornadaError("O campo deve ser entre 0 - 24");
      return;
    }
    const regex = /^[-+]?[0-9]+\.?[0-9]?$/;
    if (!val.match(regex)) {
      setJornadaError("O campo deve ser um decimal de 1 digito. Exemplo: 8,0");
      return;
    }
    setJornadaError("");
  };

  const handleUpdateUser = async () => {
    dispatch(setLoading(true));
    try {
      const response = await axios.put("/projects/user", form);
      dispatch(
        setAlert({
          message: "Colaborador alterado com sucesso",
          type: "success",
          open: true,
        })
      );
      handleCloseModal();
      handleTable("UPDATE", response.data.project);
    } catch (error) {
      dispatch(
        setAlert({
          message:
            error.response.data.message ??
            "Erro ao alterar colaborador ao projeto",
          type: "error",
          open: true,
        })
      );
    }
    dispatch(setLoading(false));
  };
  const handleCreateUser = async () => {
    dispatch(setLoading(true));
    try {
      const response = await axios.post("/projects/user", form);
      dispatch(
        setAlert({
          message: "Colaborador vinculado com sucesso",
          type: "success",
          open: true,
        })
      );
      handleCloseModal();
      handleTable("INSERT", response.data.project);
    } catch (error) {
      dispatch(
        setAlert({
          message:
            error.response.data.message ??
            "Erro ao vincular colaborador ao projeto",
          type: "error",
          open: true,
        })
      );
    }
    dispatch(setLoading(false));
  };

  const handleForm = async () => {
    if (!validateForm()) return;

    if (currentUser) {
      handleUpdateUser();
    } else {
      handleCreateUser();
    }
  };

  useEffect(() => {
    const getUsers = async () => {
      const response = await axios.get("/users/active");
      setUsers(response.data.users);
    };
    getUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container>
      <CustomModal title={modalTitle} onClose={handleCloseModal} open={isOpen}>
        <div className="row" style={{ marginTop: "50px" }}>
          <Autocomplete
            fullWidth
            disabled={currentUser}
            defaultValue={
              currentUser ? { id: form.userId, name: form.userName } : null
            }
            getOptionSelected={(option, value) => option.id === value.id}
            onChange={(_, value) => {
              handleInputUser(value?.id);
            }}
            options={users}
            getOptionLabel={(option) => option.name}
            renderInput={(params) => (
              <TextField
                error={!!userIdError}
                helperText={userIdError}
                label="Colaborador"
                required={true}
                variant="outlined"
                {...params}
                inputProps={{
                  ...params.inputProps,
                  "data-testid":"nome-colaborador"
                }}
              />
            )}
          />
          <TextField
            required={true}
            variant="outlined"
            onChange={handleInputJornada}
            InputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
            label="Jornada"
            placeholder="Digite a jornada do colaborador no projeto"
            fullWidth
            type="number"
            value={form.jornada}
            error={!!jornadaError}
            helperText={jornadaError}
            inputProps={{"data-testid":"jornada-colaborador"}}
          />
        </div>
        <Button
          style={{ marginTop: "20px", marginBottom: "30px", float: "right" }}
          onClick={handleForm}
        >
          Salvar
        </Button>
      </CustomModal>
    </Container>
  );
};
