import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { azureConfig } from '../AzureConfig';
import { DistributedTracingModes } from '@microsoft/applicationinsights-common';

let reactPlugin = null;
let appInsights = null;

/**
 * Create the App Insights Telemetry Service
 * @return {{reactPlugin: ReactPlugin, appInsights: Object, initialize: Function}} - Object
 */
const createTelemetryService = () => {

    /**
     * Initialize the Application Insights class
     * @param {Object} browserHistory - client's browser history, supplied by the withRouter HOC
     * @return {void}
     */
    const initialize = (browserHistory) => {

        if (!browserHistory) {
            throw new Error('Could not initialize Telemetry Service');
        }

        reactPlugin = new ReactPlugin();

        const appInsights = new ApplicationInsights({
            config: {
                instrumentationKey: azureConfig.APPINSIGHTS_KEY,
                extensions: [reactPlugin],
                extensionConfig: {
                    [reactPlugin.identifier]: { history: browserHistory }
                },
                distributedTracingMode: DistributedTracingModes.AI_AND_W3C,
                enableRequestHeaderTracking: true,
                enableCorsCorrelation: true
            }
        })
        appInsights.loadAppInsights()

        appInsights.appInsights.addTelemetryInitializer((envelope) => {
            envelope.tags['ai.cloud.role'] = azureConfig.APP_NAME;
        });
    };

    return { reactPlugin, appInsights, initialize };
};

export const ai = createTelemetryService();
export const getAppInsights = () => appInsights;