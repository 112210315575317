import React, { useState, useEffect } from "react";
import { Container } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { Button } from "../Button";
import { CustomModal } from "../CustomModal";
import { OutlinedDiv } from "./outlinedDiv";
import useFormValidate from "./useFormValidate";
import axios from "../../Engine/Infra/CustomAxios";
import { DateTime } from "luxon";
import { setAlert, setLoading } from "../../store/App/App.actions";
import { useDispatch } from "react-redux";

const INIT_FORM = {
  sequence: 0,
  name: "",
  beginningDate: "",
  endingDate: "",
  taskTypeId: "",
  isBillable: "",
  isWarning: "",
  isExtraHour: "",
  isInnovation: "",
  isRework: "",
  openTask: "",
  team: "",
  module: "",
  requirement: "",
  history: "",
  timePlaned: 0,
  realizationTime: "",
  grouper: "",
  profile: "",
  clientArea: "",
};

export const ProjectTaskDuplicateModal = ({
  currentTask,
  openModal,
  handleClose,
  handleSave,
  listTasksNames,
  projectBeginningDate,
}) => {
  const dispatch = useDispatch();
  const [taskTypes, setTaskTypes] = useState([]);
  const [users, setUsers] = useState([]);
  const [showAutocompleteTeam, setShowAutocompleteTeam] = useState(
    currentTask.openTask === "true" || currentTask.openTask
  );

  const normalizeNFD = (value) => value.toLowerCase().normalize("NFD");

  useEffect(() => {
    const getTaskTypes = async () => {
      const response = await axios.get("/tasks/task-type");
      setTaskTypes(response.data.types ?? []);
    };
    getTaskTypes();
  }, []);

  useEffect(() => {
    const getUsers = async () => {
      const response = await axios.get(
        `/projects/${currentTask.projectId}/users/active`
      );
      setUsers(
        response.data.users.sort((a, b) =>
          normalizeNFD(a.name) > normalizeNFD(b.name) ? 1 : -1
        ) ?? []
      );
    };
    getUsers();
  }, [currentTask.projectId]);

  const handleSubmitForm = async (data) => {
    const { id, realizationTime, tableData, sequence, ...newData } = data;
    if (data.openTask) newData.team = [];
    dispatch(setLoading(true));
    try {
      await handleSave(newData);

      dispatch(setLoading(false));
      handleClose();
      dispatch(
        setAlert({
          message: "Tarefa incluída com sucesso",
          type: "success",
          open: true,
        })
      );
    } catch (error) {
      dispatch(
        setAlert({
          message: error.response?.data?.message ?? "Erro ao incluir tarefa",
          type: "error",
          open: true,
        })
      );
      dispatch(setLoading(false));
    }
  };
  const { errors, values, handleChange, handleSubmit } = useFormValidate(
    !!currentTask
      ? {
          ...currentTask,
          sequence: "",
          name: `${currentTask.name} (Cópia)`,
          realizationTime: "",
        }
      : INIT_FORM,
    {
      name: {
        isRequired: true,
        custom: {
          isInvalid: (value) => listTasksNames.includes(value),
          message:
            "Já existe uma tarefa com este nome para este projeto. Informe um novo nome para a tarefa.",
        },
      },
      beginningDate: {
        isRequired: true,
        custom: {
          isInvalid: (value) =>
            value <
            DateTime.fromFormat(projectBeginningDate, "dd/MM/yyyy").toFormat(
              "yyyy-MM-dd"
            )
              ? true
              : undefined,
          message: `Data início da tarefa deve ser maior ou igual a <${projectBeginningDate}>`,
        },
      },
      endingDate: {
        custom: {
          isInvalid: (value) =>
            value > values.beginningDate ? true : undefined,
          message:
            "Data de fim da tarefa deve ser igual ou maior que a data de início da tarefa",
        },
      },
      module: {
        maxLength: 50,
      },
      requirement: {
        maxLength: 100,
      },
      history: {
        maxLength: 2000,
      },
      grouper: {
        maxLength: 50,
      },
      profile: {
        maxLength: 100,
      },
      clientArea: {
        maxLength: 100,
      },
      timePlaned: {
        notNegativeNumber: true,
      },
      team: {
        custom: {
          isInvalid: (value) =>
            !values.openTask && !value.length > 0 ? true : undefined,
          message: "Selecione pelo menos um colaborador",
        },
      },
    },
    handleSubmitForm
  );

  return (
    <Container>
      <CustomModal
        title="Incluir Tarefa"
        onClose={handleClose}
        open={openModal}
      >
        <div className="row">
          <TextField
            fullWidth
            value={values.name}
            name="name"
            required={true}
            variant="outlined"
            label="Nome da Tarefa"
            onChange={handleChange}
            error={!!errors.name}
            helperText={errors.name}
          />
        </div>
        <div className="row">
          <TextField
            style={{ flex: 1 }}
            variant="outlined"
            value={values.beginningDate}
            label="Data de Início"
            required={true}
            fullWidth
            id="date"
            type="date"
            name="beginningDate"
            onChange={handleChange}
            error={!!errors.beginningDate}
            helperText={errors.beginningDate}
            InputLabelProps={{
              shrink: true,
            }}
            InputProps={{ inputProps: { max: "5000-12-31" } }}
          />
          <TextField
            style={{ flex: 1 }}
            variant="outlined"
            label="Data de Fim"
            value={values.endingDate ?? INIT_FORM.endingDate}
            name="endingDate"
            onChange={handleChange}
            fullWidth
            id="date"
            type="date"
            error={!!errors.endingDate}
            helperText={errors.endingDate}
            InputLabelProps={{
              shrink: true,
            }}
            InputProps={{ inputProps: { max: "5000-12-31" } }}
          />
        </div>
        <div className="row">
          <TextField
            select
            value={values.taskTypeId ?? ""}
            label="Tipo de Tarefa"
            onChange={handleChange}
            variant="outlined"
            fullWidth
            name="taskTypeId"
          >
            {taskTypes.map((t) => (
              <MenuItem key={t.id} value={t.id}>
                {t.name}
              </MenuItem>
            ))}
          </TextField>
        </div>
        <div className="row">
          <OutlinedDiv label="Faturável">
            <FormControl component="fieldset">
              <RadioGroup
                row
                aria-label="position"
                name="isBillable"
                defaultValue={values.isBillable.toString()}
                value={values.isBillable.toString()}
                onChange={handleChange}
              >
                <FormControlLabel
                  value="true"
                  control={<Radio color="primary" />}
                  label="Sim"
                  labelPlacement="end"
                />
                <FormControlLabel
                  value="false"
                  control={<Radio color="primary" />}
                  label="Não"
                  labelPlacement="end"
                />
              </RadioGroup>
            </FormControl>
          </OutlinedDiv>
          <OutlinedDiv label="Sobreaviso">
            <FormControl component="fieldset">
              <RadioGroup
                row
                aria-label="position"
                name="isWarning"
                defaultValue={values.isWarning.toString()}
                value={values.isWarning.toString()}
                onChange={handleChange}
              >
                <FormControlLabel
                  value="true"
                  control={<Radio color="primary" />}
                  label="Sim"
                  labelPlacement="end"
                />
                <FormControlLabel
                  value="false"
                  control={<Radio color="primary" />}
                  label="Não"
                  labelPlacement="end"
                />
              </RadioGroup>
            </FormControl>
          </OutlinedDiv>
          <OutlinedDiv label="Hora Extra">
            <FormControl component="fieldset">
              <RadioGroup
                row
                aria-label="position"
                name="isExtraHour"
                defaultValue={values.isExtraHour.toString()}
                value={values.isExtraHour.toString()}
                onChange={handleChange}
              >
                <FormControlLabel
                  value="true"
                  control={<Radio color="primary" />}
                  label="Sim"
                  labelPlacement="end"
                />
                <FormControlLabel
                  value="false"
                  control={<Radio color="primary" />}
                  label="Não"
                  labelPlacement="end"
                />
              </RadioGroup>
            </FormControl>
          </OutlinedDiv>
          <OutlinedDiv label="Inovação">
            <FormControl component="fieldset">
              <RadioGroup
                row
                aria-label="position"
                name="isInnovation"
                defaultValue={values.isInnovation.toString()}
                value={values.isInnovation.toString()}
                onChange={handleChange}
              >
                <FormControlLabel
                  value="true"
                  control={<Radio color="primary" />}
                  label="Sim"
                  labelPlacement="end"
                />
                <FormControlLabel
                  value="false"
                  control={<Radio color="primary" />}
                  label="Não"
                  labelPlacement="end"
                />
              </RadioGroup>
            </FormControl>
          </OutlinedDiv>
          <OutlinedDiv label="Retrabalho">
            <FormControl component="fieldset">
              <RadioGroup
                row
                aria-label="position"
                name="isRework"
                defaultValue={values.isRework.toString()}
                value={values.isRework.toString()}
                onChange={handleChange}
              >
                <FormControlLabel
                  value="true"
                  control={<Radio color="primary" />}
                  label="Sim"
                  labelPlacement="end"
                />
                <FormControlLabel
                  value="false"
                  control={<Radio color="primary" />}
                  label="Não"
                  labelPlacement="end"
                />
              </RadioGroup>
            </FormControl>
          </OutlinedDiv>
        </div>
        <div className="row-open-task">
          <OutlinedDiv label="Tarefa Aberta">
            <FormControl component="fieldset">
              <RadioGroup
                row
                aria-label="position"
                name="openTask"
                defaultValue={values.openTask.toString()}
                value={values.openTask.toString()}
                onChange={(e) => {
                  setShowAutocompleteTeam(e.target.value === "true");
                  handleChange(e);
                }}
              >
                <FormControlLabel
                  value="true"
                  control={<Radio color="primary" />}
                  label="Sim"
                  labelPlacement="end"
                />
                <FormControlLabel
                  value="false"
                  control={<Radio color="primary" />}
                  label="Não"
                  labelPlacement="end"
                />
              </RadioGroup>
            </FormControl>
          </OutlinedDiv>
          {showAutocompleteTeam ? (
            <OutlinedDiv
              style={{ marginLeft: "25px" }}
              label="Colaboradores da Tarefa"
            >
              <span>Todos os colaboradores</span>
            </OutlinedDiv>
          ) : (
            <Autocomplete
              style={{ marginLeft: "25px" }}
              limitTags={2}
              multiple
              defaultValue={values.team}
              getOptionSelected={(option, value) => option.id === value.id}
              onChange={(event, value) =>
                handleChange({
                  persist: event.persist,
                  target: { name: "team", value: value },
                })
              }
              options={users}
              getOptionLabel={(option) => option.name}
              renderInput={(params) => (
                <TextField
                  label="Colaboradores da Tarefa"
                  variant="outlined"
                  error={!!errors.team}
                  helperText={errors.team}
                  {...params}
                />
              )}
            />
          )}
        </div>
        <div className="row">
          <TextField
            fullWidth
            variant="outlined"
            label="Módulo"
            name="module"
            value={values.module}
            error={!!errors.module}
            helperText={errors.module}
            onChange={handleChange}
          />
        </div>
        <div className="row">
          <TextField
            fullWidth
            variant="outlined"
            label="Requisito"
            name="requirement"
            value={values.requirement}
            error={!!errors.requirement}
            helperText={errors.requirement}
            onChange={handleChange}
          />
        </div>
        <div className="row">
          <TextField
            fullWidth
            variant="outlined"
            multiline={true}
            label="Histórico"
            name="history"
            value={values.history}
            error={!!errors.history}
            helperText={errors.history}
            onChange={handleChange}
          />
        </div>
        <div className="row">
          <TextField
            style={{ flex: 1 }}
            variant="outlined"
            label="Horas planejadas"
            fullWidth
            type="number"
            name="timePlaned"
            error={!!errors.timePlaned}
            helperText={errors.timePlaned}
            value={values.timePlaned ?? INIT_FORM.timePlaned}
            onChange={handleChange}
          />
          {/* <TextField
            style={{ flex: 1 }}
            variant="outlined"
            label="Horas realizadas"
            disabled
            value={currentTask.realizationTime ?? 0}
            fullWidth
            type="number"
          /> */}
        </div>
        <div className="row">
          <TextField
            fullWidth
            variant="outlined"
            label="Agrupador"
            name="grouper"
            value={values.grouper}
            error={!!errors.grouper}
            helperText={errors.grouper}
            onChange={handleChange}
          />
        </div>
        <div className="row">
          <TextField
            fullWidth
            variant="outlined"
            label="Perfil"
            name="profile"
            value={values.profile}
            error={!!errors.profile}
            helperText={errors.profile}
            onChange={handleChange}
          />
        </div>
        <div className="row">
          <TextField
            fullWidth
            variant="outlined"
            label="Área do cliente"
            name="clientArea"
            value={values.clientArea}
            error={!!errors.clientArea}
            helperText={errors.clientArea}
            onChange={handleChange}
          />
        </div>
        <div style={{ width: "100%", textAlign: "right" }}>
          <Button
            style={{ marginTop: "20px", marginBottom: "30px" }}
            onClick={handleSubmit}
          >
            Salvar tarefa
          </Button>
        </div>
      </CustomModal>
    </Container>
  );
};
