import logo from "./logo.svg";
import {ReactComponent as Calendar} from "./Icons/calendar.svg";
import {ReactComponent as TempoTotal} from "./Icons/tempoTotal.svg";

export default {
  icons: {
    ok: "https://image.flaticon.com/icons/svg/130/130879.svg",
    Calendar,
    TempoTotal,
  },
  logo,
};
