export function setTimeRecords (timeRecords) {
    return {
        type: 'SET_TIME_RECORDS',
        payload: timeRecords
    }
}
export function setLoadingTimesheet (loading) {
    return {
        type: 'LOADING_TIMESHEET',
        payload: loading
    }
}
export function timeSheetAdded (time) {
    return {
        type: 'TIMESHEET_ADDED',
        payload: time
    }
}
