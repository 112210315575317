import { useHistory } from "react-router-dom";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import Container from "@material-ui/core/Container";
import { Copyright } from "../../Components/Copyright";
import { useDispatch } from "react-redux";
import React, { useEffect } from "react";
import publicClientApplication from "../../AzureAd";
import "./styles.css";
import * as userService from "../../Engine/Services/UserService";
import Box from "@material-ui/core/Box";
import { logout, signed } from "../../store/User/User.actions";
import { signIn } from "./login";
import { setAlert, setLoading } from "../../store/App/App.actions";

publicClientApplication.handleRedirectPromise();
export const Login = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const handleSilentLogin = async () => {
    publicClientApplication
      .handleRedirectPromise()
      .then(async (tokenResponse) => {
        dispatch(setLoading(true));
        if (!tokenResponse) {
          throw new Error("Token response is required!!!");
        }
        const user = await userService.getCurrentUser();
        user.dispatch(setLoading(false));
        dispatch(signed(user.signedInUser));
        history.push("/timesheet");
      })
      .catch(async (error) => {
        if (error.response && error.response.status !== 400) {
          dispatch(
            setAlert({
              open: true,
              type: "error",
              message: error.response.data.message || "Erro ao efetuar login",
            })
          );
        }
        const user = await userService.silentLogin();
        dispatch(setLoading(false));
        if (user) {
          dispatch(signed(user.signedInUser));
          history.push("/timesheet");
        } else {
          dispatch(logout());
        }
      });
  };
  useEffect(() => {
    handleSilentLogin();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className="paper">
        <img
          className="logo"
          alt="Logo DBserver"
          src="/novosLogos/Logo-DB.png"
        />
        <form onSubmit={signIn} className="form" noValidate>
          <Button
            title="Botão para fazer login"
            style={{ height: "40px" }}
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className="submit"
          >
            Fazer Login
          </Button>
        </form>
      </div>
      <Box style={{ marginTop: "30px" }}>
        <Copyright />
      </Box>
    </Container>
  );
};
