import React, { useEffect, useState } from "react";
import { Container, MenuItem, Paper, TextField } from "@material-ui/core";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { DateTime } from "luxon";
import axios from "../../Engine/Infra/CustomAxios";
import { useDispatch } from "react-redux";
import { setAlert, setLoading } from "../../store/App/App.actions";
import { Button } from "../../Components/Button";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import "./styles.css";
import { Comment } from "@material-ui/icons";
import { TaskCommentModal } from "../../Components/TaskCommentModal";
import { downloadFile } from "../../Engine/Services/DownloadService";

export const ReportUserAppointments = () => {
  const [form, setForm] = useState({
    projects: [],
    from: DateTime.now()
      .minus({ month: 1 })
      .startOf("month")
      .toFormat("yyyy-MM-dd"),
    to: DateTime.now()
      .minus({ month: 1 })
      .endOf("month")
      .toFormat("yyyy-MM-dd"),
    totalizer: "BOTH",
  });
  const [projects, setProjects] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const handleCloseModal = () => {
    setModalOpen(false);
  };
  const dispatch = useDispatch();
  const timestampFormat = "yyyy-MM-dd-HH-mm-ss";
  const [showTable, setShowTable] = useState(false);
  const [initialDateError, setInitialDateError] = useState("");
  const [tableData, setTableData] = useState([]);
  const [currentRecord, setCurrentRecord] = useState();
  const [enablePeriodDate, setEnablePeriodDate] = useState(true);
  const [invalidDatePeriod, setInvalidDatePeriod] = useState({
    from: null,
    to: null,
  });

  const getProjects = async () => {
    if (form.from && form.to) {
      const params = {
        from: form.from,
        to: form.to,
      };
      try {
        dispatch(setLoading(true));
        const response = await axios.get("reports/my-projects-with-time", {
          params: params,
        });
        setProjects(response.data);
      } catch (error) {
        dispatch(
          setAlert({
            message: error.response.data?.message || "Erro ao listar projetos",
            type: "error",
            open: true,
          })
        );
      } finally {
        dispatch(setLoading(false));
      }
    }
  };
  const handleExcel = async () => {
    dispatch(setLoading(true));
    const data = {
      from: form.from,
      project: form.projects,
      to: form.to,
      totalizer: form.totalizer,
    };
    axios
      .post(
        `${process.env.REACT_APP_SVC_URL}reports/report/collaborator-record.xlsx`,
        data,
        {
          responseType: "blob",
        }
      )
      .then((response) => {
        const timestamp = DateTime.now().toFormat(timestampFormat);
        const fileName = `DBTime_relatorio-de-horas-trabalhadas-${timestamp}.xlsx`;
        downloadFile(fileName, response);
        dispatch(setLoading(false));
      })
      .catch(async (_) => {
        try {
          await axios.post(
            `${process.env.REACT_APP_SVC_URL}reports/report/collaborator-record.xlsx`,
            data
          );
        } catch (error) {
          dispatch(setLoading(false));
          dispatch(
            setAlert({
              message:
                error.response.data?.message || "Erro ao gerar relatório",
              type: "error",
              open: true,
            })
          );
        }
      });
  };
  const handlePdf = async () => {
    dispatch(setLoading(true));
    const data = {
      from: form.from,
      project: form.projects,
      to: form.to,
      totalizer: form.totalizer,
    };
    axios
      .post(
        `${process.env.REACT_APP_SVC_URL}reports/report/collaborator-record.pdf`,
        data,
        {
          responseType: "blob",
        }
      )
      .then((response) => {
        const timestamp = DateTime.now().toFormat(timestampFormat);
        const fileName = `DBTime_relatorio-de-horas-colaborador-${timestamp}.pdf`;
        downloadFile(fileName, response);
        dispatch(setLoading(false));
      })
      .catch(async (_) => {
        try {
          await axios.post(
            `${process.env.REACT_APP_SVC_URL}reports/report/collaborator-record.pdf`,
            data
          );
        } catch (error) {
          dispatch(setLoading(false));
          dispatch(
            setAlert({
              message:
                error.response.data?.message || "Erro ao gerar relatório",
              type: "error",
              open: true,
            })
          );
        }
      });
  };

  const validatePeriod = () => {
    let from,
      to = "";
    let isValid = true;
    if (!form.from) {
      from = "Data inválida";
      isValid = false;
    }
    if (!form.to) {
      to = "Data inválida";
      isValid = false;
    }
    setInvalidDatePeriod({ from, to });
    return isValid;
  };
  const handleReport = async () => {
    if (!validatePeriod()) return;
    setShowTable(false);
    dispatch(setLoading(true));
    try {
      const data = {
        from: form.from,
        project: form.projects,
        to: form.to,
        totalizer: form.totalizer,
      };
      const response = await axios.post(
        `${process.env.REACT_APP_SVC_URL}reports/report/collaborator-record`,
        data
      );
      if (response.data?.data?.length > 0) {
        setTableData(response.data);
        setShowTable(true);
      } else {
        dispatch(
          setAlert({
            message: "Nenhum registro encontrado",
            type: "warning",
            open: true,
          })
        );
        setShowTable(false);
      }
      dispatch(setLoading(false));
    } catch (error) {
      dispatch(
        setAlert({
          message: error.response?.data?.message || "Erro ao gerar relatório",
          type: "error",
          open: true,
        })
      );
      dispatch(setLoading(false));
    }
  };
  const init = async () => {
    getProjects();
  };
  const handleCommentModal = (record) => {
    setCurrentRecord(record);
    setModalOpen(true);
  };
  const validateRange = (from, to) => {
    if (from && to && DateTime.fromISO(from) > DateTime.fromISO(to)) {
      setInitialDateError("Data de início deve ser menor que a data fim");
    } else {
      setInitialDateError("");
    }
  };
  const changeFormValues = (value) => {
    const model = { ...form, ...value };
    setForm(model);
  };

  const handleChandeRadioPeriod = (event) => {
    const { value } = event.target;
    setInvalidDatePeriod({ from: undefined, to: undefined });
    setEnablePeriodDate(true);
    let from, to;
    switch (value) {
      case "last_month":
        from = DateTime.now()
          .minus({ month: 1 })
          .startOf("month")
          .toFormat("yyyy-MM-dd");
        to = DateTime.now()
          .minus({ month: 1 })
          .endOf("month")
          .toFormat("yyyy-MM-dd");

        break;
      case "period":
        from = "";
        to = "";
        setEnablePeriodDate(false);
        break;
      default:
        from = DateTime.now().startOf("month").toFormat("yyyy-MM-dd");
        to = DateTime.now().endOf("month").toFormat("yyyy-MM-dd");
        break;
    }

    changeFormValues({ from, to });
  };

  useEffect(() => {
    init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form.from, form.to]);

  return (
    <Container className="main-container">
      {modalOpen ? (
        <TaskCommentModal
          open={true}
          onClose={handleCloseModal}
          currentRecord={currentRecord}
        />
      ) : null}
      <Paper className="main-paper">
        <div className="main-form">
          <h3 style={{ color: "#414aba" }}>Consulta de Horas Trabalhadas</h3>
          <div>
            <RadioGroup
              row
              aria-label="períodos"
              name="períodos"
              defaultValue="last_month"
              onChange={handleChandeRadioPeriod}
            >
              <FormControlLabel
                value="last_month"
                control={<Radio color="primary" />}
                label="Mês anterior"
              />
              <FormControlLabel
                value="current_month"
                control={<Radio color="primary" />}
                label="Mês atual"
              />
              <FormControlLabel
                value="period"
                control={<Radio color="primary" />}
                label="Período"
              />
            </RadioGroup>
          </div>
          <div className="form-first-row">
            <TextField
              className="input"
              variant="outlined"
              label="Data de Início"
              value={form.from}
              onChange={(event) => {
                changeFormValues({ from: event.target.value });
                validateRange(event.target.value, form.to);
                setInvalidDatePeriod({
                  ...invalidDatePeriod,
                  from: undefined,
                });
              }}
              error={!!initialDateError || !!invalidDatePeriod.from}
              helperText={initialDateError || invalidDatePeriod.from}
              type="date"
              InputLabelProps={{
                shrink: true,
              }}
              disabled={enablePeriodDate}
            />
            <TextField
              className="input"
              variant="outlined"
              label="Data de Fim"
              value={form.to}
              type="date"
              onChange={(event) => {
                changeFormValues({ to: event.target.value });
                validateRange(form.from, event.target.value);
                setInvalidDatePeriod({
                  ...invalidDatePeriod,
                  to: undefined,
                });
              }}
              InputLabelProps={{
                shrink: true,
              }}
              disabled={enablePeriodDate}
              error={!!initialDateError || !!invalidDatePeriod.to}
              helperText={initialDateError || invalidDatePeriod.to}
            />
            <Autocomplete
              className="input"
              limitTags={2}
              multiple
              getOptionSelected={(option, value) => option.id === value.id}
              onChange={(item, value) => {
                form.projects = value.map((v) => v.id);
              }}
              options={projects}
              getOptionLabel={(option) => option.name}
              renderInput={(params) => (
                <TextField
                  variant="outlined"
                  label="Projeto"
                  placeholder="Escolha o projeto"
                  {...params}
                />
              )}
            />
            <TextField
              className="input"
              select
              label="Totalizador"
              placeholder="Escolha o totalizador"
              variant="outlined"
              onChange={(event) => {
                form.totalizer = event.target.value;
              }}
            >
              <MenuItem key="both" value="BOTH">
                Todos
              </MenuItem>
              <MenuItem key="periodo" value="PERIOD">
                Período
              </MenuItem>
              <MenuItem key="projeto" value="PROJECT">
                Projeto
              </MenuItem>
            </TextField>
          </div>
          <div className="button-div">
            <Button onClick={handleReport}>Consultar</Button>
          </div>
        </div>
      </Paper>
      {showTable ? (
        <TableContainer component={Paper} style={{ marginTop: "30px" }}>
          <div className="table-header">
            <Button onClick={handlePdf}>Gerar PDF</Button>
            <Button onClick={handleExcel}>Gerar Excel</Button>
          </div>
          <Table aria-label="caption table">
            {/* <caption>A basic table example with a caption</caption> */}
            <TableHead color="primary">
              <TableRow>
                <TableCell className="custom-th">Cliente</TableCell>
                <TableCell className="custom-th">Projeto</TableCell>
                <TableCell className="custom-th">Projeto Inovação</TableCell>
                <TableCell className="custom-th">Tarefa</TableCell>
                <TableCell className="custom-th">Data Registro</TableCell>
                <TableCell className="custom-th">Qtde. Horas</TableCell>
                <TableCell className="custom-th">Ações</TableCell>
              </TableRow>
            </TableHead>
            <TableBody className="body-style">
              {tableData?.data?.map((d) => [
                d.data?.map((day) => (
                  <TableRow key={DateTime.now().toLocaleString()}>
                    <TableCell>{day.clientName}</TableCell>
                    <TableCell>{day.projectName}</TableCell>
                    <TableCell>{day.isInnovation ? "Sim" : "Não"}</TableCell>
                    <TableCell>{day.taskName}</TableCell>
                    <TableCell>
                      {DateTime.fromFormat(
                        day.realizationDate,
                        "yyyy-MM-dd"
                      ).toFormat("dd/MM/yyyy")}
                    </TableCell>
                    <TableCell>
                      {day.hours?.toLocaleString("pt-br", {
                        minimumFractionDigits: 2,
                      })}
                    </TableCell>
                    <TableCell>
                      <Comment
                        onClick={() => {
                          handleCommentModal(day);
                        }}
                      />
                    </TableCell>
                  </TableRow>
                )),

                <TableRow key={DateTime.now().toLocaleString()}>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell className="td-total">Total dia</TableCell>
                  <TableCell className="td-total">
                    {d.total?.toLocaleString("pt-br", {
                      minimumFractionDigits: 2,
                    })}
                  </TableCell>
                  <TableCell></TableCell>
                </TableRow>,
              ])}
              {form.totalizer === "BOTH" || form.totalizer === "PROJECT"
                ? [
                    <TableRow>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell align="right" className="td-total">
                        Total Projeto
                      </TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                    </TableRow>,
                    tableData?.totalProject?.map((p) => (
                      <TableRow key={DateTime.now().toLocaleString()}>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        <TableCell className="td-total">{p.name}</TableCell>
                        <TableCell className="td-total">
                          {p.total.toLocaleString("pt-br", {
                            minimumFractionDigits: 2,
                          })}
                        </TableCell>
                      </TableRow>
                    )),
                  ]
                : null}

              {form.totalizer === "BOTH" || form.totalizer === "PERIOD"
                ? [
                    <TableRow>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell align="right" className="td-total">
                        Total Período
                      </TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                    </TableRow>,
                    <TableRow>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                      <TableCell className="td-total">
                        {tableData?.totalPeriod?.period}
                      </TableCell>
                      <TableCell className="td-total">
                        {tableData?.totalPeriod?.total?.toLocaleString(
                          "pt-br",
                          { minimumFractionDigits: 2 }
                        )}
                      </TableCell>
                    </TableRow>,
                  ]
                : null}
            </TableBody>
          </Table>
        </TableContainer>
      ) : null}
    </Container>
  );
};
