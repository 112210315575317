import React from "react";
import styled from "styled-components";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import { DateTime } from "luxon";
import { minutesToHours, qtyToTime } from "../../Engine/Infra/TimeConverter";
import {
  checkIfTheDayNumberIsWeekend,
  translateNumberDayToNameDay,
} from "../../Shared/functions";

import styles from "../../Styles";
import images from "../../Images";

const ContainerFooter = styled.div`
  display: flex;
  width: 100%;
  height: 104px;
  background-color: ${(props) => props.styles.colors.quaternary};
  bottom: 0;
  position: fixed;

  @media (max-width: ${(props) => props.styles.screenSizes.desktop}) {
    width: calc(100% - 40px);
    left: 50%;
    transform: translateX(-50%);
    border-radius: 4px;
    margin-bottom: 10px;
    height: 120px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    .global-container {
      padding: 0;
    }
  }
`;

const ContainerHours = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  padding: 10px;
  @media (max-width: ${(props) => props.styles.screenSizes.desktop}) {
    flex-direction: column;
    padding: 0;
  }
`;

const HoursTitle = styled.div`
  display: flex;
  align-items: center;
  width: 69%;
  padding: 5px;
  span {
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 23px;
    letter-spacing: 0.15px;
    color: #ffffff;
  }
  .icon {
    margin-right: 10px;
  }
  @media (max-width: ${(props) => props.styles.screenSizes.desktop}) {
    span {
      font-size: 16px;
    }
  }
`;
const HoursDayAndWeek = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  gap: 5px;
  @media (max-width: ${(props) => props.styles.screenSizes.desktop}) {
    gap: 6px;
    padding: 3px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  }
`;
const HoursSumWeek = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 27%;
  padding: 5px;
  span {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 25px;
    line-height: 29px;
    text-align: right;
    color: #e1e2f4;
    padding-right: 20px;
  }
  @media (max-width: ${(props) => props.styles.screenSizes.desktop}) {
    span {
      font-size: 16px;
    }
  }
`;

const BoxHoursDayAndWeek = styled.div`
  display: flex;
  flex-direction: column;
  border: ${({ weekends }) =>
    weekends ? "1px solid rgba(255, 255, 255, .5)" : "1px solid #fff"};
  opacity: ${({ weekends }) => (weekends ? 0.5 : 1)};
  width: 88px;
  height: 72px;
  border-radius: 4px;
  box-sizing: border-box;
  @media (max-width: ${(props) => props.styles.screenSizes.desktop}) {
    border: 1px solid transparent;
    width: calc(100% / 8);
    justify-content: center;
    align-items: center;
    gap: 1px;
  }
`;

const BoxWeekAndDay = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 8px;
  @media (max-width: ${(props) => props.styles.screenSizes.desktop}) {
    gap: 0px;
  }
`;

const WeekAndDayTypography = styled.span`
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.4px;
  color: #ffffff;
`;

const Week = styled.div``;
const Day = styled.div``;
const QtyHours = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  span {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 25px;
    line-height: 29px;
    text-align: right;
    color: #ffffff;
  }
  .point-time {
    margin-left: 2px;
    margin-right: 2px;
    font-size: 14px;
    font-weight: bold;
  }

  @media (max-width: ${(props) => props.styles.screenSizes.desktop}) {
    span {
      font-size: 12px;
    }
  }
`;
const MinuteToHours = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  span {
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 14px;
    text-align: center;
    letter-spacing: 0.4px;
    color: #aeb2e3;
  }
  @media (max-width: ${(props) => props.styles.screenSizes.desktop}) {
    span {
      font-size: 10px;
    }
  }
`;

const ContainerMobiTitleAndTotalHours = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 80%;
`;

export const HoursFloatingCard = ({ entries }) => {
  const mobi = useMediaQuery((theme) => theme.breakpoints.down("md"));

  const total = entries.reduce((total, entry) => {
    return (total += entry.total);
  }, 0);
  return (
    <ContainerFooter styles={styles} title="Bloco do rodapé">
      <div className="global-container">
        <ContainerHours styles={styles} title="Bloco das horas da semana">
          {!mobi && (
            <HoursTitle styles={styles}>
              <images.icons.TempoTotal className="icon" />
              <span>Total de horas:</span>
            </HoursTitle>
          )}
          <HoursDayAndWeek
            styles={styles}
            title="Bloco de horas trabalhas no dia"
          >
            {entries.map((day, index) => {
              const date = DateTime.fromFormat(
                day.date,
                "yyyy-MM-dd"
              ).setLocale("pt");

              const splitDayToHours = qtyToTime(day.total)
                .replace(",", ":")
                .split(":");
              const weekends = checkIfTheDayNumberIsWeekend(date.toFormat("c"));

              return (
                <BoxHoursDayAndWeek
                  styles={styles}
                  title={`Total de horas trabalhadas no dia ${date.toFormat(
                    "dd/MM"
                  )}`}
                  key={index}
                  weekends={weekends}
                >
                  <BoxWeekAndDay styles={styles}>
                    <Week styles={styles}>
                      <WeekAndDayTypography styles={styles}>
                        {mobi
                          ? date.toFormat("ccc")
                          : translateNumberDayToNameDay(date.toFormat("c"))}
                      </WeekAndDayTypography>
                    </Week>
                    <Day styles={styles}>
                      <WeekAndDayTypography styles={styles}>
                        {date.toFormat("d").padStart(2, 0)}
                      </WeekAndDayTypography>
                    </Day>
                  </BoxWeekAndDay>
                  <QtyHours styles={styles}>
                    <span data-testid='horas-rodape'>{splitDayToHours[0]}</span>
                    <span className="point-time">:</span>
                    <span data-testid='minutos-rodape'>{splitDayToHours[1]}</span>
                  </QtyHours>
                  <MinuteToHours styles={styles}>
                    <WeekAndDayTypography styles={styles}>
                      {minutesToHours(day.total).replace(":", ",")}
                    </WeekAndDayTypography>
                  </MinuteToHours>
                </BoxHoursDayAndWeek>
              );
            })}
          </HoursDayAndWeek>
          {!mobi ? (
            <HoursSumWeek styles={styles}>
              <span>{qtyToTime(total).replace(",", ":")}</span>
            </HoursSumWeek>
          ) : (
            <ContainerMobiTitleAndTotalHours styles={styles}>
              <HoursTitle styles={styles}>
                <images.icons.TempoTotal className="icon" />
                <span>Total de horas:</span>
              </HoursTitle>
              <HoursSumWeek
                styles={styles}
                title="Total de horas trabalhadas na semana completa"
              >
                <span>{qtyToTime(total).replace(",", ":")}</span>
              </HoursSumWeek>
            </ContainerMobiTitleAndTotalHours>
          )}
        </ContainerHours>
      </div>
    </ContainerFooter>
  );
};
