import React, { Fragment, useState } from "react";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { default as DrawerMUI } from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import MenuIcon from "@material-ui/icons/Menu";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import Tooltip from "@material-ui/core/Tooltip";
import ExitToAppOutlinedIcon from "@material-ui/icons/ExitToAppOutlined";
import EmojiObjectsOutlinedIcon from "@material-ui/icons/EmojiObjectsOutlined";
import { logout } from "../../store/User/User.actions";
import { signOut } from "../../Engine/Services/UserService";
import { getMenuByProfile } from "./staticMenu";
import { useStyles } from "./makeStyles";
import ChildrenMenuItem from "./childrenMenuItem";

const Drawer = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.signedInUser);
  const location = useLocation();
  const menuSelected = useSelector((state) => state.app.menuSelected);
  const menuUser = getMenuByProfile(user.profile);
  const [openDrawer, setOpenDrawer] = useState(false);

  const toggleDrawer = () => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    if (!menuSelected.path && menuSelected.children.length) {
      const childrenFlat = menuSelected.children.flat();
      dispatch({
        type: "MENU_SELECTED",
        payload: {
          ...menuSelected,
          open: childrenFlat.some((chi) => chi.path === location.pathname),
        },
      });
    }
    setOpenDrawer(!openDrawer);
  };

  const handleLogout = () => {
    signOut();
    dispatch(logout());
  };

  const handleSendSuggestion = () => {
    window.location.href =
      "mailto:dbtime@db.tec.br?subject=[DBTime] - Sugestão de Melhoria";
    setOpenDrawer(false);
  };

  return (
    <Fragment>
      <IconButton onClick={toggleDrawer()} color="primary" component="span">
        <MenuIcon />
      </IconButton>
      <DrawerMUI
        className={classes.drawer}
        open={openDrawer}
        anchor="right"
        classes={{
          paper: classes.drawerPaper,
        }}
        onClose={toggleDrawer()}
      >
        <div className={classes.containerLogo}>
          <img
            alt="Logo da DBserver"
            className={classes.logo}
            src="/novosLogos/[212x212]-Logo-DBTIME.png"
          />
        </div>
        <div className={classes.drawerContainer}>
          <List>
            {menuUser.map((item, index) => (
              <ChildrenMenuItem
                itens={item}
                key={index}
                handlerClick={toggleDrawer()}
              />
            ))}
          </List>
        </div>
        <div className={classes.footer}>
          <Tooltip title="Enviar Sugestão">
            <Button
              color="inherit"
              endIcon={<EmojiObjectsOutlinedIcon color="primary" />}
              onClick={handleSendSuggestion}
              fullWidth
            >
              Enviar Sugestão
            </Button>
          </Tooltip>
          <Tooltip title="Sair">
            <Button
              color="inherit"
              endIcon={<ExitToAppOutlinedIcon color="primary" />}
              onClick={handleLogout}
              fullWidth
            >
              Sair
            </Button>
          </Tooltip>
        </div>
      </DrawerMUI>
    </Fragment>
  );
};

export default Drawer;
