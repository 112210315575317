import React, { useEffect, useState } from 'react';
import { Container } from '@material-ui/core';
import { ClientModal } from '../../Components/ClientModal';
import Edit from '@material-ui/icons/Edit';
import Delete from '@material-ui/icons/Delete';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from "../../Components/Alert";
import MaterialTable from 'material-table';
import axios from '../../Engine/Infra/CustomAxios';
import { Button } from '../../Components/Button';
import { Loading } from '../../Components/Loading';
import confirmService from '../../Components/ConfirmDialog';
import * as tableProperties from '../../Shared/default_table_properties';
import { searchIgnoreCaseAndAccents } from "../../Shared/functions";


export const ClientList = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const [pageLoading, setPageLoading] = useState(true);
  const [clients, setClients] = useState([]);
  const [openAlert, setOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState();
  const [alertType, setAlertType] = useState();
  const [currentClient, setCurrentClient] = useState();
  const handleOpenModal = () => {
    setModalOpen(true);
  }
  const handleLoadingPage = (isLoading) => {
    setPageLoading(isLoading);
  }
  const handleCloseModal = () => {
    setCurrentClient('');
    setModalOpen(false);
  }

  const handleAlert= ({message, type}) => {
    setAlertMessage(message);
    setAlertType(type);
    handleOpenAlert();
  }

  const getClientsRow = () => {
    return clients?.map((client) => {
      return {
        ...client,
        //"businessUnit": client.businessUnit
      }
    });
  }
  const handleTable = (action, data) => {
    switch (action) {
      case 'CREATE':
        setClients([...clients, data])
        break;
      case 'UPDATE':
        const newClients = clients.map((client)=> {
          if(client.id === data.id)
            client = data;

          return client;
        });
        setClients(newClients);
        break;
      case 'DELETE':
        setClients(clients.filter(clientInList=> clientInList.id !== data.id));
        handleAlert({message: 'Cliente deletado com sucesso', type: 'success'})
        break;
      default:
        break;
    }
  }
  useEffect(()=> {
    const getClients = async () => {
      const response = await axios.get("/clients");
      setClients(response.data.clients);
      setPageLoading(false);
    }
    getClients();
  }, []);
  const handleEditClient = (client) => {
    setCurrentClient(client);
    setModalOpen(true);
    
  }
  const handleDeleteClient = async (client) => {
    const result = await confirmService.show({
      message: 'Deseja realmente excluir esse cliente?',
      title: 'Excluir cliente'
    });
    if(!result)
      return;

    try {
      const response = await axios.delete('/clients/'+client.id);
      if(response?.data.success) {
        handleTable('DELETE', client);
      }else {
        handleAlert({message: response.data.message ?? 'Erro ao deletar cliente', type: 'error'})
      }  
    } catch (error) {
      handleAlert({message: error.response.data.message ?? 'Erro ao deletar cliente', type: 'error'})
    }
    handleLoadingPage(false);
  }
  const handleCloseAlert = (event, reason) => setOpen(false);
  const handleOpenAlert = () => setOpen(true);
  const columns = [
    {
      title: 'Código',
      field: 'clientCode'
    },
    { title: 'Nome', field: 'name',
      customFilterAndSearch: (term, rowData) => searchIgnoreCaseAndAccents(term, rowData.name) },
    { title: 'Descrição', field: 'description',
      customFilterAndSearch: (term, rowData) => searchIgnoreCaseAndAccents(term, rowData.description)
    },
    // { title: 'Unidade de Negócio', field: 'businessUnit.name',
    //   customFilterAndSearch: (term, rowData) => searchIgnoreCaseAndAccents(term, rowData.businessUnit.name)
    // },
  ];

  const actions = [
    {
      icon: ()=> <Edit color="primary"/>,
      tooltip: 'Editar cliente',
      color: 'primary',
      onClick: (event, rowData) => handleEditClient(rowData)
    },
    {
      icon: ()=> <Delete color="primary"/>,
      tooltip: 'Deletar cliente',
      onClick: (event, rowData) => handleDeleteClient(rowData)
    }
  ];
  return (
    
    <Container>
      <Snackbar open={openAlert} autoHideDuration={2000} onClose={handleCloseAlert}>
        <Alert severity={alertType}>
          {alertMessage}
        </Alert>
      </Snackbar>
      <Loading isLoading={pageLoading} />
      {modalOpen ? <ClientModal handleAlert={handleAlert} currentClient={currentClient} open={modalOpen} onClose={handleCloseModal} handleLoadingPage={handleLoadingPage} handleTable={handleTable}/> : null}
      <div style={{textAlign: 'right'}}>
        <Button style={{marginTop: '20px'}} onClick={handleOpenModal}>Novo Cliente</Button>
      </div>
      <MaterialTable
        title=""
        style={{marginTop:"30px"}}
        columns={columns}
        data={getClientsRow()}
        actions={actions}
        options={tableProperties.options}
        localization={tableProperties.localization}
      />
    </Container>
    
  );
}