import React, { useEffect, useState } from "react";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import { Button } from "../Button";
import { Container } from "@material-ui/core";
import axios from "../../Engine/Infra/CustomAxios";
import { CustomModal } from "../CustomModal";
import { translateProfiles } from "../../Shared/functions";
import { useSelector } from "react-redux";

import "./styles.css";

export const UserModal = ({
  currentUser,
  open,
  onClose,
  handleLoadingPage,
  handleTable,
  handleAlert,
}) => {
  const [name, setName] = useState("");
  const [nameError, setNameError] = useState();

  const [login, setLogin] = useState("");
  const [loginError, setLoginError] = useState();

  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState();

  const [profile, setProfile] = useState();
  const [profileError, setProfileError] = useState();

  const [registrationCode, setRegistrationCode] = useState();
  const [registrationCodeError, setRegistrationCodeError] = useState();

  const [profiles, setProfiles] = useState();

  const [disabledName] = useState(false);
  const [disabledEmail] = useState(false);
  const [disabledAllFields, setDisabledAllFields] = useState(false);

  const [registrationCodeRequired, setRegistrationCodeRequired] =
    useState(false);

  const [modalTitle, setModalTitle] = useState("Incluir Usuário");

  const signedInUser = useSelector((state) => state.signedInUser);

  const handleInputName = (e) => {
    const val = e.target.value;
    setName(val);
    if (val) {
      setNameError("");
    } else {
      setNameError("Campo obrigatório");
    }
  };
  const handleInputLogin = (e) => {
    const val = e.target.value;
    setLogin(val);
    if (val) {
      setLoginError("");
    } else {
      setLoginError("Campo obrigatório");
    }
  };
  const handleInputEmail = (e) => {
    const val = e.target.value;
    setEmail(val.toLowerCase());
    if (!val) {
      setEmailError("Campo obrigatório");
      return;
    }
    if (!val.includes("@")) {
      setEmailError("Campo e-mail com formato errado");
      return;
    }
    setEmailError("");
  };
  const handleInputProfile = (e) => {
    const val = e.target.value;
    setProfile(val);
    if (val) {
      setProfileError("");
    } else {
      setProfileError("Campo obrigatório");
    }
  };
  const handleInputRegistrationCode = (e) => {
    const val = e.target.value;
    setRegistrationCode(val);
    if (val) {
      setRegistrationCodeError("");
    } else if (isCurrentUserIsNotAdministrator()) {
      setRegistrationCodeError("Campo obrigatório");
    }
  };
  const handleForm = async () => {
    if (currentUser) handleUpdateUser();
    else handleCreateUser();
  };

  const validateForm = () => {
    let valid = true;
    if (!login) {
      setLoginError("Campo obrigatório");
      valid = false;
    }
    if (!name) {
      setNameError("Campo obrigatório");
      valid = false;
    }
    if (!email) {
      setEmailError("Campo obrigatório");
      valid = false;
    }
    if (!email.includes("@") && email.trim() !== "") {
      setEmailError("Campo e-mail com formato errado");
      valid = false;
    }
    if (!profile) {
      setProfileError("Campo obrigatório");
      valid = false;
    }
    if (!registrationCode && isCurrentUserIsNotAdministrator()) {
      setRegistrationCodeError("Campo obrigatório");
      valid = false;
    }
    return valid;
  };

  const isCurrentUserIsNotAdministrator = () => {
    console.log("Profile do usuario " + signedInUser.profile);
    return signedInUser.profile !== "ADMINISTRATOR";
  };

  const handleCreateUser = async () => {
    if (!validateForm()) return;

    handleLoadingPage(true);

    const data = {
      name,
      username: login,
      email,
      profile,
      registrationCode,
    };
    try {
      const response = await axios.post("/users", data);
      if (response?.data.success) {
        handleAlert({
          message: "Usuário adicionado com sucesso",
          type: "success",
        });
        handleTable("CREATE", response.data?.user);
        onClose();
      } else {
        handleAlert({
          message: response.data.message ?? "Erro ao criar usuário",
          type: "error",
        });
      }
    } catch (error) {
      handleAlert({
        message: error.response.data.message ?? "Erro ao criar usuário",
        type: "error",
      });
    }
    handleLoadingPage(false);
  };
  const handleUpdateUser = async () => {
    if (!validateForm()) return;

    handleLoadingPage(true);
    const data = {
      name,
      username: login,
      email,
      profile,
      registrationCode,
    };
    try {
      const response = await axios.put("/users/" + currentUser.id, data);
      if (response?.data.success) {
        handleAlert({
          message: "Usuário alterado com sucesso",
          type: "success",
        });
        handleTable("UPDATE", { ...currentUser, ...data });
        onClose();
      } else {
        handleAlert({
          message: response.data.message ?? "Erro ao alterar usuário",
          type: "error",
        });
      }
    } catch (error) {
      handleAlert({
        message: error.response.data.message ?? "Erro ao alterar usuário",
        type: "error",
      });
    }
    handleLoadingPage(false);
  };

  /*
  const handleFindUser = async () => {
    if(!login) {
        setLoginError('Campo obrigatório');
        return;
    }
    handleLoadingPage(true);
    try {
        const response = await axios.get("/users/"+login);
        if(response.data.success) {
            setLoginError('');
            setNameError('');
            setEmailError('');
            const user = response.data.user;
            setName(user.cn);
            setEmail(user.mail);
            setDisabledName(user.cn != null);
            setDisabledEmail(user.mail != null);
        }else {
          setLoginError('Usuário não encontrado');
        }    
    } catch (error) {
        setLoginError(error.response?.data?.message || 'Usuário não encontrado');
    }
    handleLoadingPage(false);
  }
  */
  useEffect(() => {
    const getProfiles = async () => {
      const response = await axios.get("/users/profiles");
      setProfiles(response.data.profiles ?? []);
    };
    getProfiles();
    if (currentUser) {
      if (currentUser.status === "INACTIVE") setDisabledAllFields(true);

      setModalTitle("Editar Usuário");
      setLogin(currentUser.username);
      setName(currentUser.name);
      setEmail(currentUser.email);
      setProfile(currentUser.profile);
      setRegistrationCode(currentUser.registrationCode);
    }
    setRegistrationCodeRequired(isCurrentUserIsNotAdministrator());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container>
      <CustomModal title={modalTitle} onClose={onClose} open={open}>
        <div className="row">
          <TextField
            disabled={currentUser || disabledAllFields ? true : false}
            className="buscarUsuarioInput"
            required={true}
            variant="outlined"
            onChange={handleInputLogin}
            label="Login do usuário"
            value={login}
            error={!!loginError}
            helperText={loginError}
          />
        </div>
        <div className="row" style={{ marginTop: "30px" }}>
          <TextField
            disabled={disabledName || disabledAllFields}
            required={true}
            variant="outlined"
            onChange={handleInputName}
            label="Nome do Usuário"
            fullWidth
            value={name}
            error={!!nameError}
            helperText={nameError}
          />
          <TextField
            disabled={disabledEmail || disabledAllFields}
            required={true}
            variant="outlined"
            onChange={handleInputEmail}
            label="E-mail do Usuário"
            fullWidth
            value={email}
            error={!!emailError}
            helperText={emailError}
          />
        </div>
        <div style={{ marginTop: "30px" }} className="row">
          <TextField
            disabled={disabledAllFields}
            required={true}
            select
            label="Perfil do Usuário"
            onChange={handleInputProfile}
            variant="outlined"
            fullWidth
            value={profile}
            error={!!profileError}
            helperText={profileError}
          >
            {profiles?.map((option) => (
              <MenuItem key={option} value={option}>
                {translateProfiles(option)}
              </MenuItem>
            ))}
          </TextField>
        </div>

        <div style={{ marginTop: "30px" }} className="row">
          <TextField
            disabled={disabledAllFields}
            required={registrationCodeRequired}
            label="Matrícula do Usuário"
            fullWidth
            onChange={handleInputRegistrationCode}
            variant="outlined"
            value={registrationCode}
            error={!!registrationCodeError}
            helperText={registrationCodeError}
          ></TextField>
        </div>

        {disabledAllFields ? null : (
          <Button
            style={{ marginTop: "20px", marginBottom: "30px", float: "right" }}
            onClick={handleForm}
          >
            Salvar usuário
          </Button>
        )}
      </CustomModal>
    </Container>
  );
};
