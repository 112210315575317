import styled from "styled-components";
export const Container = styled.div`
  align-items: center;
  background-color: white;
  border-bottom: 1px solid #f2f2f2;
  display: flex;
  justify-content: space-between;
  height: 60px;
  padding: 10px 20px;
  width: 100%;
`;
export const UserName = styled.span`
  color: ${(props) => props.styles.colors.primary};
  font-weight: bold;
  margin-right: 20px;
`;
export const AppName = styled.span`
  color: ${(props) => props.styles.colors.primary};
  font-weight: bold;
  &:hover {
    cursor: pointer;
  }
`;
export const DrawerDiv = styled.div`
  display: flex;
  align-items: center;
`;
