const defaultState = {
  loading_add_page: false,
  searchText: "",
  columnFilters: [],
};
export default function (state = defaultState, action) {
  switch (action.type) {
    case "LOADING_ADD_PAGE": {
      return {
        ...state,
        loading_add_page: action.payload,
      };
    }
    case "LOGOUT": {
      return {};
    }
    case "CHANGE_SEARCH_TEXT": {
      return { ...state, searchText: action.payload };
    }
    case "CHANGE_COLUMN_FILTERS": {
      return { ...state, columnFilters: action.payload };
    }
    case "CLEAR_FILTERS": {
      return { ...defaultState };
    }

    default:
      return state;
  }
}
