export const downloadFile = (fileName, response) => {
  const fileType = getType(response);
  const blob = new Blob([response.data], { type: fileType });
  const url = URL.createObjectURL(blob);
  const downloadLink = document.createElement("a");
  downloadLink.href = url;
  downloadLink.download = fileName;
  downloadLink.click();
  downloadLink.remove();
};

const getType = (response) => {
  const contentType = response.headers["content-type"];
  return contentType || "application/octet-stream";
};
