import React, { useEffect, useState } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { DateTime } from "luxon";
import { Container } from "@material-ui/core";
import { Button } from "../../Components/Button";
import Edit from "@material-ui/icons/Edit";
import Delete from "@material-ui/icons/Delete";

import { ProjectTaskTimeTable } from "../../Components/ProjectTaskTimeTable";
import { ProjectTaskTimeModal } from "../../Components/ProjectTaskTimeModal";
import { ProjectTaskTimeModalDelete } from "../../Components/ProjectTaskTimeModalDelete";
import { setAlert, setLoading } from "../../store/App/App.actions";
import { useDispatch } from "react-redux";

import axios from "../../Engine/Infra/CustomAxios";

const MODAL_TITLE = "Registro de Horas";
const MODAL_TITLE_DELETE = "Excluir Registro de Horas";

const OPERATIONS_MODAL = {
  INSERT: "insert",
  UPDATE: "update",
  DELETE: "delete",
};

export const ProjectTaskTime = () => {
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const { state } = location;
  const [records, setRecords] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [openModalDelete, setOpenModalDelete] = useState(false);
  const [operationModal, setOperationModal] = useState("");
  const [currentTaskListTime, setCurrentTaskListTime] = useState();

  if (!state) history.goBack();

  useEffect(() => {
    dispatch(setLoading(true));

    const getTask = async () => {
      const { data } = await axios.get(`/time/task/${state.task.id}`);
      const customRecords = data.records.map((record) => ({
        ...record,
        realizationDate: DateTime.fromFormat(
          record.realizationDate,
          "yyyy-MM-dd"
        ).toFormat("dd/MM/yyyy"),
      }));

      setRecords(customRecords);
      dispatch(setLoading(false));
    };
    getTask();
  }, [state.task.id, refresh, dispatch]);

  const handleOpenModal = () => setOpenModal(!openModal);
  const handleCloseModal = () => setOpenModal(!openModal);
  const handleOpenModalDelete = () => setOpenModalDelete(!openModalDelete);
  const handleCloseModalDelete = () => setOpenModalDelete(!openModalDelete);

  const handleCreateTaskTime = () => {
    setOperationModal(OPERATIONS_MODAL.INSERT);
    setCurrentTaskListTime();
    handleOpenModal();
  };
  const handleEditTaskTime = (data) => {
    setOperationModal(OPERATIONS_MODAL.UPDATE);
    handleOpenModal();
    setCurrentTaskListTime(data);
  };
  const handleDeleteTaskTime = (data) => {
    setOperationModal(OPERATIONS_MODAL.DELETE);
    handleOpenModalDelete();
    setCurrentTaskListTime(data);
  };

  const validateDateNewRegisterInList = (data) => 
    records.filter((record) => (record.realizationDate.includes(data.date) && record.userId.includes(data.userId))).length;
   
  
 

  const showMessage = (type, message) => {
    dispatch(
      setAlert({
        open: true,
        type,
        message,
      })
    );
  };

  const executionOperation = async (operation, data) => {
    const { tableData, ...formValues } = data;
    const { time, justification, id, userId, realizationDate, currentTaskId } =
      formValues;
    try {
      dispatch(setLoading(true));
      setRefresh(false);
      switch (operation) {
        case OPERATIONS_MODAL.UPDATE:
          const update = await axios.patch(`/time/${id}/management`, {
            time,
            justification,
          });
          dispatch(setLoading(false));
          if (!update.data) {
            showMessage("error", "Ocorreu um erro ao adicionar o apontamento!");
          } else {
            showMessage("success", "Apontamento adicionado com sucesso");
            handleCloseModal();
            setRefresh(true);
          }
          break;
        case OPERATIONS_MODAL.INSERT:
          const insert = await axios.post("time/management", {
            taskId: currentTaskId,
            collaboratorUserId: userId,
            realizationDate,
            time,
            justification,
          });
          dispatch(setLoading(false));
          if (!insert.data) {
            showMessage("error", "Ocorreu um erro ao editar o apontamento!");
          } else {
            showMessage("success", "Apontamento alterado com sucesso");
            handleCloseModal();
            setRefresh(true);
          }
          break;
        case OPERATIONS_MODAL.DELETE:
          const del = await axios.delete(`/time/${id}/management`, {
            data: { justification },
          });
          dispatch(setLoading(false));
          if (!del.data) {
            showMessage("error", "Ocorreu um erro ao deletar o apontamento!");
          } else {
            showMessage("success", "Registro de horas deletado com sucesso");
            handleCloseModalDelete();
            setRefresh(true);
          }
          break;
        default:
          break;
      }
    } catch (error) {
      dispatch(setLoading(false));
      showMessage(
        "error",
        error.response.data.message || "Ocorreu um erro inesperado"
      );
    }
  };

  const actions = [
    {
      icon: () => <Edit color="primary" />,
      tooltip: "Editar registro de horas",
      color: "primary",
      onClick: (_, rowData) => handleEditTaskTime(rowData),
    },
    {
      icon: () => <Delete color="primary" />,
      tooltip: "Deletar registro de horas",
      onClick: (_, rowData) => handleDeleteTaskTime(rowData),
    },
  ];

  return (
    <Container>
      <div style={{ textAlign: "right", marginBottom: "2rem" }}>
        <Button onClick={handleCreateTaskTime} style={{ marginTop: "20px" }}>
          Novo Registro de horas
        </Button>
      </div>
      {openModalDelete && (
        <ProjectTaskTimeModalDelete
          modalTitle={MODAL_TITLE_DELETE}
          isOpen={openModalDelete}
          handleCloseModal={handleCloseModalDelete}
          operation={operationModal}
          currentTaskTime={currentTaskListTime}
          executionOperation={executionOperation}
        />
      )}
      {openModal && (
        <ProjectTaskTimeModal
          modalTitle={MODAL_TITLE}
          isOpen={openModal}
          handleCloseModal={handleCloseModal}
          operation={operationModal}
          currentTaskTime={currentTaskListTime}
          currentTask={state.task}
          currentProject={state.project}
          executionOperation={executionOperation}
          validateDateNewRegisterInList={validateDateNewRegisterInList}
        />
      )}
      <ProjectTaskTimeTable
        actions={actions}
        records={records}
        nameTask={state.task.name}
      />
    </Container>
  );
};
