export function setLoading(loading) {
  return {
    type: "LOADING",
    payload: loading,
  };
}

export function setAlert({ open, type, message, seconds }) {
  return {
    type: "ALERT",
    payload: {
      open,
      type,
      message,
      seconds: seconds ? seconds : type === "error" ? 6000 : 2000,
    },
  };
}

export function setMenuSelected(menu) {
  return {
    type: "MENU_SELECTED",
    payload: menu,
  };
}
