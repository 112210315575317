import * as defaultAxios from 'axios';
import { getAzureRefreshedToken } from '../Services/UserService';

const axios = defaultAxios.default.create({
  baseURL: process.env.REACT_APP_API_URL,
});

axios.interceptors.request.use(async (config)=> {
  const token = await getAzureRefreshedToken();
  config.headers =  {
    // 'Accept': 'application/json',
    'Authorization': "Bearer "+ token,
    ...config.headers
  };
  return config;
});

export default axios;