import React from "react";
import TextField from "@material-ui/core/TextField";

const InputComponent = ({ inputRef, ...other }) => <div {...other} />;

export const OutlinedDiv = ({ children, label, ...other }) => {
  return (
    <TextField
      {...other}
      variant="outlined"
      label={label}
      multiline
      className="outline-div"
      InputLabelProps={{ shrink: true }}
      InputProps={{
        inputComponent: InputComponent,
      }}
      inputProps={{ children: children }}
    />
  );
};
