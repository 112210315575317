import styled from "styled-components";

export const Wrapper = styled.div`
  opacity: 1;
  cursor: not-allowed;
  background-color: red;
`;

export const StyledButtonDisabled = styled.a`
  display: inline-block;
  padding: 8px 15px;
  text-transform: uppercase;
  background-color: ${(props) => props.color || "#414aba"};
  border-radius: 30px;
  font-size: 12px;
  text-decoration: none;
  color: white;
  font-weight: bold;
  pointer-events: ${(props) => (props.disabled ? "none" : "all")};
`;
// rgb(65, 74, 186)
export const StyledButton = styled.a`
  display: inline-block;
  padding: 8px 15px;
  text-transform: uppercase;
  background-color: ${(props) => props.color || "#414aba"};
  /* background-color: ${(props) =>
    props.disabled ? "rgb(65, 74, 186, 0.5)" : props.color || "#414aba"}; */
  border-radius: 30px;
  font-size: 12px;
  text-decoration: none;
  color: white;
  font-weight: bold;
  opacity: ${(props) => (props.disabled ? "0.5" : "initial")};
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};

  &:active {
    pointer-events: ${(props) => (props.disabled ? "none" : "all")};
  }
`;
