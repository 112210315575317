import publicClientApplication from '../../AzureAd';
// Create the main publicClientApplication instance
// configuration parameters are located at authConfig.js


/**
 * A promise handler needs to be registered for handling the
 * response returned from redirect flow. For more information, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/acquire-token.md
 */


function signIn(e) {
    e.preventDefault();
    /**
     * You can pass a custom request object below. This will override the initial configuration. For more information, visit:
     * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/request-response-object.md#request
     */

    publicClientApplication.loginRedirect({
        prompt: "select_account",
        scopes: [
            "api://1ec01e83-43ce-41d3-be9f-3af1a02da186/dbtime.all"
        ]
    });
}
export {signIn}
// function signOut() {

//     /**
//      * You can pass a custom request object below. This will override the initial configuration. For more information, visit:
//      * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/request-response-object.md#request
//      */

//     const logoutRequest = {
//         account: publicClientApplication.getAccountByUsername(username),
//         postLogoutRedirectUri: msalConfig.auth.redirectUri,
//     };

//     publicClientApplication.logoutRedirect(logoutRequest);
// }



// function seeProfile() {
//     getTokenRedirect(loginRequest)
//         .then(response => {
//             callMSGraph(graphConfig.graphMeEndpoint, response.accessToken, updateUI);
//         }).catch(error => {
//             console.error(error);
//         });
// }

// function readMail() {
//     getTokenRedirect(tokenRequest)
//         .then(response => {
//             callMSGraph(graphConfig.graphMailEndpoint, response.accessToken, updateUI);
//         }).catch(error => {
//             console.error(error);
//         });
// }