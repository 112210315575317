import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import MuiAccordion from "@material-ui/core/Accordion";
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";

import Typography from "@material-ui/core/Typography";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";

import "./styles.css";

const Accordion = withStyles({
  root: {
    border: "none",
    backgroundColor: "transparent",
    boxShadow: "none",
    marginBottom: "15px",
    "&(:last-chind": {
      marginBottom: "10px",
    },
    "&:not(:last-child)": {
      borderBottom: 0,
    },
    "&:before": {
      display: "none",
    },
    "&$expanded": {
      margin: "auto",
    },
  },
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    borderBottom: "1px solid #000000",
    backgroundColor: "transparent",
    marginBottom: -1,
    paddingLeft: 0,
    minHeight: 56,
    "&$expanded": {
      minHeight: 56,
      margin: 0,
    },
  },
  content: {
    "&$expanded": {
      margin: 0,
    },
  },
  expanded: {},
  expandIcon: {
    padding: 0,
    marginTop: 28,
  },
})(MuiAccordionSummary);

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    padding: "0px",
  },
  heading: {
    fontSize: "20px",
    fontStyle: "normal",
    fontWeight: "normal",
    lineHeight: "23px",
    letterSpacing: "0.15px",
    flexBasis: "33.33%",
    flexShrink: 0,
    color: "#272727",
  },
  secondaryHeading: {
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: "bold",
    lineHeight: "19px",
    letterSpacing: "0.15px",
    color: "#414ABA",
  },
}));

export const ThimeSheetAccordion = (props) => {
  const {
    title,
    subtitle,
    children,
    name,
    defaultExpanded = false,
    onChange,
  } = props;
  const classes = useStyles();
  const [expanded, setExpanded] = useState(defaultExpanded ? "all" : "");

  useEffect(() => {
    setExpanded(defaultExpanded ? "all" : "");
  }, [children, defaultExpanded]);

  const handleOnChange = (newExpanded, painel) => {
    setExpanded(newExpanded ? painel : "");
    if (onChange) {
      onChange(newExpanded);
    }
  };

  return (
    <div className={classes.root}>
      <Accordion
        expanded={expanded === name || expanded === "all"}
        onChange={(_, expanded) => handleOnChange(expanded, name)}
        title={`Bloco do accordion do projeto ${title}`}
      >
        <AccordionSummary
          expandIcon={
            expanded === name || expanded === "all" ? (
              <ExpandLessIcon
                fontSize="small"
                color="primary"
                title={`Botão seta para baixo do accordion do projeto ${title} para fechar a lista de tarefas`}
                data-testid="seta-baixo"
              />
            ) : (
              <ChevronRightIcon
                fontSize="small"
                color="primary"
                title={`Botão seta para direita do accordion do projeto ${title} para abrir a lista de tarefas`}
                data-testid="seta-direita"
              />
            )
          }
          aria-controls={title}
          id={title}
          title="Bloco sumário do accordion"
        >
          <div
            className="accordion-summary-container-title"
            title="Bloco de titulo e subtitulo do sumário do accordion"
          >
            <Typography
              variant="h6"
              className={classes.heading}
              title="Titulo do sumário - nome do cliente"
            >
              {title}
            </Typography>
            <Typography
              variant="subtitle1"
              className={classes.secondaryHeading}
              title="Subtitulo do sumário - nome do projeto"
            >
              {subtitle}
            </Typography>
          </div>
        </AccordionSummary>

        {children}
      </Accordion>
    </div>
  );
};

ThimeSheetAccordion.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  children: PropTypes.node,
  name: PropTypes.string,
  onChange: PropTypes.func,
};
