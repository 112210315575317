import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import styles from "../../Styles";
import Drawer from "../Drawer/index";
import { useHistory } from "react-router-dom";
import * as Styles from "./styles.js";

export const Header = () => {
  const history = useHistory();
  const signedInUser = useSelector((state) => state.signedInUser);

  useEffect(() => {
    if (!signedInUser.id) {
      history.push("/");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [signedInUser]);

  const redirectHome = () => history.push("/timesheet");

  return (
    <Styles.Container className="header" styles={styles}>
      <Styles.AppName title="Nome do sistema" styles={styles}>
        <img
          src="/novosLogos/[64x64]-Logo-DBTIME.png"
          alt="Logo DB"
          width="50px"
          onClick={redirectHome}
        />
      </Styles.AppName>

      <div style={{ visibility: "hidden" }}>Versão: 0.1 - Manual</div>
      {signedInUser.id ? (
        <Styles.DrawerDiv>
          <Styles.UserName title="Nome do usuário" styles={styles}>
            {signedInUser.name}
          </Styles.UserName>
          <div title="Botão para abrir menu lateral">
            <Drawer />
          </div>
        </Styles.DrawerDiv>
      ) : null}
    </Styles.Container>
  );
};
