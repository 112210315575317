import React, { useEffect, useState, createRef, useRef } from "react";
import { useHistory } from "react-router-dom";
import { DateTime } from "luxon";
import {
  IconButton,
  TextField,
  Popper,
  MenuList,
  Paper,
  Fade,
  ClickAwayListener,
  Chip,
} from "@material-ui/core";
import Switch from "@material-ui/core/Switch";
import Clear from "@material-ui/icons/Clear";
import Delete from "@material-ui/icons/Delete";
import Check from "@material-ui/icons/Check";
import MaterialTable, {
  MTableToolbar,
  MTableAction,
  MTableBodyRow,
} from "material-table";
import axios from "../../Engine/Infra/CustomAxios";
import * as tableProperties from "../../Shared/default_table_properties";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { useDispatch, useSelector } from "react-redux";
import { setAlert } from "../../store/App/App.actions";
import { ProjectTaskModal } from "../ProjectTaskModal";
import Tooltip from "@material-ui/core/Tooltip";
import ExpandLessOutlinedIcon from "@material-ui/icons/ExpandLessOutlined";
import ExpandMoreOutlinedIcon from "@material-ui/icons/ExpandMoreOutlined";
import "./styles.css";
import { Button } from "../Button";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "../Alert";
import { Loading } from "../Loading";
import confirmService from "../ConfirmDialog";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { ProjectTaskDuplicateModal } from "../ProjectTaskDuplicateModal";
import {
  TableHeaderColumn,
  TableTextField,
  TableIcons,
  TableCustomFilterDatePicker,
} from "../../Components/MaterialTable/index";

const MenuItemRowTable = (props) => {
  const [achor, setAnchor] = useState(null);
  const { options, data } = props;

  const handleClickOpenMenu = (event) => {
    setAnchor(event.currentTarget);
  };
  const handleClickCloseMenu = () => setAnchor(null);

  return (
    <>
      <IconButton
        onClick={(event) => {
          handleClickOpenMenu(event);
        }}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="menu-item-row-task"
        anchorEl={achor}
        keepMounted
        open={Boolean(achor)}
        onClose={handleClickCloseMenu}
      >
        {options.map((option) => (
          <MenuItem
            key={option.name}
            onClick={() => option.action(data)}
            disabled={option.disabled}
          >
            {option.name}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

const MenuContextRow = (props) => {
  return (
    <Popper
      id="menu-context"
      open={props.open}
      anchorEl={props.anchorEl}
      transition
      placement="bottom-start"
    >
      {({ TransitionProps }) => (
        <ClickAwayListener onClickAway={props.handleClose}>
          <Fade {...TransitionProps}>
            <Paper
              style={{
                maxHeight: "calc(100% - 96px)",
                WebkitOverflowScrolling: "touch",
                border: "1px solid #414aba",
              }}
            >
              <MenuList style={{ outline: 0 }} autoFocus>
                {props.options.map((op) => (
                  <MenuItem
                    onClick={() => op.action(props.data)}
                    key={op.name}
                    disabled={Boolean(op.disabled)}
                  >
                    {op.name}
                  </MenuItem>
                ))}
              </MenuList>
            </Paper>
          </Fade>
        </ClickAwayListener>
      )}
    </Popper>
  );
};

const getColumns = (taskLength, upTask, downTask, users, handleToggle) => {
  return [
    {
      title: "",
      cellStyle: {
        width: "0.5%",
        padding: 0,
        margin: 0,
      },
      headerStyle: {
        width: "0.5%",
        padding: 0,
        margin: 0,
      },
      filterCellStyle: {
        width: "0.5%",
        padding: 0,
        margin: 0,
      },
      render: (rowData) => {
        return (
          <>
            {taskLength > 1 && rowData.tableData?.id > 0 ? (
              <IconButton onClick={() => upTask(rowData)} aria-label="up">
                <ExpandLessOutlinedIcon />
              </IconButton>
            ) : null}
            {taskLength > 1 && rowData.tableData?.id < taskLength - 1 ? (
              <IconButton onClick={() => downTask(rowData)} aria-label="down">
                <ExpandMoreOutlinedIcon />
              </IconButton>
            ) : null}
          </>
        );
      },
    },
    {
      title: <TableHeaderColumn title="Nome" />,
      field: "name",
      cellStyle: {
        width: "13%",
        minWidth: "13%",
        padding: 0,
        margin: 0,
        cursor: "context-menu",
      },
      headerStyle: {
        width: "13%",
        minWidth: "13%",
        padding: 0,
        margin: 0,
      },
      filterCellStyle: {
        width: "13%",
        minWidth: "13%",
        padding: 0,
        margin: 0,
      },
      validate: (rowData) => (!rowData.name ? "Campo obrigatório" : ""),
      editComponent: (props) => (
        <TextField
          value={props.rowData.name}
          error={!!props.helperText}
          helperText={props.helperText}
          fullWidth
          style={{ width: 200 }}
          type="text"
          inputProps={{"data-testid":"nome-tarefa"}}
          onChange={(event) => {
            props.onChange(event.target.value);
          }}
        />
      ),
      filterComponent: (props) => (
        <TableTextField
          className="input-filter"
          id="field-filter-nome"
          variant="outlined"
          size="small"
          onChange={(event) => {
            props.onFilterChanged(
              props.columnDef.tableData.id,
              event.target.value
            );
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
      ),
    },
    {
      title: <TableHeaderColumn title="Data Início" />,
      field: "beginningDate",
      cellStyle: {
        width: "15%",
        minWidth: "15%",
        padding: 5,
        margin: 0,
        cursor: "context-menu",
      },
      headerStyle: {
        width: "15%",
        minWidth: "15%",
        padding: 5,
        margin: 0,
      },
      filterCellStyle: {
        width: "15%",
        minWidth: "15%",
        padding: 5,
        margin: 0,
      },
      validate: (rowData) =>
        !rowData.beginningDate ? "Campo obrigatório" : "",
      render: (rowData) => (
        <span>
          {rowData.beginningDate
            ? DateTime.fromFormat(rowData.beginningDate, "yyyy-MM-dd").toFormat(
                "dd/MM/yyyy"
              )
            : null}
        </span>
      ),
      customFilterAndSearch: (value, rowData) =>
        rowData.beginningDate
          ? DateTime.fromFormat(rowData.beginningDate, "yyyy-MM-dd")
              .toFormat("dd/MM/yyyy")
              .toLowerCase()
              ?.includes(value.toLowerCase())
          : false,
      editComponent: (props) => (
        <TextField
          value={props.rowData.beginningDate}
          error={!!props.helperText}
          helperText={props.helperText}
          style={{ width: 150 }}
          fullWidth
          type="date"
          inputProps={{"data-testid":"data-inicio"}}
          onChange={(event) => {
            props.onChange(event.target.value);
          }}
        />
      ),
      filterComponent: (props) => (
        <TableCustomFilterDatePicker {...props} id="field-filter-data-inicio" />
      ),
    },
    {
      title: <TableHeaderColumn title="Tarefa Aberta" />,
      field: "openTask",
      cellStyle: {
        width: "1%",
        minWidth: "1%",
        padding: 5,
        margin: 0,
        cursor: "context-menu",
      },
      headerStyle: {
        width: "1%",
        minWidth: "1%",
        padding: 5,
        margin: 0,
      },
      filterCellStyle: {
        width: "1%",
        minWidth: "1%",
        padding: 5,
        margin: 0,
      },
      lookup: { true: "Sim", false: "Não" },
      initialEditValue: false,
      render: (rowData) => (
        <Tooltip title="Para alterar tarefa aberta, clique no botão editar">
          <Switch checked={rowData.openTask} color="primary" />
        </Tooltip>
      ),
      editComponent: (props) => (
        <Switch
          checked={props.rowData.openTask}
          onChange={(event) => {
            props.onChange(event.target.checked);
          }}
          color="primary"
          inputProps={{"data-testid":"tarefa-aberta"}}
        />
      ),
    },
    {
      title: <TableHeaderColumn title="Time" />,
      field: "team",
      cellStyle: {
        width: "14%",
        minWidth: "14%",
        padding: 5,
        margin: 0,
        cursor: "context-menu",
      },
      headerStyle: {
        width: "14%",
        minWidth: "14%",
        padding: 5,
        margin: 0,
      },
      filterCellStyle: {
        width: "14%",
        minWidth: "14%",
        padding: 5,
        margin: 0,
      },
      validate: (rowData) => {
        if (!rowData.openTask && !rowData.team?.length > 0)
          return "Selecione pelo menos um colaborador";

        return "";
      },
      customFilterAndSearch: (value, rowData) => {
        return rowData.team
          .map((u) => u.name.toLowerCase())
          .join(", ")
          .includes(value.toLowerCase());
      },
      render: (rowData) =>
        !rowData.openTask ? (
          <Tooltip title={rowData.team?.map((u) => u.name).join(", ")}>
            <span>
              {rowData.team
                ?.slice(0, 2)
                .map((u) => u.name)
                .join(", ")}{" "}
              {rowData.team?.length > 2 ? "..." : null}
            </span>
          </Tooltip>
        ) : (
          <span>Todos os colaboradores</span>
        ),
      editComponent: (props) => {
        return !props.rowData.openTask ? (
          <Autocomplete
            limitTags={2}
            multiple
            defaultValue={props.rowData?.team}
            getOptionSelected={(option, value) => option.id === value.id}
            onChange={(item, value) => {
              props.onChange(value);
            }}
            renderTags={(value, getTagProps) => {
              return value
                .sort((a, b) => -b.name.localeCompare(a.name))
                .map((option, index) => (
                  <Chip
                    label={option.name}
                    {...getTagProps({ index })}
                    size="small"
                  />
                ));
            }}
            style={{ width: 300 }}
            options={users.sort()}
            getOptionLabel={(option) => option.name}
            renderInput={(params) => (
              <TextField
                error={!!props.helperText}
                helperText={props.helperText}
                {...params}
                inputProps={{
                  ...params.inputProps,
                  "data-testid":"colaborador-projeto"
                }} 
              />
            )}
          />
        ) : (
          <span>Todos os colaboradores</span>
        );
      },
      filterComponent: (props) => (
        <TableTextField
          className="input-filter"
          id="field-filter-time"
          variant="outlined"
          size="small"
          onChange={(event) => {
            props.onFilterChanged(
              props.columnDef.tableData.id,
              event.target.value
            );
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
      ),
    },
    {
      title: <TableHeaderColumn title="Faturável" />,
      field: "isBillable",
      initialEditValue: true,
      cellStyle: {
        width: "1%",
        // padding: 0,
        margin: 0,
        cursor: "context-menu",
      },
      headerStyle: {
        width: "1%",
        // padding: 0,
        margin: 0,
      },
      filterCellStyle: {
        width: "1%",
        // padding: 0,
        margin: 0,
      },
      lookup: { true: "Sim", false: "Não" },
      render: (rowData) => (
        <Switch
          checked={rowData.isBillable}
          onChange={(_) => handleToggle("isBillable", rowData)}
          color="primary"
        />
      ),
      editComponent: (props) => (
        <Switch
          checked={props.rowData.isBillable}
          onChange={(event) => {
            props.onChange(event.target.checked);
          }}
          color="primary"
          inputProps={{"data-testid":"faturavel"}}
        />
      ),
    },
    {
      title: <TableHeaderColumn title="S.A" titleTooltip="Sobre Aviso" />,
      cellStyle: {
        width: "1%",
        // padding: 0,
        margin: 0,
        cursor: "context-menu",
      },
      headerStyle: {
        width: "1%",
        // padding: 0,
        margin: 0,
      },
      filterCellStyle: {
        width: "1%",
        // padding: 0,
        margin: 0,
      },
      field: "isWarning",
      initialEditValue: false,
      lookup: { true: "Sim", false: "Não" },
      render: (rowData) => (
        <Switch
          checked={rowData.isWarning}
          onChange={(_) => handleToggle("isWarning", rowData)}
          color="primary"
        />
      ),
      editComponent: (props) => (
        <Switch
          checked={props.rowData.isWarning}
          onChange={(event) => {
            props.onChange(event.target.checked);
          }}
          color="primary"
          inputProps={{"data-testid":"sobre-aviso"}}
        />
      ),
    },
    {
      title: <TableHeaderColumn title="H.E" titleTooltip="Hora Extra" />,
      field: "isExtraHour",
      cellStyle: {
        width: "1%",
        // padding: 0,
        margin: 0,
        cursor: "context-menu",
      },
      headerStyle: {
        width: "1%",
        // padding: 0,
        margin: 0,
      },
      filterCellStyle: {
        width: "1%",
        // padding: 0,
        margin: 0,
      },
      initialEditValue: false,
      lookup: { true: "Sim", false: "Não" },
      render: (rowData) => (
        <Switch
          checked={rowData.isExtraHour}
          onChange={(_) => handleToggle("isExtraHour", rowData)}
          color="primary"
        />
      ),
      editComponent: (props) => (
        <Switch
          checked={props.rowData.isExtraHour}
          onChange={(event) => {
            props.onChange(event.target.checked);
          }}
          color="primary"
          inputProps={{"data-testid":"hora-extra"}}
        />
      ),
    },
    {
      title: <TableHeaderColumn title="INO" titleTooltip="Inovação" />,
      field: "isInnovation",
      cellStyle: {
        width: "1%",
        // padding: 0,
        margin: 0,
        cursor: "context-menu",
      },
      headerStyle: {
        width: "1%",
        // padding: 0,
        margin: 0,
      },
      filterCellStyle: {
        width: "1%",
        // padding: 0,
        margin: 0,
      },
      initialEditValue: false,
      lookup: { true: "Sim", false: "Não" },
      render: (rowData) => (
        <Switch
          checked={rowData.isInnovation}
          onChange={(_) => handleToggle("isInnovation", rowData)}
          color="primary"
        />
      ),
      editComponent: (props) => (
        <Switch
          checked={props.rowData.isInnovation}
          onChange={(event) => {
            props.onChange(event.target.checked);
          }}
          color="primary"
          inputProps={{"data-testid":"inovacao"}}
        />
      ),
    },
    {
      title: <TableHeaderColumn title="Módulo" />,
      field: "module",
      cellStyle: {
        width: "12%",
        minWidth: "12%",
        padding: 5,
        margin: 0,
        cursor: "context-menu",
      },
      headerStyle: {
        width: "12%",
        minWidth: "12%",
        padding: 5,
        margin: 0,
      },
      filterCellStyle: {
        width: "12%",
        minWidth: "12%",
        padding: 5,
        margin: 0,
      },
      editComponent: (props) => (
        <TextField
          value={props.rowData.module}
          error={!!props.helperText}
          helperText={props.helperText}
          style={{ width: 150 }}
          fullWidth
          type="text"
          inputProps={{"data-testid":"modulo"}}
          onChange={(event) => {
            props.onChange(event.target.value);
          }}
        />
      ),
      filterComponent: (props) => (
        <TableTextField
          className="input-filter"
          id="field-filter-modulo"
          variant="outlined"
          size="small"
          onChange={(event) => {
            props.onFilterChanged(
              props.columnDef.tableData.id,
              event.target.value
            );
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
      ),
    },
    {
      title: <TableHeaderColumn title="Data Fim" />,
      field: "endingDate",
      cellStyle: {
        width: "15%",
        minWidth: "15%",
        padding: 0,
        margin: 0,
        cursor: "context-menu",
      },
      headerStyle: {
        width: "15%",
        minWidth: "15%",
        padding: 0,
        margin: 0,
      },
      filterCellStyle: {
        width: "15%",
        minWidth: "15%",
        padding: 0,
        margin: 0,
      },
      render: (rowData) => (
        <span>
          {rowData.endingDate
            ? DateTime.fromFormat(rowData.endingDate, "yyyy-MM-dd").toFormat(
                "dd/MM/yyyy"
              )
            : null}
        </span>
      ),
      customFilterAndSearch: (value, rowData) =>
        rowData.endingDate
          ? DateTime.fromFormat(rowData.endingDate, "yyyy-MM-dd")
              .toFormat("dd/MM/yyyy")
              .toLowerCase()
              ?.includes(value.toLowerCase())
          : false,
      validate: (rowData) => {
        if (rowData.endingDate && rowData.beginningDate > rowData.endingDate)
          return "A data fim deve ser maior que a data de início";

        return "";
      },
      editComponent: (props) => (
        <TextField
          value={props.rowData.endingDate}
          error={!!props.helperText}
          helperText={props.helperText}
          fullWidth
          style={{ width: 150 }}
          type="date"
          inputProps={{"data-testid": "data-fim"}}
          onChange={(event) => {
            props.onChange(event.target.value);
          }}
        />
      ),
      filterComponent: (props) => (
        <TableCustomFilterDatePicker {...props} id="field-filter-data-fim" />
      ),
    },
  ];
};

const translateColumns = [
  { field: "name", label: "Nome", type: "string" },
  { field: "beginningDate", label: "Data Inicio", type: "date" },
  {
    field: "openTask",
    label: "Tarefa Aberta",
    type: "boolean",
    lookup: { true: "Sim", false: "Não" },
  },
  { field: "team", label: "Time", type: "array", filterField: "name" },
  {
    field: "isBillable",
    label: "Faturável",
    type: "boolean",
    lookup: { true: "Sim", false: "Não" },
  },
  {
    field: "isWarning",
    label: "S.A",
    type: "boolean",
    lookup: { true: "Sim", false: "Não" },
  },
  {
    field: "isExtraHour",
    label: "H.E",
    type: "boolean",
    lookup: { true: "Sim", false: "Não" },
  },
  {
    field: "isInnovation",
    label: "INO",
    type: "boolean",
    lookup: { true: "Sim", false: "Não" },
  },
  { field: "module", label: "Módulo", type: "string" },
  { field: "endingDate", label: "Data Fim", type: "date" },
];

export const ProjectTaskList = ({ currentProject }) => {

  const userAuthenticated = useSelector((state) => state.signedInUser);
  const history = useHistory();
  const [tasks, setTasks] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalDuplicateOpen, setModalDuplicateOpen] = useState(false);
  const [currentTask, setCurrentTask] = useState();
  const [users, setUsers] = useState([]);
  const [listTasksNames, setListTasksNames] = useState([]);
  const [forceRefresh, setForceRefresh] = useState(false);
  const dispatch = useDispatch();
  const [menuContext, setMenuContext] = useState({
    virtualElement: null,
    open: false,
    data: {},
    options: [],
    target: null,
    anchorEl: null,
  });
  const [openAlert, setOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState();
  const [alertType, setAlertType] = useState();

  const searchRef = new createRef();
  const tableRef = new createRef();
  const addActionRef = useRef();

  const handleAlert = ({ message, type }) => {
    setAlertMessage(message);
    setAlertType(type);
    handleOpenAlert();
  };

  const handleCloseAlert = (_, __) => setOpen(false);
  const handleOpenAlert = () => setOpen(true);
  const [pageLoading, setPageLoading] = useState(false);

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const handleCloseDuplicateModal = () => {
    setModalDuplicateOpen(false);
  };

  const getUsers = async () => {
    const response = await axios.get(
      `/projects/${currentProject.id}/users/active`
    );
    setUsers(response.data.users);
  };
  const getTasks = async () => {
    setPageLoading(true);
    const response = await axios.get(`/tasks/project/${currentProject.id}`);
   
    setListTasksNames(response.data.tasks.map((task) => task.name));
    setPageLoading(false);
    setTasks(response.data.tasks);
    
  };
  useEffect(() => {
    getTasks();
    getUsers();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [forceRefresh]);

  const handleToggle = async (toggle, data) => {
    try {
      const newData = getToggleData(toggle, data);
      await axios.put(`tasks/${data.id}`, newData);
      tasks[data.tableData.id] = newData;
      setTasks([...tasks]);
    } catch (error) {
      dispatch(
        setAlert({
          message: error.response?.data?.message ?? "Erro ao alterar task",
          type: "error",
          open: true,
        })
      );
    }
  };
  const getToggleData = (toggle, data) => {
    if (toggle === "openTask") {
      return { ...data, openTask: !data.openTask };
    }
    if (toggle === "isBillable") {
      return { ...data, isBillable: !data.isBillable };
    }
    if (toggle === "isWarning") {
      return { ...data, isWarning: !data.isWarning };
    }
    if (toggle === "isExtraHour") {
      return { ...data, isExtraHour: !data.isExtraHour };
    }
    if (toggle === "isInnovation") {
      return { ...data, isInnovation: !data.isInnovation };
    }
  };
  const arrayMove = (arr, old_index, new_index) => {
    if (new_index >= arr.length) {
      var k = new_index - arr.length + 1;
      while (k--) {
        arr.push(undefined);
      }
    }
    arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
    return arr;
  };

  const upTask = async (rowData) => {
    try {
      const index = tasks.findIndex((item) => item.id === rowData.id);
      let newTasksArray = arrayMove([...tasks], index, index - 1);
      await axios.post(`tasks/change-sequence/${rowData.id}`, {
        direction: "UP",
      });
      setTasks(newTasksArray);
    } catch (error) {
      dispatch(
        setAlert({
          message:
            error.response?.data?.message ?? "Erro ao alterar ordem da tarefa",
          type: "error",
          open: true,
        })
      );
    }
  };
  const downTask = async (rowData) => {
    try {
      const index = tasks.findIndex((item) => item.id === rowData.id);
      let newTasksArray = arrayMove([...tasks], index, index + 1);
      await axios.post(`tasks/change-sequence/${rowData.id}`, {
        direction: "DOWN",
      });
      setTasks(newTasksArray);
    } catch (error) {
      dispatch(
        setAlert({
          message:
            error.response?.data?.message ?? "Erro ao alterar ordem da tarefa",
          type: "error",
          open: true,
        })
      );
    }
  };

  const handleDeleteTask = async (rowData) => {
    const result = await confirmService.show({
      message: `Confirma a exclusão da tarefa - "${rowData.name}"?`,
      title: "Excluir",
    });
    if (!result) return;
    try {
      await axios.delete(`tasks/${rowData.id}`);
      const index = rowData.tableData.id;
      tasks.splice(index, 1);
      setTasks([...tasks]);
      setColumns(
        getColumns(tasks.length, upTask, downTask, users, handleToggle)
      );
      setForceRefresh(true);
    } catch (error) {
      dispatch(
        setAlert({
          message: error.response?.data?.message ?? "Erro ao deletar task",
          type: "error",
          open: true,
        })
      );
    }
  };

  const handleTable = (data) => {
    const index = tasks.findIndex((t) => t.id === data.id);
    tasks[index] = data;
    setTasks([...tasks]);
    setColumns(getColumns(tasks.length, upTask, downTask, users, handleToggle));
    setForceRefresh(true);
  };

  const [columns, setColumns] = useState(
    getColumns(tasks.length, upTask, downTask, users, handleToggle)
  );

  const handleAddTask = (newData) =>
    new Promise(async (resolve, reject) => {
      try {
        const data = {
          ...newData,
          projectId: currentProject.id,
          team: newData.team ?? [],
          isRework: newData.isRework ?? false,
        };
        const response = await axios.post("tasks", data);
        setTasks([...tasks, response.data.task]);
        resolve();
        setForceRefresh(true);
      } catch (error) {
        dispatch(
          setAlert({
            message:
              error.response.status !== 500
                ? error.response?.data?.message ??
                  "Ocorreu um error inesperado ao criar tarefa!"
                : "Ocorreu um error inesperado ao criar tarefa!",
            type: "error",
            open: true,
          })
        );
        reject();
      }
    });

  const [selectedFile, setSelectedFile] = useState();

  const changeHandler = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleImportSubmission = async () => {
    const formData = new FormData();
    formData.append("file", selectedFile);
    try {
      setPageLoading(true);
      const response = await axios.post(
        "/tasks/import/" + currentProject.id,
        formData
      );
      setPageLoading(false);
      if (response.data.success) {
        handleAlert({ message: response.data.message, type: "success" });
        await getTasks();
      } else {
        handleAlert({
          message: response.data.message || "Erro ao enviar arquivo",
          type: "error",
        });
      }
      setSelectedFile("");
    } catch (error) {
      setSelectedFile("");
      setPageLoading(false);
      handleAlert({
        message: error.response.data.message || "Erro ao enviar arquivo",
        type: "error",
      });
    }
  };
  const handleOnchangeSearch = (value) =>
    searchRef.current.onSearchChange(value);
  const actions = [
    {
      icon: () => <MoreVertIcon />,
      tooltip: "opções",
      onClick: () => null,
    },
  ];

  const handleCloseMenuContext = () => {
    setMenuContext({
      virtualElement: null,
      open: false,
      data: {},
      options: [],
      target: null,
      anchorEl: null,
    });
  };

  const handleViewProjectTaskTime = (data) => {
    history.push({
      pathname: "/project/task/time",
      state: { task: data, project: currentProject },
    });
  };

  const options = [
    {
      name: "Copiar linha",
      action: (data) => copyTextToClipboard(data),
      disabled: false,
    },
    {
      name: "Editar",
      action: (data) => handleEdit(data),
      disabled: false,
    },
    {
      name: "Excluir",
      action: (data) => handleExcluir(data),
      disabled: false,
    },
    {
      name: "Duplicar",
      action: (data) => handleDuplicateTask(data),
      disabled: false,
    },
    {
      name: "Consultar Registros de Horas",
      action: (data) => handleViewProjectTaskTime(data),
      disabled:
        !currentProject.responsible.some(
          (responsible) => responsible.id === userAuthenticated.id
        ) || currentProject.status !== "ACTIVE",
    },
  ];

  const transformObjectRowData = (data) => {
    return translateColumns
      .map((col) => {
        let value = data[col.field];
        if ((value != null) & (col.type === "boolean")) {
          value = col.lookup[value.toString()];
        }
        if (value != null && col.type === "array") {
          value = value.map((v) => v[col.filterField]).join(", ");
        }
        if (value != null && col.type === "date")
          value = DateTime.fromFormat(value, "yyyy-MM-dd")
            .toFormat("dd/MM/yyyy")
            .toString();

        return `${[col.label]}=${value ?? ""}`;
      })
      .join(";");
  };

  async function copyTextToClipboard(data) {
    const textCopy = transformObjectRowData(data).toString();
    const message = "Dados Copiado para o seu clipboard!";

    if ("clipboard" in navigator) {
      handleCloseMenuContext();
      handleAlert({ message: message, type: "success" });

      return await navigator.clipboard.writeText(textCopy);
    } else {
      handleCloseMenuContext();
      handleAlert({ message: message, type: "success" });
      return document.execCommand("copy", true, textCopy);
    }
  }

  const handleEdit = (data) => {
    setCurrentTask(data);
    setModalOpen(true);
    handleCloseMenuContext();
  };

  const handleExcluir = (data) => {
    handleDeleteTask(data);
    handleCloseMenuContext();
  };

  const handleDuplicateTask = (data) => {
    setCurrentTask(data);
    setModalDuplicateOpen(true);
    handleCloseMenuContext();
  };

  useEffect(
    () =>
      setColumns(
        getColumns(tasks.length, upTask, downTask, users, handleToggle)
      ),

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [tasks]
  );

  const onHandleContextMenu = (e, props) => {
    e.preventDefault();
    const { clientX, clientY } = e;

    setMenuContext({
      open: true,
      data: props.data,
      options: options,
      anchorEl: {
        clientWidth: 0,
        clientHeight: 0,
        getBoundingClientRect: () => ({
          width: 0,
          height: 0,
          top: clientY,
          right: clientX,
          bottom: clientY,
          left: clientX,
        }),
      },
    });
  };

  return (
    <div>
      <Snackbar
        open={openAlert}
        autoHideDuration={10000}
        onClose={handleCloseAlert}
      >
        <Alert severity={alertType}>{alertMessage}</Alert>
      </Snackbar>
      <Loading isLoading={pageLoading} />
      <div className="task-div">
        {modalOpen ? (
          <ProjectTaskModal
            open={true}
            onClose={handleCloseModal}
            currentTask={currentTask}
            handleTable={handleTable}
          />
        ) : null}
        {modalDuplicateOpen ? (
          <ProjectTaskDuplicateModal
            openModal={modalDuplicateOpen}
            currentTask={currentTask}
            handleClose={handleCloseDuplicateModal}
            handleSave={handleAddTask}
            listTasksNames={listTasksNames}
            projectBeginningDate={currentProject.beginningDate}
          />
        ) : null}
        <div className="div-btn">
          <div style={{ padding: "0px 10px", textAlign: "right" }}>
            <input
              type="file"
              style={{ display: "none" }}
              id="button-file"
              accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
              name="file"
              onChange={changeHandler}
            />
            {!selectedFile ? (
              <Button
                htmlFor="button-file"
                onClick={() => {
                  document.querySelector("#button-file").click();
                }}
              >
                Importar Tarefas
              </Button>
            ) : (
              <div>
                <span style={{ marginRight: "10px" }}>
                  <b>Arquivo selecionado:</b> {selectedFile.name}
                </span>
                <Button onClick={handleImportSubmission}>Importar</Button>
              </div>
            )}
          </div>
          <Button onClick={() => addActionRef.current.click()}>
            Nova tarefa
          </Button>
        </div>
        <div className="container-field-search-table">
          <TableTextField
            fullWidth
            placeholder="Pesquise aqui"
            variant="outlined"
            size="small"
            style={{}}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            onChange={(event) => handleOnchangeSearch(event.target.value)}
          />
        </div>
        <MaterialTable
          icons={{
            Clear: (props) => <Clear color="primary" {...props} />,
            Check: (props) => <Check color="primary" {...props} />,
            Delete: (props) => <Delete color="primary" {...props} />,
            ...TableIcons,
          }}
          title=""
          tableRef={tableRef}
          style={{ marginTop: "30px" }}
          columns={columns}
          actions={actions}
          data={tasks}
          components={{
            Toolbar: (props) => (
              <div className="custom-toolbar">
                <MTableToolbar ref={searchRef} {...props} />
              </div>
            ),
            Row: (props) => (
              <MTableBodyRow
                {...props}
                onContextMenu={(e) => onHandleContextMenu(e, props)}
              />
            ),
            Action: (props) => {
              if (
                typeof props.action === typeof Function ||
                (props.action.tooltip !== "Adicionar" &&
                  props.action.tooltip !== "opções")
              ) {
                return <MTableAction {...props} />;
              } else if (props.action.tooltip === "opções") {
                return (
                  <MenuItemRowTable
                    data={props.data}
                    el={props.action}
                    options={options}
                  />
                );
              } else {
                return (
                  <div
                    ref={addActionRef}
                    onClick={() => {
                      setColumns(
                        getColumns(
                          tasks.length,
                          upTask,
                          downTask,
                          users,
                          handleToggle
                        )
                      );
                      props.action.onClick();
                    }}
                  />
                );
              }
            },
          }}
          options={{
            ...tableProperties.options,
            paging: false,
            headerStyle: {
              ...tableProperties.options.headerStyle,
              backgroundColor: "#414ABA",
              color: "#fff",
              top: "none",
              fontSize: "14px",
              padding: 5,
              margin: 0,
            },
            actionsCellStyle: {
              padding: 0,
              margin: 0,
              width: "20px",
            },
            minBodyHeight: "350px",
            addRowPosition: "first",
            columnResizable: true,
            rowStyle: {
              fontSize: "14px",
              padding: 0,
              margin: 0,
            },
          }}
          localization={tableProperties.localization}
          editable={{
            onRowAdd: (newData) => handleAddTask(newData),
          }}
        />
        <MenuContextRow {...menuContext} handleClose={handleCloseMenuContext} />
      </div>
    </div>
  );
};
