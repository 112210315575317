import { Container, MenuItem, Paper, TextField } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { Button } from "../../Components/Button";
import React, { useEffect, useState } from "react";
import axios from "../../Engine/Infra/CustomAxios";
import { DateTime } from "luxon";
import { useDispatch, useSelector } from "react-redux";
import { setAlert, setLoading } from "../../store/App/App.actions";
import { useHistory } from "react-router-dom";
import "./styles.css";
import { downloadFile } from "../../Engine/Services/DownloadService";

export const ReportProjectAppointments = () => {
  const signedInUser = useSelector((state) => state.signedInUser);
  const history = useHistory();
  if (
    signedInUser.profile !== "ADMINISTRATOR" &&
    signedInUser.profile !== "MANAGER"
  ) {
    history.push("/timesheet");
  }
  const [form] = useState({
    businessUnits: [],
    clients: [],
    projects: [],
    users: [],
    from: "",
    to: "",
    isInnovation: null,
    task: "",
  });
  const [clients, setCLients] = useState([]);
  const [businessUnits, setBusinessUnits] = useState([]);
  const [projects, setProjects] = useState([]);
  const [users, setUsers] = useState([]);
  const [maxDate, setMaxDate] = useState();
  const [initialDateError, setInitialDateError] = useState("");
  const dispatch = useDispatch();

  const validateForm = () => {
    let valid = true;
    if (form.to) {
      if (form.from > form.to) {
        setInitialDateError("Data de início deve ser menor que a data fim");
        valid = false;
      } else {
        setInitialDateError("");
        valid = true;
      }
    }
    return valid;
  };

  const handleReport = async () => {
    if (!validateForm()) {
      return;
    }
    dispatch(setLoading(true));
    const data = {
      businessId: form.businessUnits,
      clientId: form.clients,
      from: form.from,
      innovation: form.isInnovation,
      projectId: form.projects,
      taskName: form.task,
      to: form.to,
      userId: form.users,
    };
    axios
      .post(
        `${process.env.REACT_APP_SVC_URL}reports/report/general-time`,
        data,
        {
          responseType: "blob",
        }
      )
      .then((response) => {
        const timestamp = DateTime.now().toFormat("yyyy-MM-dd-HH-mm-ss");
        const fileName = `DBTime_relatorio-geral-de-horas-${timestamp}.xlsx`;
        downloadFile(fileName, response);
        dispatch(setLoading(false));
      })
      .catch(async (_) => {
        try {
          await axios.post(
            `${process.env.REACT_APP_SVC_URL}reports/report/general-time`,
            data
          );
        } catch (error) {
          dispatch(setLoading(false));
          dispatch(
            setAlert({
              message:
                error.response.data?.message || "Erro ao gerar relatório",
              type: "error",
              open: true,
            })
          );
        }
      });
  };
  const getBusinessUnits = async () => {
    const response = await axios.get("reports/business-units");
    setBusinessUnits(response.data);
  };
  const getClients = async () => {
    const data = {
      businessId: form.businessUnits,
    };
    const response = await axios.post("reports/clients", data);
    setCLients(response.data);
  };
  const getProjects = async () => {
    const data = {
      clientId: form.clients,
    };
    const response = await axios.post("reports/projects", data);
    setProjects(response.data);
  };
  const getUsers = async () => {
    const response = await axios.get("users");
    setUsers(response.data.users);
  };
  const handleMaxDate = async () => {
    if (form.projects.length === 1 || form.clients.length === 1) {
      setMaxDate("2100-01-01");
    } else {
      const minDate = form.from
        ? DateTime.fromFormat(form.from, "yyyy-MM-dd")
        : DateTime.now();
      setMaxDate(minDate.plus({ year: 1 }).toFormat("yyyy-MM-dd"));
    }
  };
  const init = async () => {
    getBusinessUnits();
    getUsers();
    getClients();
    getProjects();
  };

  useEffect(() => {
    init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <Container className="main-container">
      <Paper className="main-paper">
        <div className="main-form">
          <h3 style={{ color: "#414aba" }}>Relatório Geral de Horas</h3>
          <div className="form-first-row">
            <Autocomplete
              className="input"
              limitTags={2}
              multiple
              getOptionSelected={(option, value) => option.id === value.id}
              onChange={(item, value) => {
                form.businessUnits = value.map((v) => v.id);
                getClients();
              }}
              options={businessUnits}
              getOptionLabel={(option) => option.name}
              renderInput={(params) => (
                <TextField
                  variant="outlined"
                  label="Unidade de Negócio"
                  placeholder="Escolha a unidade"
                  {...params}
                />
              )}
            />
            <Autocomplete
              className="input"
              limitTags={2}
              multiple
              getOptionSelected={(option, value) => option.id === value.id}
              onChange={(item, value) => {
                form.clients = value.map((v) => v.id);
                handleMaxDate();
                getProjects();
              }}
              options={clients}
              getOptionLabel={(option) => option.name}
              renderInput={(params) => (
                <TextField
                  variant="outlined"
                  label="Cliente"
                  placeholder="Selecione o cliente"
                  {...params}
                />
              )}
            />
            <TextField
              className="input"
              select
              label="Projeto Inovação?"
              placeholder="Escolha o tipo do projeto"
              variant="outlined"
              onChange={(event) => {
                form.isInnovation =
                  event.target.value === "todos" ? null : event.target.value;
              }}
            >
              <MenuItem key="todos" value="todos">
                Todos
              </MenuItem>
              <MenuItem key="sim" value={true}>
                Sim
              </MenuItem>
              <MenuItem key="nao" value={false}>
                Não
              </MenuItem>
            </TextField>
            <Autocomplete
              className="input"
              limitTags={2}
              multiple
              getOptionSelected={(option, value) => option.id === value.id}
              onChange={(item, value) => {
                form.projects = value.map((v) => v.id);
                handleMaxDate();
              }}
              options={projects}
              getOptionLabel={(option) => option.name}
              renderInput={(params) => (
                <TextField
                  variant="outlined"
                  label="Projeto"
                  placeholder="Escolha o projeto"
                  {...params}
                />
              )}
            />
          </div>
          <div className="form-second-row">
            <TextField
              className="input"
              variant="outlined"
              label="Tarefa"
              placeholder="Digite o nome da tarefa"
              onChange={(event) => {
                form.task = event.target.value;
              }}
            />
            <TextField
              className="input"
              variant="outlined"
              label="Data de Início"
              onChange={(event) => {
                form.from = event.target.value;
                handleMaxDate();
                if (form.to) {
                  if (form.from > form.to) {
                    setInitialDateError(
                      "Data de início deve ser menor que a data fim"
                    );
                  } else {
                    setInitialDateError("");
                  }
                }
              }}
              error={!!initialDateError}
              helperText={initialDateError}
              type="date"
              InputLabelProps={{
                shrink: true,
              }}
            />
            <TextField
              className="input"
              variant="outlined"
              label="Data de Fim"
              onChange={(event) => {
                form.to = event.target.value;
                if (form.from > form.to) {
                  setInitialDateError(
                    "Data de início deve ser menor que a data fim"
                  );
                } else {
                  setInitialDateError("");
                }
              }}
              type="date"
              inputProps={{
                max: maxDate,
              }}
              InputLabelProps={{
                shrink: true,
              }}
            />
            <Autocomplete
              className="input"
              limitTags={2}
              multiple
              getOptionSelected={(option, value) => option.id === value.id}
              onChange={(item, value) => {
                form.users = value.map((v) => v.id);
              }}
              options={users}
              getOptionLabel={(option) => option.name}
              renderInput={(params) => (
                <TextField
                  variant="outlined"
                  label="Colaborador"
                  placeholder="Escolha os colaboradores"
                  {...params}
                />
              )}
            />
          </div>
          <div className="button-div">
            <Button onClick={handleReport}>Gerar Relatório</Button>
          </div>
        </div>
      </Paper>
    </Container>
  );
};
