import React, {useEffect, useState} from 'react';
import TextField from '@material-ui/core/TextField';
import {Button} from '../Button';
import {Container} from '@material-ui/core';
import axios from '../../Engine/Infra/CustomAxios';
import {CustomModal} from '../CustomModal';

import './styles.css';
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "../Alert";
import {Loading} from "../Loading";
import Autocomplete from "@material-ui/lab/Autocomplete";


export const ImportCollaboratorModal = ({ projectId, isOpen, handleCloseModal }) => {

    const [modalTitle] = useState('Importar Colaborador ao Projeto');
    const [tasks, setTasks] = useState([]);
    const [jornadaError, setJornadaError] = useState('');
    const [selectedFile, setSelectedFile] = useState();
    const [selectedFileError, setSelectedFileError] = useState();
    const [pageLoading, setPageLoading] = useState(false);
    const [openAlert, setOpen] = useState(false);
    const [alertMessage, setAlertMessage] = useState();
    const [alertType, setAlertType] = useState();

    const handleAlert = ({ message, type }) => {
        setAlertMessage(message);
        setAlertType(type);
        handleOpenAlert();
    }

    const handleCloseAlert = (_, __) => setOpen(false);
    const handleOpenAlert = () => setOpen(true);
    const [form, setForm] = useState({
        "projectId": projectId
    });

    const validateForm = () => {
        let validate = true;
        if (selectedFile == null) {
            setSelectedFileError("Arquivo obrigatório");
            validate = false;
        } else {
            setSelectedFileError("")
        }
        if (!form.jornada) {
            setJornadaError("Campo obrigatório");
            validate = false;
        }
        if (form.jornada > 24 || form.jornada < 0) {
            setJornadaError("O campo deve ser entre 0 - 24");
            validate = false;
        }

        return validate;
    }

    const handleInputJornada = (event) => {
        const val = event.target.value;
        setForm({ ...form, "jornada": val });
        if (!val) {
            setJornadaError("Campo obrigatório");
            return;
        }
        if (val > 24 || val < 0) {
            setJornadaError("O campo deve ser entre 0 - 24");
            return;
        }
        const regex = /^[-+]?[0-9]+\.?[0-9]?$/
        if(!val.match(regex)) {
            setJornadaError("O campo deve ser um decimal de 1 digito. Exemplo: 8,0");
            return;
        }
        setJornadaError("");
    }
    const getTasks = async () => {
        const response = await axios.get(`/tasks/project/${projectId}`);
        setTasks(response.data.tasks);
    }
    const changeHandler = (event) => {
        setSelectedFile(event.target.files[0]);
    };
    const handleInputTask = (taskId) => {
        setForm({ ...form, "taskId": taskId });
    }
    const handleImportSubmission = async () => {
        const formData = new FormData();
        formData.append('file', selectedFile);
        formData.append("projectId", form.projectId);
        formData.append("jornada", form.jornada);
        if (form.taskId) {
            formData.append("taskId", form.taskId);
        }
        try {
            setPageLoading(true)
            const response = await axios.post('/projects/associate-users/', formData);
            setPageLoading(false)
            if (response.data.success) {
                handleAlert({ message: response.data.message, type: 'success' })
                document.querySelector(".modal-close").click()
            } else {
                handleAlert({
                    message: response.data.message || 'Erro ao enviar arquivo',
                    type: 'error'
                })
            }
            setSelectedFile('');
        } catch (error) {
            setSelectedFile('');
            setPageLoading(false)
            handleAlert({
                message: error.response.data.message || 'Erro ao enviar arquivo',
                type: 'error'
            })
        }
    };
    const handleForm = async () => {
        if (!validateForm())
            return;
        handleImportSubmission();
    }
    useEffect(() => {
        getTasks();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Container>
            <Snackbar open={openAlert} autoHideDuration={10000} onClose={handleCloseAlert}>
                <Alert severity={alertType}>
                    {alertMessage}
                </Alert>
            </Snackbar>
            <Loading isLoading={pageLoading} />
            <CustomModal title={modalTitle} onClose={handleCloseModal} open={isOpen}>
                <div className="row" style={{marginTop: "50px"}}>
                    <input type="file"
                           id="button-file"
                           accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                           name="file" onChange={changeHandler} />
                    <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error Mui-required">
                        {selectedFileError}</p>
                </div>
                <div className="row" style={{ marginTop: "50px" }}>
                    <Autocomplete
                        fullWidth
                        getOptionSelected={(option, value)=> option.id === value.id}
                        onChange={(_, value) => {
                            handleInputTask(value?.id)
                        }}
                        options={tasks}
                        getOptionLabel={(option) => option.name}
                        renderInput={(params) => (
                            <TextField
                                label="Tarefa"
                                required={false}
                                variant="outlined"
                                {...params}
                            />
                        )}
                    />
                    <TextField
                        required={true}
                        variant="outlined"
                        onChange={handleInputJornada}
                        InputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                        label="Jornada"
                        placeholder="Digite a jornada do colaborador no projeto"
                        fullWidth
                        type="number"
                        value={form.jornada}
                        error={!!jornadaError}
                        helperText={jornadaError}
                    />

                </div>
                <Button
                    style={{ marginTop: "20px", marginBottom: '30px', float: 'right' }}
                    onClick={handleForm}>
                    Salvar
        </Button>
            </CustomModal>
        </Container>
    );
}
