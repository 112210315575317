import React, { useEffect, useState } from "react";
import { Container } from "@material-ui/core";
import { UserModal } from "../../Components/UserModal";
import VisibilityIcon from "@material-ui/icons/Visibility";
import Edit from "@material-ui/icons/Edit";
import Delete from "@material-ui/icons/Delete";
import axios from "../../Engine/Infra/CustomAxios";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "../../Components/Alert";
import MaterialTable from "material-table";
import Switch from "@material-ui/core/Switch";
import confirmService from "../../Components/ConfirmDialog";
import { Loading } from "../../Components/Loading";
import { Button } from "../../Components/Button";
import * as tableProperties from "../../Shared/default_table_properties";
import {
  searchIgnoreCaseAndAccents,
  translateProfiles,
} from "../../Shared/functions";

export const UserList = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const [pageLoading, setPageLoading] = useState(true);
  const [users, setUsers] = useState([]);
  const [openAlert, setOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState();
  const [alertType, setAlertType] = useState();
  const [currentUser, setCurrentUser] = useState();
  const handleOpenModal = () => {
    setModalOpen(true);
  };
  const handleLoadingPage = (isLoading) => {
    setPageLoading(isLoading);
  };
  const handleCloseModal = () => {
    setCurrentUser("");
    setModalOpen(false);
  };
  const handleAlert = ({ message, type }) => {
    setAlertMessage(message);
    setAlertType(type);
    handleOpenAlert();
  };

  const handleTable = (action, data) => {
    switch (action) {
      case "CREATE":
        setUsers([...users, data]);
        break;
      case "UPDATE":
        const newUsers = users.map((user) => {
          if (user.id === data.id) user = data;

          return user;
        });
        setUsers(newUsers);
        break;
      case "DELETE":
        setUsers(users.filter((userInList) => userInList.id !== data.id));
        setAlertMessage("Usuário deletado com sucesso");
        break;
      default:
        break;
    }
  };
  useEffect(() => {
    const getusers = async () => {
      const response = await axios.get("/users");
      setUsers(response.data.users);
      setPageLoading(false);
    };
    getusers();
  }, []);
  const handleEditUser = (user) => {
    setCurrentUser(user);
    setModalOpen(true);
  };
  const handleDeleteUser = async (user) => {
    const result = await confirmService.show({
      message:
        "Deseja realmente excluir esse usuário? Esta ação não poderá ser desfeita!",
      title: "Excluir usuário",
    });
    if (!result) return;

    try {
      const response = await axios.delete(`/users/${user.id}`);
      if (response?.data.success) {
        handleTable("DELETE", user);
      } else {
        handleAlert({
          message: response.data.message ?? "Erro ao deletar usuário",
          type: "error",
        });
      }
    } catch (error) {
      handleAlert({
        message: error.response.data.message ?? "Erro ao deletar usuário",
        type: "error",
      });
    }
    handleLoadingPage(false);
  };

  const handleUserStatus = async (_, user) => {
    const result = await confirmService.show({
      message: "Deseja realmente alterar o status desse usuário?",
      title: "Desativar/Ativar Usuário",
    });
    if (!result) return;

    const newStatus = user.status === "ACTIVE" ? "INACTIVE" : "ACTIVE";
    try {
      const response = await axios.post("/users/status/" + user.id, {
        status: newStatus,
      });
      if (response.data.success) {
        const index = users.findIndex((u) => u.id === user.id);
        users[index].status = newStatus;
        setUsers([...users]);
      } else {
        handleAlert({
          message: response.data.message || "Erro ao alterar status do usuário",
          type: "error",
        });
      }
    } catch (error) {
      handleAlert({
        message:
          error.response.data.message || "Erro ao alterar status do usuário",
        type: "error",
      });
    }
  };

  const handleCloseAlert = (_, __) => setOpen(false);
  const handleOpenAlert = () => setOpen(true);
  const columns = [
    {
      title: "Nome",
      field: "name",
      customFilterAndSearch: (term, rowData) =>
        searchIgnoreCaseAndAccents(term, rowData.name),
    },
    { title: "E-mail", field: "email" },
    {
      title: "Perfil",
      field: "profile",
      render: (rowData) => translateProfiles(rowData.profile),
      lookup: {
        COLLABORATOR: "Colaborador",
        MANAGER: "Gerente",
        ADMINISTRATOR: "Administrador",
      },
    },
    { title: "Matrícula", field: "registrationCode" },
    {
      title: "Situação",
      field: "status",
      render: (rowData) => (
        <Switch
          checked={rowData.status === "ACTIVE"}
          onChange={(event) => handleUserStatus(event, rowData)}
          color="primary"
        />
      ),
      lookup: { ACTIVE: "Ativo", INACTIVE: "Inativo" },
    },
  ];
  const actions = [
    (rowData) => ({
      icon: () =>
        rowData.status !== "ACTIVE" ? (
          <VisibilityIcon color="primary" />
        ) : (
          <Edit color="primary" />
        ),
      tooltip:
        rowData.status !== "ACTIVE" ? "Consultar usuário" : "Editar usuário",
      onClick: (_, rowData) => handleEditUser(rowData),
    }),
    {
      icon: () => <Delete color="primary" />,
      tooltip: "Deletar usuário",
      color: "primary",
      onClick: (_, rowData) => handleDeleteUser(rowData),
    },
  ];

  const changeHandler = (event) => {
    setSelectedFile(event.target.files[0]);
  };
  const [selectedFile, setSelectedFile] = useState();

  const handleImportSubmission = async () => {
    const formData = new FormData();

    formData.append("file", selectedFile);
    try {
      setPageLoading(true);
      const response = await axios.post("/users/import/", formData);
      setPageLoading(false);
      if (response.data.success) {
        handleAlert({ message: response.data.message, type: "success" });
      } else {
        handleAlert({
          message: response.data.message || "Erro ao enviar arquivo",
          type: "error",
        });
      }
    } catch (error) {
      setPageLoading(false);
      handleAlert({
        message: error.response.data.message || "Erro ao enviar arquivo",
        type: "error",
      });
    }
  };

  return (
    <Container>
      <Snackbar
        open={openAlert}
        autoHideDuration={2000}
        onClose={handleCloseAlert}
      >
        <Alert severity={alertType}>{alertMessage}</Alert>
      </Snackbar>
      <Loading isLoading={pageLoading} />
      {modalOpen ? (
        <UserModal
          handleAlert={handleAlert}
          currentUser={currentUser}
          open={modalOpen}
          onClose={handleCloseModal}
          handleLoadingPage={handleLoadingPage}
          handleTable={handleTable}
        />
      ) : null}
      <div style={{ textAlign: "right" }}>
        <Button
          style={{ marginTop: "20px", marginRight: "10px" }}
          onClick={handleOpenModal}
        >
          Novo Usuário
        </Button>
        <input
          type="file"
          style={{ display: "none" }}
          id="button-file"
          accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
          name="file"
          onChange={changeHandler}
        />
        {!selectedFile ? (
          <Button
            htmlFor="button-file"
            onClick={() => {
              document.querySelector("#button-file").click();
            }}
          >
            Clique para importar um arquivo
          </Button>
        ) : (
          <div>
            <span style={{ marginRight: "10px" }}>
              <b>Arquivo selecionado:</b> {selectedFile.name}
            </span>
            <Button onClick={handleImportSubmission}>Importar</Button>
          </div>
        )}
      </div>
      <MaterialTable
        title=""
        style={{ marginTop: "30px" }}
        columns={columns}
        data={users}
        actions={actions}
        options={tableProperties.options}
        localization={tableProperties.localization}
      />
    </Container>
  );
};
