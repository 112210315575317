import React, { useEffect, useState, createRef } from "react";
import MaterialTable, { MTableToolbar } from "material-table";
import axios from "../../Engine/Infra/CustomAxios";
import { Button } from "../Button";
import "./styles.css";
import { ProjectCollaboratorModal } from "../ProjectCollaboratorModal";
import { ImportCollaboratorModal } from "../ImportCollaboratorModal";
import * as tableProperties from "../../Shared/default_table_properties";
import { Switch } from "@material-ui/core";
import confirmService from "../ConfirmDialog";
import { useDispatch } from "react-redux";
import { setAlert } from "../../store/App/App.actions";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import MoreVertIcon from "@material-ui/icons/MoreVert";

import {
  TableHeaderColumn,
  TableTextField,
  TableIcons,
} from "../../Components/MaterialTable/index";

const getColumns = (onChangeSwithc) => [
  {
    title: <TableHeaderColumn title="Nome" />,
    field: "userName",
    filterComponent: (props) => (
      <TableTextField
        className="input-filter"
        id="field-filter-nome"
        variant="outlined"
        size="small"
        onChange={(event) => {
          props.onFilterChanged(
            props.columnDef.tableData.id,
            event.target.value
          );
        }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
      />
    ),
  },
  {
    title: <TableHeaderColumn title="Jornada Diária" />,
    field: "jornada",
    render: (rowData) => rowData.jornada.toLocaleString("pt-BR"),
    filterComponent: (props) => (
      <TableTextField
        className="input-filter"
        id="field-filter-jornada"
        variant="outlined"
        size="small"
        onChange={(event) => {
          props.onFilterChanged(
            props.columnDef.tableData.id,
            event.target.value
          );
        }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
      />
    ),
  },
  {
    title: <TableHeaderColumn title="Situação" />,
    field: "status",
    disableClick: true,
    render: (rowData) => (
      <div
        onClick={() => onChangeSwithc(rowData)}
        style={{ cursor: "pointer" }}
      >
        <Switch
          checked={rowData.status === "ACTIVE"}
          // onChange={(event) => onChangeSwithc(rowData)}
          color="primary"
        />
      </div>
    ),
    lookup: { ACTIVE: "Ativo", INACTIVE: "Inativo" },
  },
];

export const ProjectTeamList = ({ currentProject }) => {
  const dispatch = useDispatch();
  const [users, setUsers] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [openModalImportCollaborator, setOpenModalImportCollaborator] =
    useState(false);
  const [currentUser, setCurrentUser] = useState();
  const searchRef = new createRef();

  const [[anchorEl, selectedRow], setAnchorEl] = React.useState([
    null,
    undefined,
  ]);

  const getUsers = async () => {
    const response = await axios.get(
      "/projects/" + currentProject.id + "/users"
    );

    setUsers(response.data.users);
  };

  useEffect(() => {
    getUsers();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleUserStatus = async (user) => {
    const result = await confirmService.show({
      message: "Deseja realmente alterar o status desse usuário?",
      title: "Desativar/Ativar Usuário no projeto",
    });
    if (!result) return;

    const newStatus = user.status === "ACTIVE" ? "INACTIVE" : "ACTIVE";
    try {
      const data = {
        projectId: user.projectId,
        status: newStatus,
        userId: user.userId,
      };
      const response = await axios.put("/projects/user/status", data);

      if (response.data.success) {
        const index = users.findIndex((u) => u.userId === user.userId);
        users[index].status = newStatus;
        setUsers([...users]);

        dispatch(
          setAlert({
            message: "Status alterado com sucesso",
            type: "success",
            open: true,
          })
        );
      } else {
        dispatch(
          setAlert({
            message: "Erro ao alterar status do usuário",
            type: "error",
            open: true,
          })
        );
      }
    } catch (error) {
      dispatch(
        setAlert({
          message: "Erro ao alterar status do usuário",
          type: "error",
          open: true,
        })
      );
    }
  };

  const [columns, setColumns] = useState(getColumns(handleUserStatus));

  useEffect(() => {
    setColumns(getColumns(handleUserStatus));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [users]);

  const handleTable = (action, user) => {
    switch (action) {
      case "UPDATE":
        const index = users.findIndex((item) => item.userId === user.userId);
        users[index] = user;
        setUsers(users);
        setColumns(getColumns(handleUserStatus));
        break;
      case "INSERT":
        setUsers([...users, user]);
        setColumns(getColumns(handleUserStatus));
        break;
      case "DELETE":
        setUsers(users.filter((u) => u.userId !== user.userId));
        setColumns(getColumns(handleUserStatus));
        break;
      default:
        break;
    }
  };

  // const columns = [
  //   {
  //     title: <TableHeaderColumn title="Nome" />,
  //     field: "userName",
  //     filterComponent: (props) => (
  //       <TableTextField
  //         className="input-filter"
  //         id="field-filter-nome"
  //         variant="outlined"
  //         size="small"
  //         onChange={(event) => {
  //           props.onFilterChanged(
  //             props.columnDef.tableData.id,
  //             event.target.value
  //           );
  //         }}
  //         InputProps={{
  //           startAdornment: (
  //             <InputAdornment position="start">
  //               <SearchIcon />
  //             </InputAdornment>
  //           ),
  //         }}
  //       />
  //     ),
  //   },
  //   {
  //     title: <TableHeaderColumn title="Jornada Diária" />,
  //     field: "jornada",
  //     render: (rowData) => rowData.jornada.toLocaleString("pt-BR"),
  //     filterComponent: (props) => (
  //       <TableTextField
  //         className="input-filter"
  //         id="field-filter-jornada"
  //         variant="outlined"
  //         size="small"
  //         onChange={(event) => {
  //           props.onFilterChanged(
  //             props.columnDef.tableData.id,
  //             event.target.value
  //           );
  //         }}
  //         InputProps={{
  //           startAdornment: (
  //             <InputAdornment position="start">
  //               <SearchIcon />
  //             </InputAdornment>
  //           ),
  //         }}
  //       />
  //     ),
  //   },
  //   {
  //     title: <TableHeaderColumn title="Situação" />,
  //     field: "status",
  //     render: (rowData) => (
  //       <Switch
  //         checked={rowData.status === "ACTIVE"}
  //         onChange={(event) => handleUserStatus(rowData)}
  //         color="primary"
  //       />
  //     ),
  //     lookup: { ACTIVE: "Ativo", INACTIVE: "Inativo" },
  //   },
  // ];

  const options = [
    {
      name: "Editar",
      action: () => handleEdit(),
      disabled: selectedRow && selectedRow.status === "INACTIVE",
    },
    {
      name: "Excluir",
      action: () => handleExcluir(),
      disabled: !(selectedRow && selectedRow.status === "INACTIVE"),
    },
  ];

  const handleClick = (event, rowData) => {
    setAnchorEl([event.currentTarget, rowData]);
  };

  const handleClose = () => {
    setAnchorEl([null, undefined]);
  };

  const handleEdit = () => {
    handleEditUser(selectedRow);
    handleClose();
  };

  const handleExcluir = () => {
    handleDeleteUser(selectedRow);
    handleClose();
  };

  const actions = [
    (_) => ({
      icon: () => <MoreVertIcon />,
      tooltip: "Abrir opções",
      onClick: handleClick,
    }),
  ];

  const handleEditUser = (user) => {
    setCurrentUser(user);
    handleOpenModal();
  };
  const handleDeleteUser = async (rowData) => {
    const result = await confirmService.show({
      message: `Confirma a exclusão do colaborador - "${rowData.userName}" do projeto?`,
      title: "Excluir",
    });
    if (!result) return;
    try {
      await axios.delete(
        `projects/${rowData.projectId}/users/${rowData.userId}`
      );
      handleTable("DELETE", rowData);
    } catch (error) {
      dispatch(
        setAlert({
          message:
            error.response?.data?.message ??
            "Erro ao retirar colaborador do time",
          type: "error",
          open: true,
        })
      );
    }
  };
  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleOpenModalImportCollaborator = () => {
    setOpenModalImportCollaborator(true);
  };
  const handleCloseModal = () => {
    setCurrentUser("");
    setOpenModal(false);
  };

  const handleCloseImportCollaborator = () => {
    getUsers();
    setOpenModalImportCollaborator(false);
  };

  const handleOnchangeSearch = (value) =>
    searchRef.current.onSearchChange(value);

  return (
    <div className="table-team-list">
      <Menu
        id="menu-item-row"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {options.map((option) => (
          <MenuItem
            key={option.name}
            onClick={option.action}
            disabled={option.disabled}
          >
            {option.name}
          </MenuItem>
        ))}
      </Menu>
      {openModal ? (
        <ProjectCollaboratorModal
          currentUser={currentUser}
          isOpen={openModal}
          handleTable={handleTable}
          projectId={currentProject.id}
          handleCloseModal={handleCloseModal}
        />
      ) : null}
      {openModalImportCollaborator ? (
        <ImportCollaboratorModal
          isOpen={openModalImportCollaborator}
          projectId={currentProject.id}
          handleCloseModal={handleCloseImportCollaborator}
        />
      ) : null}
      <div className="div-btn">
        <Button onClick={handleOpenModalImportCollaborator}>
          Importar Colaborator
        </Button>
        <Button onClick={handleOpenModal}>Adicionar colaborador</Button>
      </div>
      <div className="container-field-search-table">
        <TableTextField
          fullWidth
          placeholder="Pesquise aqui"
          variant="outlined"
          size="small"
          style={{}}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          onChange={(event) => handleOnchangeSearch(event.target.value)}
        />
      </div>
      <MaterialTable
        title=""
        onRowClick={(_, rowData) =>
          rowData.status === "ACTIVE"
            ? handleEditUser(rowData)
            : handleDeleteUser(rowData)
        }
        style={{ marginTop: "30px" }}
        columns={columns}
        data={users}
        icons={TableIcons}
        actions={actions}
        components={{
          Toolbar: (props) => (
            <div className="custom-toolbar">
              <MTableToolbar ref={searchRef} {...props} />
            </div>
          ),
        }}
        options={{
          ...tableProperties.options,
          headerStyle: {
            ...tableProperties.options.headerStyle,
            backgroundColor: "#414ABA",
            color: "#fff",
          },
        }}
        localization={tableProperties.localization}
      />
    </div>
  );
};
