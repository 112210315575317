import React, { forwardRef, useState } from "react";
import "./styles.css";
import ArrowDropUp from "@material-ui/icons/ArrowDropUp";
import TextField from "@material-ui/core/TextField";
import { withStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";

import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import InputAdornment from "@material-ui/core/InputAdornment";
import DateRangeIcon from "@material-ui/icons/DateRange";

export const TableTextField = withStyles({
  root: {
    "& .MuiInputBase-root": {
      fontSize: "14px",
      backgroundColor: "#fff",
    },
    "& label.Mui-focused": {
      color: "#41aba",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#272727",
      },
    },
  },
})(TextField);

export const TableIcons = {
  SortArrow: forwardRef((props, ref) => (
    <div className="header-icon-sorting">
      <ArrowDropUp {...props} ref={ref} />
    </div>
  )),
};

export const TableHeaderColumn = ({ title, titleTooltip }) => (
  <Tooltip title={titleTooltip ?? title}>
    <span className="header-column">{title}</span>
  </Tooltip>
);

export const TableCustomFilterDatePicker = (props) => {
  const [date, setDate] = useState(null);
  const handlerOnChange = (date, value) => {
    const convertValue = value ? value.split("_")[0] : undefined;
    props.onFilterChanged(props.columnDef.tableData.id, convertValue);
    setDate(date);
  };

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <KeyboardDatePicker
        id={props.id}
        inputVariant="outlined"
        className="customKeyboardDatePicker input-filter-sm"
        disableToolbar={true}
        clearable
        value={date}
        placeholder="dd/mm/aaaa"
        onChange={handlerOnChange}
        format="dd/MM/yyyy"
        invalidDateMessage={<small></small>}
        KeyboardButtonProps={{ disabled: true, style: { display: "none" } }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <DateRangeIcon />
            </InputAdornment>
          ),
        }}
        size="small"
      />
    </MuiPickersUtilsProvider>
  );
};
