import React, { useEffect, useState } from "react";
import { Button } from "../Button";
import { Container, TextField } from "@material-ui/core";
import { CustomModal } from "../CustomModal";
import { DateTime } from "luxon";
import { ThimeSheetAccordion } from "../../Components/TimeSheetAccordion/TimeSheetAccordion";
import { useDispatch } from "react-redux";
import { setAlert, setLoading } from "../../store/App/App.actions";
import axios from "../../Engine/Infra/CustomAxios";
import styles from "../../Styles";
import "./styles.css";

export const TaskTimeCommentModal = ({ taskWeek, open, handleCloseModal }) => {
  const MAX_COMMENT_CHARS = 500;
  const regexContainsUrl = RegExp(
    "([a-zA-Z0-9]+://)?([a-zA-Z0-9_]+:[a-zA-Z0-9_]+@)?([a-zA-Z0-9.-]+\\.[A-Za-z]{2,4})(:[0-9]+)?(/.*)?"
  );

  const dispatch = useDispatch();
  const [modalTitle] = useState(
    `Editar Comentário - ${taskWeek.taskName} - (${taskWeek.week.start.toFormat(
      "dd/MM/yyyy"
    )} - ${taskWeek.week.end.toFormat("dd/MM/yyyy")})`
  );
  const [taskTimeComment, setTaskTimeComment] = useState({
    name: "",
    realizationDate: "",
    records: [],
  });
  const [expandedComments, setExpandedComments] = useState(new Set());

  useEffect(() => {
    dispatch(setLoading(true));
    const weekStart = taskWeek.week.start.toFormat("yyyy-MM-dd");
    axios
      .get(`/time/task/${taskWeek.taskId}/week/${weekStart}/comments`)
      .then((response) => {
        const data = response.data;
        if (data.records.length > 0) {
          expandedComments.add(data.records[0].id);
          setExpandedComments(new Set(expandedComments));
        }
        setTaskTimeComment(data);
      })
      .catch((error) => {
        dispatch(
          setAlert({
            open: true,
            type: "error",
            message:
              error.response.data.message ||
              "Erro ao buscar comentários. Contate algum administrador",
          })
        );
        handleCloseModal();
      })
      .finally(() => dispatch(setLoading(false)));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleForm = async () => {
    if (!validateForm()) return;

    dispatch(setLoading(true));
    axios
      .put("/time/comments", createComments())
      .then(() => {
        handleCloseModal();
      })
      .catch((error) => {
        dispatch(
          setAlert({
            open: true,
            type: "error",
            message:
              error.response.data.message ||
              "Erro ao salvar comentário(s). Contate algum administrador",
          })
        );
      })
      .finally(() => dispatch(setLoading(false)));
  };

  const validateForm = () => {
    let hasErrors = taskTimeComment.records.filter((t) => !!t.error).length > 0;

    if (hasErrors) {
      dispatch(
        setAlert({
          open: true,
          type: "error",
          message: "Existe(m) comentário(s) inválido(s)",
        })
      );
    }

    return !hasErrors;
  };
  const handleInputComment = (id, newValue) => {
    const timeRecord = taskTimeComment.records.find((t) => t.id === id);
    if (timeRecord) {
      timeRecord.comment = newValue;
      timeRecord.error = validateComment(newValue);
      setTaskTimeComment({ ...taskTimeComment });
    }
  };

  const validateComment = (comment) => {
    if (comment?.length > MAX_COMMENT_CHARS) {
      return "Campo deve possuir menos de 500 caracteres";
    } else if (regexContainsUrl.test(comment)) {
      return "Campo não pode conter URL's";
    } else {
      return null;
    }
  };

  const createComments = () => {
    const timeRecordsComments = taskTimeComment.records.map((timeRecord) => {
      return {
        id: timeRecord.id,
        comment: timeRecord.comment,
      };
    });
    return { timeRecordsComments: timeRecordsComments };
  };

  const onChangeAccordion = (expanded, id) => {
    if (expanded) {
      expandedComments.add(id);
    } else {
      expandedComments.delete(id);
    }
    setExpandedComments(new Set(expandedComments));
  };

  return (
    <Container>
      <CustomModal
        title={modalTitle || ""}
        open={open}
        onClose={handleCloseModal}
      >
        {taskTimeComment.records.map((timeRecord, _) => {
          return (
            <ThimeSheetAccordion
              subtitle={
                taskTimeComment.name +
                " (" +
                DateTime.fromFormat(
                  timeRecord.realizationDate,
                  "yyyy-MM-dd"
                ).toFormat("dd/MM/yyyy") +
                ")"
              }
              name={taskTimeComment.name}
              key={`${taskTimeComment.name}_${timeRecord.id}`}
              styles={styles}
              onChange={(e) => onChangeAccordion(e, timeRecord.id)}
              defaultExpanded={expandedComments.has(timeRecord.id)}
            >
              <div className="row">
                <TextField
                  multiline={true}
                  fullWidth
                  variant="outlined"
                  label="Comentário"
                  value={timeRecord.comment || ""}
                  onChange={(e) =>
                    handleInputComment(timeRecord.id, e.target.value)
                  }
                  error={!!timeRecord.error}
                  helperText={timeRecord.error}
                  rows={10}
                  cols={60}
                />
              </div>
            </ThimeSheetAccordion>
          );
        })}
        <div style={{ width: "100%", textAlign: "right" }}>
          <Button
            style={{ marginTop: "20px", marginBottom: "30px" }}
            onClick={handleForm}
          >
            Salvar comentário
          </Button>
        </div>
      </CustomModal>
    </Container>
  );
};
