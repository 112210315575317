import React from "react";
import { BrowserRouter, Switch } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Snackbar } from "@material-ui/core";
import "fontsource-roboto";
import styles from "./Styles";
import { Header } from "./Components/Header";
import { Loading } from "./Components/Loading";
import { setAlert } from "./store/App/App.actions";
import Alert from "./Components/Alert";
import { getAppInsights } from "./Config/TelemetryService";
import TelemetryProvider from "./Config/telemetry-provider";
import RenderRoute from "./RenderRoutes";
import { routes } from "./routes";

export default () => {
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.app.loading);
  const alert = useSelector((state) => state.app.alert);

  const handleCloseAlert = (_, reason) => {
    if (reason === "clickaway") {
      return;
    }
    dispatch(setAlert({ ...alert, open: false }));
  };
  return (
    <div>
      <styles.GlobalStyles />
      <Loading isLoading={loading} />
      <Snackbar
        title={`${alert.type === "error" ? "Erro" : "Sucesso"} - ${
          alert.message
        }`}
        open={alert.open}
        autoHideDuration={alert.seconds}
        onClose={handleCloseAlert}
      >
        <Alert onClose={handleCloseAlert} severity={alert.type}>
          {alert.message}
        </Alert>
      </Snackbar>
      <BrowserRouter>
        <TelemetryProvider
          after={() => {
            getAppInsights();
          }}
        >
          <Header />
          <Switch>
            {routes.map((route) => (
              <RenderRoute {...route} key={route.path} />
            ))}
          </Switch>
        </TelemetryProvider>
      </BrowserRouter>
    </div>
  );
};
