export function setLoadingAddPage(loading) {
  return {
    type: "LOADING_ADD_PAGE",
    payload: loading,
  };
}
export function logout() {
  return {
    type: "LOGOUT",
  };
}
export function changeSearchText(searchText) {
  return {
    type: "CHANGE_SEARCH_TEXT",
    payload: searchText,
  };
}
export function changeColumnFilters(columnFilters) {
  return {
    type: "CHANGE_COLUMN_FILTERS",
    payload: columnFilters,
  };
}
export function clearFilters() {
  return {
    type: "CLEAR_FILTERS",
  };
}
