import React, { createRef, useRef } from "react";
import styled from "styled-components";
import IconButton from "@material-ui/core/IconButton";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import Comment from "@material-ui/icons/Comment";
import { DateTime } from "luxon";
import { qtyToTime } from "../../Engine/Infra/TimeConverter";
import styles from "../../Styles";
import { InputMaskHour } from "../InputMaskHour";
import {
  checkIfTheDayNumberIsWeekend,
  translateNumberDayToNameDay,
} from "../../Shared/functions";

const Card = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  min-height: 62px;
  background-color: #fff;
  margin-top: 15px;
  margin-bottom: 15px;
  border: 1px solid #e1e2f4;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  padding: 10px 0px;
  @media (max-width: ${(props) => props.styles.screenSizes.desktop}) {
    flex-direction: column;
    &:last-child {
      margin-bottom: 40px;
    }
  }
`;

const Title = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  margin-left: 10px;
  span {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.1px;
    color: #272727;
  }
  @media (max-width: ${(props) => props.styles.screenSizes.desktop}) {
    padding: 10px;
    justify-content: center;
  }
`;

const ContainerHoursDayAndWeek = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  gap: 5px;
  @media (max-width: ${(props) => props.styles.screenSizes.desktop}) {
    flex-wrap: wrap;
    padding: 10px;
    justify-content: center;
    align-items: center;
  }
`;

const BoxHoursDayAndWeek = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  border: ${({ zeroHours }) =>
    zeroHours ? "1px solid #272727" : "1px solid #a1a1a1"};
  background-color: ${({ zeroHours }) =>
    zeroHours ? "rgba(237, 237, 237, 0.5)" : "#fff"};
  width: 88px;
  height: 56px;
  border-radius: 4px;
  box-sizing: border-box;
  opacity: ${({ weekends, zeroHours }) => (weekends && !zeroHours ? 0.5 : 1)};
  /* @media (max-width: ${(props) => props.styles.screenSizes.desktop}) {
    border: 1px solid transparent;
    width: calc(100% / 8);
    justify-content: center;
    align-items: center;
    gap: 1px;
  } */
  input {
    border: 0;
    background-color: transparent;
    width: 100%;
    height: 56px;
    text-align: center;
    font-family: Roboto;
    font-size: 27px;
    line-height: 29px;
    font-style: normal;
    font-weight: normal;
    color: ${({ zeroHours }) => (zeroHours ? "#272727" : "#a1a1a1")};
    position: absolute;
    left: 0;
    bottom: 0;
    right: 0;
    top: 0;
    padding-top: 15px;

    &:disabled {
      opacity: 0.3;
    }

    @media (max-width: ${(props) => props.styles.screenSizes.desktop}) {
      font-size: 12px;
      padding: 0;
      height: 56px;
      text-align: center;
      padding-top: 5px;
    }
  }
`;

const BoxWeekAndDay = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 8px;
  /* gap: 5px; */
  flex-wrap: wrap;
`;

const WeekAndDayTypography = styled.span`
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.4px;
  color: ${({ zeroHours }) => (zeroHours ? "#5d64c6" : "#A1A1A1")};
`;

const Week = styled.div``;
const Day = styled.div``;

const ContainerTotalHours = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 43%;
  gap: 10px;
  span {
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
    text-align: right;
    letter-spacing: 0.15px;
    color: #2d3380;
  }
`;

export const TaskCard = ({
  task,
  onChange,
  daysOfWeek,
  handleRemoveTaskFromTimesheet,
  handleRegisterCommentFromTimesheet,
  disabledInputMaskHour,
}) => {
  const refArray = useRef(daysOfWeek.map(() => createRef()));

  const calculateTotalHours = () => {
    const total = task.records.reduce((total, record) => {
      if (daysOfWeek.includes(record.date)) return total + record.hours;
      else return total;
    }, 0);
    return total;
  };

  return (
    <Card styles={styles} title="Bloco da tarefa">
      <Title styles={styles} title="Nome da tarefa">
        {task.name}
      </Title>
      <ContainerHoursDayAndWeek
        styles={styles}
        title="Bloco da semana da tarefa"
        data-testid="bloco-semana"
      >
        {daysOfWeek &&
          daysOfWeek.map((day, index) => {
            const hasInTheRecords = task.records.filter(
              (record) => record.date === day
            );

            const date = DateTime.fromFormat(day, "yyyy-MM-dd").setLocale("pt");
            const hours = hasInTheRecords[0]?.hours
              ? qtyToTime(hasInTheRecords[0].hours)
              : "00:00";

            const iRef = refArray.current[index];

            const onInputChanged = (time, ref, position) => {
              if (time === "") time = "00:00";
              else if (time.includes("_")) time = time.replace("_", "0");
              const date = day;
              const taskId = task.idTask;
              const clientId = task.clientId;
              const nextField = refArray.current[position + 1];
              const currentField = ref;
              onChange({
                time,
                date,
                taskId,
                clientId,
                oldValue: hours,
                nextField,
                currentField,
              });
            };
            const zeroHours = hours !== "00:00";
            const weekends = checkIfTheDayNumberIsWeekend(date.toFormat("c"));
            return (
              <BoxHoursDayAndWeek
                styles={styles}
                title={`Campo do dia ${date.toFormat("dd/MM")} da tarefa ${
                  task.name
                }`}
                id={hours}
                key={index + "_" + Date.now().toLocaleString()}
                zeroHours={zeroHours}
                weekends={weekends}
                position={index}
              >
                <BoxWeekAndDay title="Bloco do dia" styles={styles}>
                  <Week styles={styles}>
                    <WeekAndDayTypography
                      zeroHours={zeroHours}
                      title="Nome do dia da semana"
                    >
                      {translateNumberDayToNameDay(date.toFormat("c"))}
                    </WeekAndDayTypography>
                  </Week>
                  <Day styles={styles}>
                    <WeekAndDayTypography
                      zeroHours={zeroHours}
                      title="Número do dia da semana"
                    >
                      {date.toFormat("d")}
                    </WeekAndDayTypography>
                  </Day>
                </BoxWeekAndDay>
                <InputMaskHour
                  onChange={onInputChanged}
                  initialValue={hours}
                  disabled={disabledInputMaskHour}
                  iRef={iRef}
                  name={day}
                  position={index}
                />
              </BoxHoursDayAndWeek>
            );
          })}
      </ContainerHoursDayAndWeek>
      <ContainerTotalHours styles={styles}>
        <span title="Carga horária total da semana">
          {qtyToTime(calculateTotalHours())}
        </span>
        <IconButton
          size="small"
          title="Botão para remover tarefa da semana"
          aria-label="Remover"
          onClick={() => handleRemoveTaskFromTimesheet(task.userTaskId)}
          style={{ backgroundColor: "#AEB2E3", width: 24, height: 24 }}
        >
          <VisibilityOffIcon style={{ color: "#fff", width: 20, height: 17 }} />
        </IconButton>
        <IconButton
          size="small"
          title="Botão para registar comentário para os horários da tarefa da semana"
          aria-label="Comentários"
          onClick={() => handleRegisterCommentFromTimesheet(task.userTaskId)}
          style={{ backgroundColor: "#AEB2E3", width: 24, height: 24 }}
        >
          <Comment style={{ color: "#fff", width: 20, height: 17 }} />
        </IconButton>
      </ContainerTotalHours>
    </Card>
  );
};
