import React from 'react';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';

export const Copyright = ()=> {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {'Copyright © '}
            <Link color="inherit" target="_blank" href="https://www.dbserver.com.br/">
                DBServer
            </Link>{' '}
            {` ${new Date().getFullYear()}.`}
        </Typography>
    );
}
