import React, { useEffect, useState } from 'react';
import TextField from '@material-ui/core/TextField';
import { Button } from '../Button';
import { Container } from '@material-ui/core';
import axios from '../../Engine/Infra/CustomAxios';
import { CustomModal } from '../CustomModal';
import './styles.css';

export const ClientModal = ({ currentClient, open, onClose, handleLoadingPage, handleTable, handleAlert }) => {
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [nameError, setNameError] = useState('');
  const [descriptionError, setDescriptionError] = useState('');
  const [modalTitle, setModalTitle] = useState('Incluir Cliente');

  const verifyIfFormIsValid = () => {
    let isValid = true;
    if (!name) {
      setNameError('Campo obrigatório');
      isValid = false;
    } else {
      setNameError('');
    }

    if (!description) {
      setDescriptionError('Campo obrigatório');
      isValid = false;
    } else {
      setDescriptionError('');
    }

    return isValid;
  };

  const handleInputName = (e) => {
    setName(e.target.value);
    if (!e.target.value) {
      setNameError('Campo obrigatório');
    } else {
      setNameError('');
    }
  };

  const handleInputDescription = (e) => {
    setDescription(e.target.value);
    if (!e.target.value) {
      setDescriptionError('Campo obrigatório');
    } else {
      setDescriptionError('');
    }
  };

  const handleForm = async () => {
    if (!verifyIfFormIsValid()) {
      return;
    }

    handleLoadingPage(true);
    if (currentClient) {
      handleUpdateClient();
    } else {
      handleCreateClient();
    }
  };

  const handleCreateClient = async () => {
    const data = { name, description };
    try {
      const response = await axios.post('/clients', data);
      if (response?.data.success) {
        handleAlert({ message: 'Cliente adicionado com sucesso', type: 'success' });
        handleTable('CREATE', response.data?.client);
        onClose();
      } else {
        handleAlert({ message: response.data.message ?? 'Erro ao criar cliente', type: 'error' });
      }
    } catch (error) {
      handleAlert({ message: error.response.data.message ?? 'Erro ao criar cliente', type: 'error' });
    }
    handleLoadingPage(false);
  };

  const handleUpdateClient = async () => {
    const data = { name, description };
    try {
      const response = await axios.put('/clients/' + currentClient.id, data);
      if (response?.data.success) {
        handleAlert({ message: 'Cliente alterado com sucesso', type: 'success' });
        handleTable('UPDATE', response.data?.client);
        onClose();
      } else {
        handleAlert({ message: response.data.message ?? 'Erro ao editar cliente', type: 'error' });
      }
    } catch (error) {
      handleAlert({ message: error.response.data.message ?? 'Erro ao editar cliente', type: 'error' });
    }
    handleLoadingPage(false);
  };

  useEffect(() => {
    if (currentClient) {
      setModalTitle('Editar Cliente');
      setName(currentClient.name);
      setDescription(currentClient.description);
    }
  }, [currentClient]);

  return (
    <Container>
      <CustomModal title={modalTitle} onClose={onClose} open={open}>
        <div className="row">
          <TextField
            required
            variant="outlined"
            onChange={handleInputName}
            label="Nome"
            fullWidth
            value={name}
            error={!!nameError}
            helperText={nameError}
          />
        </div>
        <div className="mt_30">
          <TextField
            required
            variant="outlined"
            onChange={handleInputDescription}
            multiline
            rows={5}
            label="Descrição"
            fullWidth
            value={description}
            error={!!descriptionError}
            helperText={descriptionError}
          />
        </div>
        <Button style={{ marginTop: '20px', marginBottom: '30px', float: 'right' }} onClick={handleForm}>
          Salvar cliente
        </Button>
      </CustomModal>
    </Container>
  );
};
