import React, { useState } from "react";
import { Container } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import { CustomModal } from "../CustomModal";
import Button from "@material-ui/core/Button";

import "./styles.css";
// import { setAlert, setLoading } from "../../store/App/App.actions";
// import { useDispatch } from "react-redux";

const initForm = {
  justification: "",
};

export const ProjectTaskTimeModalDelete = ({
  modalTitle,
  handleCloseModal,
  isOpen,
  operation,
  executionOperation,
  currentTaskTime,
}) => {
  const [form, setForm] = useState(initForm);
  const [errorForm, setErrorForm] = useState(initForm);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setForm({ ...form, [name]: value });
  };

  const validateField = (field, value) => {
    switch (field) {
      case "justification":
        if (value.length > 500) {
          setErrorForm({
            ...errorForm,
            [field]: "Campo deve possuir até 5000 caracteres",
          });
          return false;
        }
        return true;
      default:
        return true;
    }
  };

  const validateForm = () => {
    let formValid = true;
    Object.keys(form).forEach((field) => {
      formValid = validateField(field, form[field]);
    });
    return formValid;
  };

  const handleSubmit = () => {
    if (!validateForm()) return;
    executionOperation(operation, { ...form, id: currentTaskTime.id });
  };

  return (
    <Container>
      <CustomModal title={modalTitle} onClose={handleCloseModal} open={isOpen}>
        <h4 className="question">
          Deseja realmente excluir esse registro de horas? Esta ação não poderá
          ser desfeita.
        </h4>
        <div className="col">
          <TextField
            multiline
            rows={2}
            rowsMax={500}
            variant="outlined"
            onChange={handleChange}
            value={form.comment}
            error={!!errorForm.justification}
            helperText={errorForm.justification}
            id="justification"
            fullWidth
            type="text"
            name="justification"
            placeholder="Justificativa"
          />
        </div>

        <Button
          style={{ marginTop: "20px", marginBottom: "30px", float: "right" }}
          onClick={handleSubmit}
          color="primary"
          variant="text"
        >
          Sim
        </Button>
        <Button
          style={{ marginTop: "20px", marginBottom: "30px", float: "right" }}
          onClick={handleCloseModal}
          color="primary"
          variant="text"
        >
          Não
        </Button>
      </CustomModal>
    </Container>
  );
};
