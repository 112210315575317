import React, { useState } from 'react';
import {Container} from '@material-ui/core';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { CustomModal } from '../CustomModal';
import { ProjectForm } from '../ProjectForm';
import { ProjectTeamList } from '../ProjectTeamList';
import './styles.css';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export const ProjectModal = ({currentProject, open, onClose, handleLoadingPage, handleTable, handleAlert}) => {
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const [modalTitle] = useState('Novo projeto');

  return (
    <Container>
        <CustomModal  title={modalTitle} onClose={onClose} open={open}>
            <div className="root">
                <AppBar position='relative'>
                <Tabs variant="fullWidth" value={value} onChange={handleChange} aria-label="simple tabs example">
                    <Tab label="Projeto" {...a11yProps(0)} />
                    <Tab label="Time" {...a11yProps(1)} />
                    <Tab label="Tarefas" {...a11yProps(2)} />
                </Tabs>
                </AppBar>
                <TabPanel value={value} index={0} style={{maxWidth:'650px'}}>
                <ProjectForm
                    open={open} 
                    onClose={onClose} 
                    handleLoadingPage={handleLoadingPage} 
                    handleTable={handleTable} 
                    handleAlert={handleAlert}
                />
                </TabPanel>
                <TabPanel value={value} index={1}>
                <ProjectTeamList/>
                </TabPanel>
                <TabPanel value={value} index={2}>
                Item Three
                </TabPanel>
            </div>
        </CustomModal>
    </Container>
  );
}
