import React from "react";
import { Container } from "@material-ui/core";
import PropTypes from "prop-types";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
import { ProjectForm } from "../../Components/ProjectForm";
import { ProjectTeamList } from "../../Components/ProjectTeamList";
import { ProjectTaskList } from "../../Components/ProjectTask";
import Paper from "@material-ui/core/Paper";
import "./styles.css";
import { useLocation } from "react-router";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  root: {
    backgroundColor: "#e1e2f4",
    boxShadow: "0px 0px 4px rgba(65, 74, 186, 0.2)",
    color: "#32398f",
    border: "1px solid #32398f",
    borderRadius: "4px",
    "& .Mui-selected": {
      background: "#2D3380",
      color: "#e1e2f4",
    },
    "& .MuiTabs-indicator": {
      background: "#32398f",
    },
  },
});

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export const ProjectAdd = () => {
  const location = useLocation();
  const [value, setValue] = React.useState(0);
  const handleChange = (_, newValue) => {
    setValue(newValue);
  };
  const classes = useStyles();

  return (
    <Container maxWidth="xl">
      <Paper>
        {location.state?.id ? (
          <div className="mt50">
            <AppBar position="relative" style={{ background: "none" }}>
              <Tabs
                variant="fullWidth"
                className={classes.root}
                value={value}
                onChange={handleChange}
                aria-label="simple tabs example"
              >
                <Tab label="Projeto" {...a11yProps(0)} />
                <Tab
                  disabled={location.state?.status === "INACTIVE"}
                  label="Time"
                  {...a11yProps(1)}
                />
                <Tab
                  disabled={location.state?.status === "INACTIVE"}
                  label="Tarefas"
                  {...a11yProps(2)}
                />
              </Tabs>
            </AppBar>
            <TabPanel children={"span"} value={value} index={0}>
              <ProjectForm currentProject={location.state} />
            </TabPanel>
            <TabPanel value={value} index={1}>
              <ProjectTeamList currentProject={location.state} />
            </TabPanel>
            <TabPanel value={value} index={2}>
              <ProjectTaskList currentProject={location.state} />
            </TabPanel>
          </div>
        ) : (
          <div className="div-without-tab">
            <h2 className="form-title">Novo Projeto</h2>
            <ProjectForm currentProject={location.state} />
          </div>
        )}
      </Paper>
    </Container>
  );
};
