export default function (state = {}, action){
    switch (action.type) {
        case 'SIGNIN': {
            return action.payload;
        }
        case 'LOGOUT': {
            return  {};
        }
        default:
            return state;
    }
};

