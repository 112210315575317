import React, { Fragment, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import List from '@material-ui/core/List'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import ExpandLess from '@material-ui/icons/ExpandLess'
import ExpandMore from '@material-ui/icons/ExpandMore'
import Collapse from '@material-ui/core/Collapse'

import SimpleMenuItem from './simpleMenuItem'

const ChildrenMenuItem = ({ itens, handlerClick }) => {
  const dispatch = useDispatch()
  const menuSelected = useSelector((state) => state.app.menuSelected)
  const [open, setOpen] = useState(menuSelected.open)

  const handlerOpenItem = (item) => {
    setOpen(!open)
    dispatch({ type: 'MENU_SELECTED', payload: { ...item, open: !menuSelected.open } })
  }

  return (
    <Fragment>
      <SimpleMenuItem
        onClick={() => handlerOpenItem(itens)}
        path={itens.path}
        handlerClick={handlerClick}
      >
        {itens.icon && <ListItemIcon>{itens.icon}</ListItemIcon>}
        <ListItemText primary={itens.description} inset={!itens.icon} />
        {itens.children.length && !open ? <ExpandMore /> : null}
        {itens.children.length && open ? <ExpandLess /> : null}
      </SimpleMenuItem>
      {itens.children.length ? (
        <Collapse in={open} timeout="auto" unmountOnExit>
          <div className="children-container">
            <List component="div">
              {itens.children.map((item, index) => (
                <ChildrenMenuItem
                  itens={item}
                  key={index}
                  handlerClick={handlerClick}
                />
              ))}
            </List>
          </div>
        </Collapse>
      ) : null}
    </Fragment>
  )
}

export default ChildrenMenuItem
