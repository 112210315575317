import axios from "../Infra/CustomAxios";
import publicClientApplication from "../../AzureAd";

export const getCurrentUser = async () => {
  return await axios.get("users/current").then((res) => res.data);
};

export const signOut = () => {
  localStorage.removeItem("token");
  publicClientApplication.logout();
};

export const getCurrentAzureAccount = () => {
  const account = publicClientApplication.getAllAccounts();
  if (account.length === 0) return null;
  const currentAccount = publicClientApplication.getAccountByUsername(
    account[0].username
  );
  return currentAccount;
};

export const getToken = () => {
  const token = JSON.parse(localStorage.getItem("token"));
  return token;
};

export const saveToken = (userName, accessToken) => {
  const token = {
    userName,
    accessToken,
  };
  localStorage.setItem("token", JSON.stringify(token));
};

export const removeToken = () => {
  localStorage.removeItem("token");
};

export const getAzureRefreshedToken = async () => {
  const azureAccount = getCurrentAzureAccount();
  if (azureAccount) {
    const silentRequest = {
      prompt: "select_account",
      scopes: ["api://1ec01e83-43ce-41d3-be9f-3af1a02da186/dbtime.all"],
      account: azureAccount,
      forceRefresh: true,
    };
    return await publicClientApplication
      .acquireTokenSilent(silentRequest)
      .then(async (tokenResponse) => {
        return tokenResponse.accessToken;
      })
      .catch(async (_) => {
        const user = await publicClientApplication.acquireTokenPopup(
          silentRequest
        );
        if (user) return user.accessToken;
      })
      .catch(async (e) => {
        window.localStorage.clear();
        window.location.assign("/");
      });
  } else {
    throw new Error("User not logged");
  }
};

export const silentLogin = async () => {
  const azureAccount = getCurrentAzureAccount();
  if (azureAccount) {
    const silentRequest = {
      prompt: "select_account",
      scopes: ["api://1ec01e83-43ce-41d3-be9f-3af1a02da186/dbtime.all"],
      account: azureAccount,
      forceRefresh: true,
    };
    return await publicClientApplication
      .acquireTokenSilent(silentRequest)
      .then(async (_) => {
        return await getCurrentUser();
      })
      .catch(async (_) => {
        const azureUser = await publicClientApplication.acquireTokenPopup(
          silentRequest
        );
        if (!azureUser) return false;
        return await getCurrentUser();
      })
      .catch((_) => {
        window.localStorage.clear();
        return null;
      });
  }
};
