import axios from "../Infra/CustomAxios";
import { timeToQty } from "../Infra/TimeConverter";

export const saveTime = async (hour, taskId, dateStr) => {
  const payload = {
    time: timeToQty(hour),
    taskId,
    realizationDate: dateStr,
  };

  const response = await axios.post("/time", payload);
  return response.data;
};

export const getTasksByWeekAndCurrentUser = async (startDayOfWeek) => {
  return await axios.get(`/time/week/${startDayOfWeek}`);
};
