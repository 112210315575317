import React, { useEffect, useState } from "react";
import { Container } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { DateTime } from "luxon";
import { InputMaskHour } from "../InputMaskHour";
import { CustomModal } from "../CustomModal";
import { Button } from "../Button";
import axios from "../../Engine/Infra/CustomAxios";
import useFormValidate from "./useFormValidate";
import { setAlert, setLoading } from "../../store/App/App.actions";
import { useDispatch } from "react-redux";
import { qtyToTime, timeToQty } from "../../Engine/Infra/TimeConverter";
import "./styles.css";

const MAX_HOURS_DAY = 1440;

const INITI_FORM = {
  id: "",
  userId: "",
  userName: "",
  realizationDate: "",
  time: "",
  justification: "",
};

export const ProjectTaskTimeModal = ({
  modalTitle,
  handleCloseModal,
  isOpen,
  operation,
  currentTaskTime,
  currentTask,
  currentProject,
  executionOperation,
  validateDateNewRegisterInList,
}) => {
  const dispatch = useDispatch();
  const [allUsers, setAllUsers] = useState([]);
  const [totalHoursOfDay, setTotalHoursOfDay] = useState({});

  const customValidationFields = {
    realizationDate: {
      custom: {
        isValidate: (value) => {
          let validaDate = true;
          let message = "";
          const realizationDate = value;

          const beginningDateProject = DateTime.fromFormat(
            currentProject.beginningDate,
            "dd/MM/yyyy"
          ).toFormat("yyyy-MM-dd");

          const recordsReleaseDateProject = DateTime.fromFormat(
            currentProject.recordsReleaseDate,
            "dd/MM/yyyy"
          ).toFormat("yyyy-MM-dd");

          if (realizationDate < beginningDateProject) {
            message =
              "Data do lançamento deve ser maior ou igual que a data de início do projeto";
            validaDate = false;
          }

          if (realizationDate > currentProject.endingDate) {
            message =
              "Data do lançamento deve ser menor ou igual que a data de término do projeto";
            validaDate = false;
          }

          if (realizationDate < recordsReleaseDateProject) {
            message =
              "Data de lançamento deve ser maior ou igual que data de liberação do projeto";
            validaDate = false;
          }

          if (realizationDate < currentTask.beginningDate) {
            message =
              "Data de lançamento deve ser maior ou igual que a data de início da tarefa";
            validaDate = false;
          }

          if (realizationDate > currentTask.endingDate) {
            message =
              "Data de lançamento deve ser menor ou igual que a data de término da tarefa";
            validaDate = false;
          }
          if (!validaDate) {
            dispatch(
              setAlert({
                open: true,
                type: "error",
                message,
              })
            );
          }
          return validaDate;
        },
        menssage: "invalid date",
      },
    },
  };

  const totalHoursInTheDayIsGreaterThan24 = (data) => {
    return (
      data.time > MAX_HOURS_DAY ||
      data.time + totalHoursOfDay.totalOfDay > MAX_HOURS_DAY
    );
  };

  const callbackUseFormValidate = (data) => {
    data.time = !Number.isInteger(data.time) ? timeToQty(data.time) : data.time;
    const convertDate = DateTime.fromFormat(
      data.realizationDate,
      "yyyy-MM-dd"
    ).toFormat("dd/MM/yyyy");

    const param = {
      userId: data.userId,
      date: convertDate
    };
     
  
    if (operation === "insert" &&  validateDateNewRegisterInList(param) > 0) {
      dispatch(
        setAlert({
          open: true,
          type: "error",
          message: `Registro de horas já existe nesta tarefa (${currentTask.name} - ${convertDate}) para o colaborador selecionado. Para fazer o ajuste, é necessário “Editar” o registro`,
        })
      );
      return;
    }
    if (totalHoursInTheDayIsGreaterThan24(data)) {
      dispatch(
        setAlert({
          open: true,
          type: "error",
          message:
            "Não é possível salvar horário. Tempo superior a 24h diárias",
        })
      );
      return;
    }

    data.currentTaskId = currentTask.id;
    executionOperation(operation, data);
  };

  const { errors, values, handleChange, handleSubmit } = useFormValidate(
    !!currentTaskTime
      ? {
          ...currentTaskTime,
          realizationDate: DateTime.fromFormat(
            currentTaskTime.realizationDate,
            "dd/MM/yyyy"
          ).toFormat("yyyy-MM-dd"),
        }
      : INITI_FORM,
    customValidationFields,
    callbackUseFormValidate
  );

  const translaterOperation = (operation) => {
    const operations = {
      insert: "Incluir",
      update: "Editar",
    };
    return operations[operation];
  };

  useEffect(() => {
    dispatch(setLoading(true));
    const getUsers = async () => {
      const response = await axios.get("/users/active");
      const idsUserTasks = currentTask.team.map((user) => user.id);
      setAllUsers(
        response.data.users.filter((user) => idsUserTasks.includes(user.id))
      );
      dispatch(setLoading(false));
    };
    getUsers();
  }, [currentTask.openTask, currentTask.team, dispatch]);

  useEffect(() => {
    const findTotalHoursByUserAndRealizationDate = async (
      userId,
      realizationDate
    ) => {
      if (userId && realizationDate) {
        dispatch(setLoading(true));
        try {
          const totalResponse = await axios.get(
            `/time/user/${userId}/realizationDate/${realizationDate}/total-hours`
          );
          const totalOfDay =
            totalResponse.data.total -
            (currentTaskTime ? currentTaskTime.time : 0);
          setTotalHoursOfDay({ totalOfDay: totalOfDay });
        } catch (e) {
          dispatch(
            setAlert({
              open: true,
              type: "error",
              message: "Erro ao consultar total de horas do dia",
            })
          );
        } finally {
          dispatch(setLoading(false));
        }
      }
    };

    findTotalHoursByUserAndRealizationDate(
      values.userId,
      values.realizationDate
    );
  }, [values.realizationDate, values.userId, currentTaskTime, dispatch]);

  return (
    <Container>
      <CustomModal
        title={`${translaterOperation(operation)} ${modalTitle}`}
        onClose={handleCloseModal}
        open={isOpen}
      >
        <h4>{currentTask.name}</h4>
        <div className="wrapper-container-modal">
          <div className="col">
            <Autocomplete
              fullWidth
              disabled={!!currentTaskTime}
              defaultValue={
                currentTaskTime
                  ? { id: values.userId, name: values.userName }
                  : null
              }
              getOptionSelected={(option, value) => option.id === value.id}
              onChange={(event, value) => {
                handleChange({
                  persist: event.persist,
                  target: { name: "userId", value: value?.id },
                });
              }}
              options={allUsers}
              getOptionLabel={(option) => option.name}
              renderInput={(params) => (
                <TextField
                  error={!!errors?.userId}
                  helperText={errors?.userId}
                  label="Colaborador"
                  required={true}
                  variant="outlined"
                  {...params}
                />
              )}
            />
          </div>
          <div className="col">
            <div className="col-w-2">
              <TextField
                className="dates-style"
                required={true}
                variant="outlined"
                onChange={handleChange}
                label="Data de Lançamento"
                value={values.realizationDate}
                error={!!errors.realizationDate}
                helperText={
                  errors.realizationDate !== "invalid date"
                    ? errors.realizationDate
                    : ""
                }
                id="realizationDate"
                fullWidth
                type="date"
                name="realizationDate"
                InputLabelProps={{
                  shrink: true,
                }}
                disabled={!!currentTaskTime || !values.userId}
                InputProps={{ inputProps: { max: "5000-12-31" } }}
              />
            </div>
            <div className="col-w-2">
              <div className="field-hours">
                <div id="ContainerHoursDayAndWeek" className="container-hours">
                  <div
                    id="BoxHoursDayAndWeek"
                    className={`box-hours ${
                      errors.time ? "box-hours-error" : ""
                    }`}
                  >
                    <InputMaskHour
                      onChange={(value) => {
                        if (value === "") value = "00:00";
                        else if (value.includes("_")) value = value.replace("_", "0");
                        handleChange({
                          target: {
                            name: "time",
                            value,
                          },
                        })
                      }}
                      initialValue={
                        values.time ? qtyToTime(values.time) : "00:00"
                      }
                      disabled={!values.userId}
                    />
                  </div>
                </div>
                {errors.time && <p className="error-text">{errors.time}</p>}
              </div>
            </div>
          </div>
          <div className="col">
            <TextField
              multiline
              rows={2}
              rowsMax={500}
              variant="outlined"
              onChange={handleChange}
              value={values.justification}
              error={!!errors.justification}
              helperText={errors.justification}
              id="justification"
              fullWidth
              type="text"
              name="justification"
              placeholder="Justificativa"
              disabled={!values.userId}
            />
          </div>
        </div>
        <Button
          style={{ marginTop: "20px", marginBottom: "30px", float: "right" }}
          onClick={handleSubmit}
          disabled={
            !values.userId ||
            !values.realizationDate ||
            !values.time ||
            values.time === "00:00"
          }
        >
          Salvar {modalTitle}
        </Button>
      </CustomModal>
    </Container>
  );
};
