export function signed (signedInUser) {
    return {
        type: 'SIGNIN',
        payload: signedInUser
    }
}
export function logout () {
    return {
        type: 'LOGOUT',
    }
}
