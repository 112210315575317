const defaultState = {
    loading: false,
    menuSelected: {
        name: '',
        path: '',
        description: '',
        profiles: [],
        children: [],
        open: false
    },
    alert: {
        open: false,
        type: '',
        message: ''
    }
}
export default function (state = defaultState, action){
    switch (action.type) {
        case 'LOADING': {
            return {
                ...state,
                loading: action.payload
            }
        }
        case 'ALERT': {
            return {
                ...state,
                alert: action.payload
            }
        }
        case 'MENU_SELECTED': {
            return {
                ...state,
                menuSelected: action.payload
            }
        }
        default:
            return state;
    }
};

