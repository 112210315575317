import React from "react";
import ReactDOM from "react-dom";
import App from "./App";

import { createMuiTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";

import { store, persistedStore } from "./store/store";

//import './index.css';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#414aba", //Cor da DBServer
    },
    secondary: {
      main: "#E00976",
    },
  },
});

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate persistor={persistedStore}>
        <ThemeProvider theme={theme}>
          <App />
        </ThemeProvider>
      </PersistGate>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

// ReactDOM.render(<App />, document.getElementById('root'));
