import { PublicClientApplication } from '@azure/msal-browser';
import { azureConfig }  from './AzureConfig';


const publicClientApplication = new PublicClientApplication({
    auth: {
        clientId: azureConfig.appId,
        redirectUri: azureConfig.redirectUri,
        authority: azureConfig.authority,
        postLogoutRedirectUri: azureConfig.postLogoutRedirectUri
    },
    cache: {
        cacheLocation: "localStorage",
        storeAuthStateInCookie: true
    }
});

export default publicClientApplication;