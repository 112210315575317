import { createStore, combineReducers } from "redux";
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import userReducer from './User/User.reducer';
import timeSheetReducer from './Timesheet/timeSheet.reducer';
import projectReducer from './Project/Project.reducer';
import appReducer from './App/App.reducer';

const reducers = combineReducers({
    signedInUser: userReducer,
    timeSheet: timeSheetReducer,
    project: projectReducer,
    app: appReducer
});

const persistedReducer = persistReducer({
   key: 'root',
   storage,
   whitelist : ['signedInUser']
}, reducers)

export const store = createStore(persistedReducer);
export const persistedStore = persistStore(store);

