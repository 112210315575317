import moment from "moment";

import "moment-duration-format";

export const timeToQty = (time) => {
  const hoursMinutes = time.split(":");
  const hours = parseInt(hoursMinutes[0]);
  const minutes = parseInt(hoursMinutes[1]);
  let number = hours * 60 + minutes;
  return number;
};
export const qtyToTime = (qty) => {
  let hours = moment.duration(qty, "minutes").format("HH:mm");
  if (!hours.includes(":")) {
    hours = "00:" + hours;
  }
  return hours;
};

export const calcDiffHoursTimeAndOld = (time, oldTime) =>
  parseInt(time.split(":")[0]) - parseInt(oldTime.split(":")[0]);

export const sumQtyHoursForQtyDiffTime = (diffTime) => diffTime * 60;

export const sumTotalHoursWithNewHourDay = (totalHours, time, oldTime) => {
  const totalDiffDay = calcDiffHoursTimeAndOld(time, oldTime);
  const totalHoursMinutes = sumQtyHoursForQtyDiffTime(totalDiffDay);
  return totalHoursMinutes < 0
    ? totalHours - Math.abs(totalHoursMinutes)
    : totalHours + totalHoursMinutes;
};

export const minutesToHours = (qty, zeroEsquerda) => {
  const hours = parseInt(qty) / 60;

  return hours.toLocaleString("pt-br", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
    minimumIntegerDigits: zeroEsquerda,
  });
};
