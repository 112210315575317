import { Login } from "./Pages/Login";
import { TimeSheet } from "./Pages/TimeSheet";
import { UserList } from "./Pages/User";
import { ClientList } from "./Pages/Client";
import { ProjectList } from "./Pages/Project";
import { ProjectAdd } from "./Pages/ProjectAdd";
import { ReportProjectAppointments } from "./Pages/ReportProjectAppointments";
import { ReportUserPending } from "./Pages/ReportUserPending";
import { ReportUserAppointments } from "./Pages/ReportUserAppointments";
import { ProjectTaskTime } from "./Pages/ProjectTaskTime";

export const routes = [
  {
    path: "/timesheet",
    component: TimeSheet,
    exact: true,
    breadcrumbName: "",
  },
  {
    path: "/client",
    component: ClientList,
    exact: true,
    breadcrumbName: "Clientes",
  },
  {
    path: "/project",
    component: ProjectList,
    exact: true,
    breadcrumbName: "Projetos",
  },
  {
    path: "/project/view",
    component: ProjectAdd,
    exact: true,
    breadcrumbName: "Visualizar",
  },
  {
    path: "/project/new",
    component: ProjectAdd,
    exact: true,
    breadcrumbName: "Criar",
  },
  {
    path: "/project/task/time",
    component: ProjectTaskTime,
    exact: true,
    breadcrumbName: "Registro de Horas",
  },
  {
    path: "/user",
    component: UserList,
    exact: true,
    breadcrumbName: "Usuários",
  },
  {
    path: "/report/projectAppointments",
    component: ReportProjectAppointments,
    exact: true,
    breadcrumbName: "Relatório Geral de Horas",
  },
  {
    path: "/report/userPending",
    component: ReportUserPending,
    exact: true,
    breadcrumbName: "Relatório de Pendências",
  },
  {
    path: "/report/userAppointments",
    component: ReportUserAppointments,
    exact: true,
    breadcrumbName: "Consulta de Horas Trabalhadas",
  },
  {
    path: "/",
    component: Login,
    exact: true,
    breadcrumbName: "",
  },
];

export const breadcrumbNameMap = routes.reduce((prev, curr) => {
  if (!prev[curr.path]) prev[curr.path] = curr.breadcrumbName;
  return prev;
}, {});
