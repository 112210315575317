import React from 'react';
import { Paper } from "@material-ui/core"
import './styles.css';

export const NoProjectFound = () => {
    return (
        <div>
            <Paper className="bg">
                <span className="title">Nenhum projeto foi encontrado pra você   :(</span>
            </Paper>
        </div>
    )
}