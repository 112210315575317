const initialState = {
    timeRecords: [],
    loading: true
}
export default function (state = initialState, action){
    switch (action.type) {
        case 'SET_TIME_RECORDS': {
            return {
                ...state,
                timeRecords: action.payload
            };
        }
        case 'LOADING_TIMESHEET': {
            return {
                ...state,
                loading: action.payload
            };
        }
        case "TIMESHEET_ADDED": {
            const {clientId, taskId, date, time } = action.payload;
            state.timeRecords.forEach(item => {
              if(item.client !== clientId)
                return;
    
              item.tasks.forEach(task => {
                if(task.idTask !== taskId)
                  return;
    
                const foundDay = task.records.filter(record=> record.date === date);
                if(foundDay.length > 0) {
                  task.records.forEach(record=> {
                    if(record.date === date)
                    record.hours = time
                  });
                }else {
                  task.records.push({date, "hours": time});
                }
              });
            });
            return state;
          }
        default:
            return state;
    }
};

