import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { DateTime } from 'luxon';
import { timeToQty } from '../../Engine/Infra/TimeConverter';
import { WeekSelector } from '../../Components/WeekSelector';
import { TaskCard } from '../../Components/TaskCard';
import { HoursFloatingCard } from '../../Components/HoursFloatingCard';
import styles from '../../Styles';
import {
  saveTime,
  getTasksByWeekAndCurrentUser,
} from '../../Engine/Services/TimeService';
import { setTimeRecords } from '../../store/Timesheet/timeSheet.actions';
import { Container } from './styles';
import { NoProjectFound } from '../../Components/NoProjectFound';
import { ConfigWeekModal } from '../../Components/ConfigWeekModal';
import { TaskTimeCommentModal } from '../../Components/TaskTimeCommentModal';
import axios from '../../Engine/Infra/CustomAxios';
import { setAlert, setLoading } from '../../store/App/App.actions';
import confirmService from '../../Components/ConfirmDialog';
import { ThimeSheetAccordion } from '../../Components/TimeSheetAccordion/TimeSheetAccordion';
import {
  qtyToTime,
  sumTotalHoursWithNewHourDay,
} from '../../Engine/Infra/TimeConverter';

const MAX_TOTAL_HOURS_DAY = 24;

export const TimeSheet = () => {
  const signedInUser = useSelector((state) => state.signedInUser);
  const projects = useSelector((state) => state.timeSheet.timeRecords);
  const loading = useSelector((state) => state.timeSheet.loading);
  const appAlertOpen = useSelector((state) => state.app.alert.open);
  const [inputFocus, setInputFocus] = useState();

  const history = useHistory();
  const dispatch = useDispatch();
  const [currentWeek, setCurrentWeek] = useState({
    start: DateTime.local().startOf('week'),
    end: DateTime.local().endOf('week'),
  });
  const [configModalOpen, setConfigModalOpen] = useState(false);
  const [taskTimeCommentModalOpen, setTaskTimeCommentModalOpen] =
    useState(false);
  const [taskWeekSelected, setTaskWeekSelected] = useState();

  if (!signedInUser.id) {
    history.push('/');
  }
  useEffect(() => {
    if (inputFocus) {
      inputFocus.current.focus();
      inputFocus.current.select();
    }
  }, [appAlertOpen, inputFocus]);

  const buildDaysOfWeek = (dayOfStart) => {
    let days = [];
    for (let index = 0; index < 7; index++)
      days.push(dayOfStart.plus({ days: index }).toFormat('yyyy-MM-dd'));
    return days;
  };
  const daysOfWeek = buildDaysOfWeek(currentWeek.start);

  const getTasksByWeek = async () => {
    dispatch(setLoading(true));
    const start = currentWeek.start.toFormat('yyyy-MM-dd');
    try {
      const response = await getTasksByWeekAndCurrentUser(start);
      dispatch(setTimeRecords(response.data.records));
    } catch (error) {
      if (error?.response || error?.config) {
        dispatch(
          setAlert({
            open: true,
            type: 'error',
            message:
              error?.response?.data?.message ||
              'Erro ao buscar tarefas. Contate algum administrador',
          })
        );
      }
    }
    dispatch(setLoading(false));
  };

  const goToNextWeek = () => {
    const nextWeek = currentWeek.start.plus({ weeks: 1 });
    const todayMoreThirdDays = DateTime.local()
      .plus({ days: 30 })
      .toFormat('yyyy-MM-dd');

    if (nextWeek.startOf('week').toFormat('yyyy-MM-dd') > todayMoreThirdDays)
      return;

    setCurrentWeek({
      start: nextWeek.startOf('week'),
      end: nextWeek.endOf('week'),
    });
  };

  const goToPrevWeek = () => {
    const previousWeek = currentWeek.start.minus({ weeks: 1 });
    setCurrentWeek({
      start: previousWeek.startOf('week'),
      end: previousWeek.endOf('week'),
    });
  };

  useEffect(() => {
    getTasksByWeek();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentWeek]);

  const calculateTotals = (projects) => {
    const getRecordsOfProject = () =>
      projects.flatMap((project) =>
        project.tasks.flatMap((task) =>
          task.records.map((record) => ({
            date: record.date,
            hours: record.hours,
            hoursQty: record.hours,
          }))
        )
      );

    const records = getRecordsOfProject();
    const days = daysOfWeek.map((day) => {
      const recordsTotalHours = records.reduce((total, record) => {
        if (record.date === day) {
          return (total += record.hoursQty);
        } else {
          return total;
        }
      }, 0);
      return { total: recordsTotalHours, date: day };
    });
    return days;
  };

  const totalHoursInTheDayIsGreaterThan24 = (date, time, oldTime) => {
    const [totalHoursDay] = calculateTotals(projects).filter((horasDay) =>
      horasDay.date.includes(date)
    );
    const newTotalHoursDay = sumTotalHoursWithNewHourDay(
      totalHoursDay.total,
      time,
      oldTime
    );

    const hoursDay = qtyToTime(newTotalHoursDay).split(':')[0];

    return hoursDay > MAX_TOTAL_HOURS_DAY;
  };

  const onChangeTasks = async ({
    time,
    date,
    taskId,
    clientId,
    oldValue,
    nextField,
    currentField,
  }) => {
    if (time === oldValue) return;
    setInputFocus(nextField);
    if (totalHoursInTheDayIsGreaterThan24(date, time, oldValue)) {
      dispatch(
        setAlert({
          open: true,
          type: 'error',
          message:
            'Não é possível salvar horário. Tempo superior a 24h diárias.',
          seconds: 1000,
        })
      );
      setInputFocus(currentField);
      return;
    }

    dispatch({
      type: 'TIMESHEET_ADDED',
      payload: { clientId, taskId, date, time: timeToQty(time) },
    });
    dispatch(setLoading(true));
    try {
      const savedTime = await saveTime(time, taskId, date);
      if (savedTime.success) {
        dispatch(
          setAlert({
            open: true,
            type: 'success',
            message: 'Apontamento adicionado com sucesso',
            seconds: 1000,
          })
        );
      } else {
        setInputFocus(currentField);
        dispatch({
          type: 'TIMESHEET_ADDED',
          payload: { clientId, taskId, date, time: timeToQty(oldValue) },
        });
        dispatch(
          setAlert({
            open: true,
            type: 'error',
            message: savedTime.message ?? 'Erro ao adicionar apontamento',
            seconds: 1000,
          })
        );
      }
      dispatch(setLoading(false));
    } catch (error) {
      setInputFocus(currentField);
      dispatch({
        type: 'TIMESHEET_ADDED',
        payload: { clientId, taskId, date, time: timeToQty(oldValue) },
      });
      dispatch(
        setAlert({
          open: true,
          type: 'error',
          message:
            error.response?.data?.message ?? 'Erro ao adicionar apontamento',
          seconds: 1000,
        })
      );
      dispatch(setLoading(false));
    }
  };

  if (projects.length === 0 && !loading) {
    return <NoProjectFound></NoProjectFound>;
  }
  const handleConfigWeek = () => {
    setConfigModalOpen(true);
  };
  const handleCloseConfigWeekModal = () => {
    setConfigModalOpen(false);
    getTasksByWeek();
  };

  const totalHoursWeekUserTask = (userTaskId) => {
    return projects
      .flatMap((p) =>
        p.tasks
          .flatMap((t) => {
            if (t.userTaskId === userTaskId) return t.records;
          })
          .filter((t) => t)
      )
      .reduce((acc, { hours }) => acc + hours, 0);
  };

  const handleTimeSheetAccordionChange = async (expanded, projectId) => {
    const start = currentWeek.start.toFormat('yyyy-MM-dd');

    axios
      .patch(
        `/timesheet-user-configurations/week/${start}/project/${projectId}/expanded`,
        {
          expanded: expanded,
        }
      )
      .then((r) => {
        projects.forEach((item) => {
          if (item.id === projectId) item.expanded = expanded;
        });
      })
      .catch((e) => {
        dispatch(
          setAlert({
            open: true,
            type: 'warning',
            message: 'Erro ao salvar configuração da timesheet',
          })
        );
        projects.forEach((item) => {
          if (item.id === projectId) item.expanded = true;
        });
      });
  };

  const handleRemoveTaskFromTimesheet = async (userTaskId) => {
    const totalHoursWeek = totalHoursWeekUserTask(userTaskId);
    if (totalHoursWeek > 0) {
      dispatch(
        setAlert({
          open: true,
          type: 'warning',
          message: 'Não é permitido ocultar tarefas com lançamentos!',
        })
      );
    } else {
      const result = await confirmService.show({
        message: 'Você deseja remover esta tarefa da sua timesheet?',
        title: 'Remover tarefa',
      });
      if (!result) return;
      try {
        const data = {
          active: false,
          startDate: currentWeek.start.toFormat('yyyy-MM-dd'),
          userTaskId: userTaskId,
        };
        await axios.post('/timesheet/status', data);
        getTasksByWeek();
      } catch (error) {
        dispatch(
          setAlert({
            message:
              error.response?.data?.message ||
              'Erro ao remover tarefa da timesheet',
            type: 'error',
            open: true,
          })
        );
      }
    }
  };

  const handleRegisterCommentFromTimesheet = (userTaskId) => {
    const totalHoursWeek = totalHoursWeekUserTask(userTaskId);
    if (totalHoursWeek > 0) {
      const task = projects
        .flatMap((p) => p.tasks)
        .find((t) => t.userTaskId === userTaskId);

      const taskWeek = {
        week: currentWeek,
        taskId: task.idTask,
        taskName: task.name,
      };

      setTaskWeekSelected(taskWeek);
      setTaskTimeCommentModalOpen(true);
    } else {
      dispatch(
        setAlert({
          open: true,
          type: 'warning',
          message:
            'Não é permitido editar comentários para tarefas sem lançamentos!',
        })
      );
    }
  };

  const handleCloseTaskTimeComment = () => {
    setTaskTimeCommentModalOpen(false);
    setTaskWeekSelected({});
  };

  return (
    <Container>
      {configModalOpen ? (
        <ConfigWeekModal
          week={currentWeek}
          isOpen={configModalOpen}
          totalHoursWeekUserTask={totalHoursWeekUserTask}
          handleCloseModal={handleCloseConfigWeekModal}
        />
      ) : null}
      {taskTimeCommentModalOpen ? (
        <TaskTimeCommentModal
          taskWeek={taskWeekSelected}
          open={taskTimeCommentModalOpen}
          handleCloseModal={handleCloseTaskTimeComment}
        />
      ) : null}

      <WeekSelector
        week={currentWeek}
        onClickPrevious={goToPrevWeek}
        onClickNext={goToNextWeek}
        onClickConfigWeek={handleConfigWeek}
      />
      <div
        className='global-container'
        style={{ marginBottom: '100px' }}
        title='Bloco da lista de accordions de projetos'
      >
        {projects &&
          projects.map((project, index) => (
            <ThimeSheetAccordion
              title={project.client || ''}
              subtitle={project.project}
              name={project.project}
              key={`${project.project}_${index}`}
              styles={styles}
              defaultExpanded={project.expanded}
              onChange={(e) => handleTimeSheetAccordionChange(e, project.id)}
            >
              {project.tasks &&
                project.tasks.map((task, index) => {
                  return (
                    <TaskCard
                      title='Linha da tarefa'
                      handleRemoveTaskFromTimesheet={
                        handleRemoveTaskFromTimesheet
                      }
                      handleRegisterCommentFromTimesheet={
                        handleRegisterCommentFromTimesheet
                      }
                      key={index}
                      task={{ ...task, clientId: project.client }}
                      onChange={onChangeTasks}
                      daysOfWeek={daysOfWeek}
                      disabledInputMaskHour={appAlertOpen}
                    />
                  );
                })}
            </ThimeSheetAccordion>
          ))}
      </div>
      <HoursFloatingCard entries={calculateTotals(projects)} title='Rodapé' />
    </Container>
  );
};
