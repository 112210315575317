import React from "react";
import Link from "@material-ui/core/Link";
import { Link as RouterLink } from "react-router-dom";

import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import { useLocation } from "react-router-dom";

import "./styles.css";

import { breadcrumbNameMap } from "../../routes";
import { Typography } from "@material-ui/core";

export const RouteBreadcrumb = () => {
  const location = useLocation();
  const pathnames = location.pathname.split("/").filter((x) => x);

  return (
    <div className="bread-container">
      <div className="bread-container-internal">
        <div className="bread-container-items">
          <Breadcrumbs aria-label="breadcrumb">
            <Link component={RouterLink} to="/timesheet">
              Timesheet
            </Link>
            {pathnames.map((value, index) => {
              const last = index === pathnames.length - 1;
              const to = `/${pathnames.slice(0, index + 1).join("/")}`;

              return last ? (
                <Typography color="textPrimary" key={to}>
                  {breadcrumbNameMap[to]}
                </Typography>
              ) : breadcrumbNameMap[to] ? (
                <Link component={RouterLink} to={to} key={to}>
                  {breadcrumbNameMap[to]}
                </Link>
              ) : null;
            })}
          </Breadcrumbs>
        </div>
      </div>
    </div>
  );
};
