const profileMap = new Map([
  ['COLLABORATOR', 'Colaborador'],
  ['ADMINISTRATOR', 'Administrador'],
  ['MANAGER', 'Gerente'],
]);

const numberWeekendsDayMap = ['6', '7'];

const nameDayByNumberDayMap = new Map([
  ['1', 'Segunda'],
  ['2', 'Terça'],
  ['3', 'Quarta'],
  ['4', 'Quinta'],
  ['5', 'Sexta'],
  ['6', 'Sábado'],
  ['7', 'Domingo'],
]);

export const translateNumberDayToNameDay = (numberDay) =>
  nameDayByNumberDayMap.get(numberDay);

export const checkIfTheDayNumberIsWeekend = (numberDay) =>
  numberWeekendsDayMap.includes(numberDay);

export function translateProfiles(profile) {
  return profileMap.get(profile);
}

export function searchIgnoreCaseAndAccents(term, data) {
  const dataNorm = data
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .toLowerCase();
  const termNorm = term
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .toLowerCase();
  return dataNorm.includes(termNorm);
}
