import React, { useEffect, useState } from "react";
import MaterialTable from "material-table";
import { Container, MenuItem, Paper, TextField } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { DateTime } from "luxon";
import axios from "../../Engine/Infra/CustomAxios";
import { useDispatch, useSelector } from "react-redux";
import { setAlert, setLoading } from "../../store/App/App.actions";
import * as tableProperties from "../../Shared/default_table_properties";
import { Button } from "../../Components/Button";
import { useHistory } from "react-router-dom";
import "./styles.css";
import {
  checkIfTheDayNumberIsWeekend,
  translateNumberDayToNameDay,
} from "../../Shared/functions";

const HeaderTitleElement = ({ title, subtitle }) => (
  <div className="header-title-container">
    <span>{title}</span>
    {subtitle && <small>{subtitle}</small>}
  </div>
);

export const ReportUserPending = () => {
  const [form] = useState({
    projects: [],
    users: [],
    from: DateTime.now().minus({ day: 40 }).toFormat("yyyy-MM-dd"),
    to: DateTime.now().toFormat("yyyy-MM-dd"),
    statusUser: null,
  });
  const signedInUser = useSelector((state) => state.signedInUser);
  const history = useHistory();
  if (
    signedInUser.profile !== "ADMINISTRATOR" &&
    signedInUser.profile !== "MANAGER"
  ) {
    history.push("/timesheet");
  }
  const [projects, setProjects] = useState([]);
  const [users, setUsers] = useState([]);
  const dispatch = useDispatch();
  const [columns, setColumns] = useState([]);
  const [data, setData] = useState([]);
  const minDate = DateTime.now().minus({ day: 40 }).toFormat("yyyy-MM-dd");
  const maxDate = DateTime.now().toFormat("yyyy-MM-dd");
  const [showTable, setShowTable] = useState(false);
  const [initialDateError, setInitialDateError] = useState("");

  const buildColumns = (data) => {
    const columns = data[0].data.map((data) => {
      return {
        title: (
          <HeaderTitleElement
            title={DateTime.fromFormat(data.day, "yyyy-MM-dd").toFormat(
              "dd/MM"
            )}
            subtitle={translateNumberDayToNameDay(
              DateTime.fromFormat(data.day, "yyyy-MM-dd").toFormat("c")
            )}
          />
        ),
        field: data.day,
        render: (rowData) => (
          <span>
            {rowData[data.day]?.toLocaleString("pt-br", {
              minimumFractionDigits: 2,
            })}
          </span>
        ),
        cellStyle: (value, rowData) => {
          const journey = rowData.journey;
          const percent = (value / journey) * 100;
          const isWeekend = checkIfTheDayNumberIsWeekend(
            DateTime.fromFormat(data.day, "yyyy-MM-dd").toFormat("c")
          );
          if (!isWeekend && percent <= 90) {
            return {
              backgroundColor: "#f36464",
              color: "#FFF",
              fontSize: "0.800rem",
              textAlign: "center",
            };
          } else if (!isWeekend && percent > 90 && percent <= 100) {
            return {
              backgroundColor: "#6c7dd1",
              color: "#FFF",
              fontSize: "0.800rem",
              textAlign: "center",
            };
          } else if (isWeekend) {
            return {
              backgroundColor: "rgb(243, 100, 100, 0.7)",
              color: "#fff",
              fontSize: "0.800rem",
              textAlign: "center",
            };
          } else {
            return {
              fontSize: "0.800rem",
              textAlign: "center",
            };
          }
        },
      };
    });
    setColumns([
      {
        title: <HeaderTitleElement title="Colaborador" />,
        field: "userName",
        cellStyle: {
          whiteSpace: "nowrap",
          backgroundColor: "rgb(244,244,244)",
          position: "sticky",
          left: 0,
          fontSize: "0.800rem",
        },
      },
      ...columns,
      {
        title: <HeaderTitleElement title="Total" />,
        field: "totalHours",
        cellStyle: {
          backgroundColor: "#000000ba",
          color: "#FFF",
          fontSize: "0.800rem",
        },
      },
    ]);
  };
  const buildData = (data) => {
    const body = data.map((v) => {
      let value = {
        userName: v.userName,
        journey: v.journey,
        totalHours: v.totalHours?.toLocaleString("pt-br", {
          minimumFractionDigits: 2,
        }),
      };
      v.data.forEach((d) => {
        value = { ...value, [d.day]: d.quantity };
      });
      return value;
    });
    setData(body);
  };
  const getProjects = async () => {
    const data = {
      clientId: [],
    };
    const response = await axios.post("reports/projects", data);
    setProjects(response.data);
  };
  const getUsers = async () => {
    const response = await axios.get("users");
    setUsers(response.data.users);
  };
  const handleReport = async () => {
    dispatch(setLoading(true));
    try {
      const data = {
        from: form.from,
        projectId: form.projects,
        to: form.to,
        userId: form.users,
        statusUser: form.statusUser,
      };
      const response = await axios.post(
        `${process.env.REACT_APP_SVC_URL}reports/report/team-pendency`,
        data
      );
      function limpar(x) {
        return x.toLowerCase().normalize("NFD");
      }
      if (response.data.length > 0) {
        buildColumns(
          response.data.sort((a, b) =>
            limpar(a.userName) > limpar(b.userName) ? 1 : -1
          )
        );
        buildData(response.data);
        setShowTable(true);
      } else {
        dispatch(
          setAlert({
            message: "Nenhum registro encontrado",
            type: "warning",
            open: true,
          })
        );
        setShowTable(false);
      }
      dispatch(setLoading(false));
    } catch (error) {
      dispatch(
        setAlert({
          message: error.response?.data?.message || "Erro ao gerar relatório",
          type: "error",
          open: true,
        })
      );
      dispatch(setLoading(false));
    }
  };
  const init = async () => {
    getUsers();
    getProjects();
  };
  useEffect(() => {
    init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container className="main-container-rel-pendencias">
      <Paper className="main-paper">
        <div className="main-form">
          <h3 style={{ color: "#414aba" }}>Relatório de Pendências</h3>
          <div className="form-first-row">
            <Autocomplete
              className="input"
              limitTags={2}
              multiple
              getOptionSelected={(option, value) => option.id === value.id}
              onChange={(item, value) => {
                form.projects = value.map((v) => v.id);
              }}
              options={projects}
              getOptionLabel={(option) => option.name}
              renderInput={(params) => (
                <TextField
                  variant="outlined"
                  label="Time"
                  placeholder="Escolha o time"
                  {...params}
                />
              )}
            />
            <TextField
              className="input"
              variant="outlined"
              label="Data de Início"
              defaultValue={minDate}
              onChange={(event) => {
                form.from = event.target.value;
                if (form.from > form.to) {
                  setInitialDateError(
                    "Data de início deve ser menor que a data fim"
                  );
                } else {
                  setInitialDateError("");
                }
              }}
              error={!!initialDateError}
              helperText={initialDateError}
              type="date"
              InputLabelProps={{
                shrink: true,
              }}
            />
            <TextField
              className="input"
              variant="outlined"
              label="Data de Fim"
              defaultValue={maxDate}
              type="date"
              onChange={(event) => {
                form.to = event.target.value;
                if (form.from > form.to) {
                  setInitialDateError(
                    "Data de início deve ser menor que a data fim"
                  );
                } else {
                  setInitialDateError("");
                }
              }}
              InputLabelProps={{
                shrink: true,
              }}
            />
            <Autocomplete
              className="input"
              limitTags={2}
              multiple
              getOptionSelected={(option, value) => option.id === value.id}
              onChange={(item, value) => {
                form.users = value.map((v) => v.id);
              }}
              options={users}
              getOptionLabel={(option) => option.name}
              renderInput={(params) => (
                <TextField
                  variant="outlined"
                  label="Colaborador"
                  placeholder="Escolha os colaboradores"
                  {...params}
                />
              )}
            />
            <TextField
              className="input"
              select
              label="Status do Time no Projeto"
              placeholder="Escolha o status"
              variant="outlined"
              onChange={(event) => {
                form.statusUser =
                  event.target.value === "todos" ? null : event.target.value;
              }}
            >
              <MenuItem key="todos" value="todos">
                Todos
              </MenuItem>
              <MenuItem key="ativo" value={true}>
                Ativo
              </MenuItem>
              <MenuItem key="inativo" value={false}>
                Inativo
              </MenuItem>
            </TextField>
          </div>
          <div className="button-div">
            <Button onClick={handleReport}>Gerar Relatório</Button>
          </div>
        </div>
      </Paper>
      {showTable ? (
        <MaterialTable
          title=""
          style={{ marginTop: "30px" }}
          columns={columns}
          data={data}
          options={{
            ...tableProperties.options,
            paging: false,
            filtering: false,
            search: false,
            sorting: false,
            headerStyle: {
              ...tableProperties.options.headerStyle,
              position: "sticky",
              top: 0,
              left: 0,
              backgroundColor: "#f1f1f1",
            },
            maxBodyHeight: "650px",
          }}
          localization={tableProperties.localization}
        />
      ) : null}
    </Container>
  );
};
