import React from "react";
import { Container } from "@material-ui/core";
import { CustomModal } from "../CustomModal";
import { ConfigWeekTable } from "../ConfigWeekTable";

export const ConfigWeekModal = ({
  week,
  isOpen,
  handleCloseModal,
  totalHoursWeekUserTask,
}) => {
  const handleModalTitle = () => {
    return `Configuração da semana:   ${week.start.toFormat(
      "dd/MM/yyyy"
    )}  a  ${week.end.toFormat("dd/MM/yyyy")}`;
  };
  return (
    <Container>
      <CustomModal
        title={handleModalTitle()}
        onClose={handleCloseModal}
        open={isOpen}
      >
        <ConfigWeekTable
          week={week}
          totalHoursWeekUserTask={totalHoursWeekUserTask}
        />
      </CustomModal>
    </Container>
  );
};
