import colors from './Colors';
import screenSizes from './ScreenSizes.js';
import GlobalStyles from './GlobalStyles';


export default {
    colors,
    screenSizes,
    GlobalStyles
}

