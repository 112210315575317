import React from 'react';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import './styles.css';

export const Loading = ({ isLoading }) => {
    if (isLoading) {
        return (
            <Backdrop className="backdropCenter" open={true}>
                <Typography variant="h6" gutterBottom={true}>Carregando...</Typography>
                <CircularProgress color="primary" />
            </Backdrop>
        );
    }

    return null;
}