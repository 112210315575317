import { makeStyles } from "@material-ui/core/styles";

const drawerWidth = 300;

export const useStyles = makeStyles((theme) => ({
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerContainer: {
    overflow: "auto",
    position: "relative",
    marginTop: "0.3rem",
    flex: 1,
    "&::-webkit-scrollbar": {
      width: "0.4rem",
      backgroundColor: "rgba(0, 0, 0, 0.08)",
    },
    "&::-webkit-scrollbar-track": {
      background: "transparent",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#414aba",
      borderRadius: "10px",
      background: "transparent",
    },
    "&::wrapper::-webkit-scrollbar-track-piece:end": {
      background: "transparent",
    },
    "&::wrapper::-webkit-scrollbar-track-piece:start": {
      background: "transparent",
    },
    "& .MuiListItemIcon-root": {
      // paddingLeft: '5%'
    },
    "& .MuiCollapse-wrapper .MuiListItemIcon-root": {
      paddingLeft: "5%",
    },
  },
  footer: {
    display: "flex",
    flexFlow: "column",
    justifyContent: "center",
    alignItems: "center",
    bottom: 0,
    width: "100%",
    maxWidth: "100%",
    borderTop: "1px solid #F1F1F1",
  },
  containerLogo: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  logo: {
    height: "100px",
    alignItems: "center",
  },
}));
