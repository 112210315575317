import React, { useState, useEffect } from "react";
import MaterialTable from "material-table";
import * as tableProperties from "../../Shared/default_table_properties";
import { Paper, Switch } from "@material-ui/core";
import { useDispatch } from "react-redux";
import axios from "../../Engine/Infra/CustomAxios";
import { setAlert, setLoading } from "../../store/App/App.actions";
import "./styles.css";

export const ConfigWeekTable = ({ week, totalHoursWeekUserTask }) => {
  const [tasks, setTasks] = useState([]);
  const [replicate, setReplicate] = useState(false);
  const dispatch = useDispatch();
  const columns = [
    {
      title: "Exibir",
      field: "active",
      grouping: false,
      filtering: false,
      render: (rowData) => (
        <Switch
          title={`Botão para ${
            rowData.active ? "desativar" : "ativar"
          } tarefa ${rowData.task} do projeto ${rowData.project}`}
          checked={rowData.active}
          onChange={(event) => handleTaskStatus(rowData)}
          color="primary"
          inputProps={{"data-testid": "ativar-tarefa"}}
        />
      ),
    },
    { title: "Projeto", field: "project", defaultGroupOrder: true },
    { title: "Tarefa", field: "task", grouping: false },
    { title: "Cliente", field: "client" },
  ];
  const handleTaskStatus = async (task) => {
    const hours = totalHoursWeekUserTask(task.userTaskId);
    if (hours > 0) {
      dispatch(
        setAlert({
          open: true,
          type: "warning",
          message: "Não é permitido ocultar tarefas com lançamentos!",
        })
      );
    } else {
      try {
        const data = {
          active: !task.active,
          startDate: week.start.toFormat("yyyy-MM-dd"),
          userTaskId: task.userTaskId,
        };
        const response = await axios.post("/timesheet/status", data);
        if (response.data.success) {
          const index = tasks.findIndex(
            (t) => t.userTaskId === task.userTaskId
          );
          tasks[index].active = !task.active;
          setTasks([...tasks]);
        } else {
          dispatch(
            setAlert({
              message:
                response.data?.message || "Erro ao alterar status da tarefa",
              type: "error",
              open: true,
            })
          );
        }
      } catch (error) {
        dispatch(
          setAlert({
            message:
              error.response?.data?.message ||
              "Erro ao alterar status da tarefa",
            type: "error",
            open: true,
          })
        );
      }
    }
  };

  const handleReplicate = (event) => {
    const data = {
      copy: event.target.checked,
    };
    axios
      .post("/users/copy-timesheet", data)
      .then((_) => {
        setReplicate(data.copy);
      })
      .catch((error) => {
        dispatch(
          setAlert({
            message:
              error.response?.data?.message ||
              "Erro ao copiar timesheet para as próximas semanas",
            type: "error",
            open: true,
          })
        );
      });
  };
  const getTasks = async () => {
    const response = await axios.get(
      "/timesheet/status/" + week.start.toFormat("yyyy-MM-dd")
    );
    setTasks(response.data.tasks);
    setReplicate(response.data.replicate);
  };
  const init = async () => {
    dispatch(setLoading(true));
    await getTasks();
    dispatch(setLoading(false));
  };
  useEffect(() => {
    init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <div className="copy-timesheet-div">
        <Switch
          title={`Botão para ${
            replicate ? "desativar" : "ativar"
          } a replicação de tarefas para a próxima semana`}
          onChange={handleReplicate}
          checked={replicate}
          color="primary"
        />
        <span className="copy-timesheet-text">
          Sempre replicar as tarefas para a semana seguinte.
        </span>
      </div>
      <MaterialTable
        components={{
          Container: (props) => <Paper {...props} elevation={0} />,
        }}
        title=""
        style={{ marginTop: "30px" }}
        columns={columns}
        data={tasks}
        options={{
          ...tableProperties.options,
          pageSize: 5,
          grouping: true,
          search: false,
          toolbar: false,
        }}
        localization={tableProperties.localization}
      />
    </div>
  );
};
