import React, { useEffect, useState } from "react";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import { Button } from "../Button";
// import Button from "@material-ui/core/Button";
import { Container } from "@material-ui/core";
import axios from "../../Engine/Infra/CustomAxios";
import Checkbox from "@material-ui/core/Checkbox";
import { useDispatch } from "react-redux";
import { setAlert, setLoading } from "../../store/App/App.actions";
import { useHistory } from "react-router-dom";
import "./styles.css";
import Autocomplete from "@material-ui/lab/Autocomplete";
import confirmService from "../../Components/ConfirmDialog";

export const ProjectForm = ({ currentProject }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [project, setProject] = useState({
    id: currentProject?.id ?? "",
    description: "",
    name: "",
    clientId: "",
    responsible: [],
    isInnovation: false,
    isOpen: false,
    beginningDate: "",
    endingDate: "",
    recordsReleaseDate: "",
    wasOpened : false,
    businessUnit: "",
    businessUnitId: ""
  });
  const [nameError, setNameError] = useState();
  const [users, setUsers] = useState([]);
  const [clients, setClients] = useState([]);
  const [clientError, setClientError] = useState();
  const [beginningDateError, setBeginningDateError] = useState();
  const [endingDateError, setEndingDateError] = useState();
  const [recordsReleaseDateError, setRecordsReleaseDateError] = useState();
  const [descriptionError, setDescriptionError] = useState();
  const [responsibleError, setResponsibleError] = useState();

  // const [businessUnit, setBusinessUnit] = useState();
  const [businessUnitError, setBusinessUnitError] = useState();
  const [businessUnits, setBusinessUnits] = useState([]);


  const handleInputName = (e) => {
    const val = e.target.value;
    setProject({ ...project, name: val });
    if (!val) {
      setNameError("Campo obrigatório");
      return;
    }
    if (val.length > 60) {
      setNameError("Máximo de 60 caracteres");
      return;
    }

    setNameError("");
  };
  const handleInputClient = (e) => {
    const val = e.target.value;
    setProject({ ...project, clientId: val });
    if (val) {
      setClientError("");
    } else {
      setClientError("Campo obrigatório");
    }
  };

  const handleInputBusinessUnit = (e) => {
    const val = e.target.value;

    setProject({ ...project, businessUnitId: val });
   //(val);
    if (val) {
      setBusinessUnitError("");
    } else {
      setBusinessUnitError("Campo obrigatório");
    }
  };

  const validateReleaseDate = (releaseDate, endingDate, beginningDate) => {
    releaseDate = releaseDate ?? project.recordsReleaseDate;
    endingDate = endingDate ?? project.endingDate;
    beginningDate = beginningDate ?? project.beginningDate;

    if (!releaseDate) {
      setRecordsReleaseDateError("Campo obrigatório");
      return false;
    }
    if (endingDate && releaseDate > endingDate) {
      setRecordsReleaseDateError(
        "Data de liberação do projeto deve ser igual ou anterior a data de término do projeto"
      );
      return false;
    }
    if (releaseDate < beginningDate) {
      setRecordsReleaseDateError(
        "Data de liberação do projeto deve ser igual ou posterior a data de início do projeto"
      );
      return false;
    }
    setRecordsReleaseDateError("");
  };
  const validateEndingDate = (releaseDate, endingDate, beginningDate) => {
    releaseDate = releaseDate ?? project.recordsReleaseDate;
    endingDate = endingDate ?? project.endingDate;
    beginningDate = beginningDate ?? project.beginningDate;

    if (endingDate) {
      if (endingDate <= beginningDate) {
        setEndingDateError(
          "Data de término do projeto deve ser maior que a data de início do projeto"
        );
        return false;
      }
    }
    setEndingDateError("");
  };
  const validateBeginningDate = (releaseDate, endingDate, beginningDate) => {
    releaseDate = releaseDate ?? project.recordsReleaseDate;
    endingDate = endingDate ?? project.endingDate;
    beginningDate = beginningDate ?? project.beginningDate;

    if (!beginningDate) {
      setBeginningDateError("Campo obrigatório");
      return;
    }
    setBeginningDateError("");
  };

  const handleInputBegginingDate = (e) => {
    const val = e.target.value;
    setProject({ ...project, beginningDate: val });
    validateBeginningDate(null, null, val);
    if (project.recordsReleaseDate) {
      validateReleaseDate(null, null, val);
    }
    if (project.endingDate) {
      validateEndingDate(null, null, val);
    }
  };
  const handleInputEndingDate = (e) => {
    const val = e.target.value;
    setProject({ ...project, endingDate: val });
    if (project.recordsReleaseDate) {
      validateReleaseDate(null, val, null);
    }
    validateEndingDate(null, val, null);
  };
  const handleInputRecordsReleaseDate = (e) => {
    const val = e.target.value;
    setProject({ ...project, recordsReleaseDate: val });
    validateEndingDate(val, null, null);
    validateReleaseDate(val, null, null);
  };
  const handleInputDescription = (e) => {
    const val = e.target.value;
    setProject({ ...project, description: val });
    if (!val || val.trim().length === 0) {
      setDescriptionError("Campo obrigatório");
      return;
    }
    if (val.length > 255) {
      setDescriptionError("Máximo de 255 caracteres");
      return;
    }

    setDescriptionError("");
  };
  const handleInputIsInnovation = (e) => {
    const val = e.target.checked;
    setProject({ ...project, isInnovation: val });
  };
  const handleInputIsOpen = (e) => {
    const val = e.target.checked;
    setProject({ ...project, isOpen: val });
  };
  const handleForm = async () => {
    if (project.id) {
      handleUpdateProject();
    } else {
      handleCreateProject();
    }
  };

  const validateForm = () => {
    let valid = true;
    if (!project.name) {
      setNameError("Campo obrigatório");
      valid = false;
    }
    if (project.name.length > 60) {
      setNameError("Máximo de 60 caracteres");
      valid = false;
    }
    if (!project.clientId) {
      setClientError("Campo obrigatório");
      valid = false;
    }
    if (!project.beginningDate) {
      setBeginningDateError("Campo obrigatório");
      valid = false;
    }

    if (project.endingDate && project.endingDate <= project.beginningDate) {
      setEndingDateError(
        "Data de término do projeto deve ser maior que a data de início do projeto"
      );
      valid = false;
    }
    if (!project.recordsReleaseDate) {
      setRecordsReleaseDateError("Campo obrigatório");
      valid = false;
    }
    if (
      project.endingDate &&
      project.recordsReleaseDate &&
      project.recordsReleaseDate > project.endingDate
    ) {
      setRecordsReleaseDateError(
        "Data de liberação do projeto deve ser igual ou anterior a data de término do projeto"
      );
      valid = false;
    }
    if (
      project.recordsReleaseDate &&
      project.recordsReleaseDate < project.beginningDate
    ) {
      setRecordsReleaseDateError(
        "Data de liberação do projeto deve ser igual ou posterior a data de início do projeto"
      );
      valid = false;
    }
    if (!project.description) {
      setDescriptionError("Campo obrigatório");
      valid = false;
    }

    if (!project.businessUnitId) {
      setBusinessUnitError("Campo obrigatório");
      valid = false;
    }

    if (project.description.length > 255) {
      setDescriptionError("Máximo de 255 caracteres");
      valid = false;
    }
    if (!project.responsible || project.responsible.length === 0) {
      setResponsibleError("Campo obrigatório");
      valid = false;
    } else {
      setResponsibleError("");
    }
    return valid;
  };

  const verifyOpenProject = async () => {
    if (project.isOpen) {
      project.wasOpened = true;
      return confirmService.show({
        message: (
          <span>
            Ao salvar um <b>Projeto Aberto</b> todos os colaboradores ativos
            serão incluídos no time automaticamente. Confirma?
          </span>
        ),
        title: "Projeto aberto",
      });
    } else if (project.id !== "" && project.wasOpened) {
      return confirmService.show({
        message: (
          <span>
            Ao desabilitar a opção de <b>Projeto Aberto</b>, todos os
            colaboradores vinculados ao projeto continuarão vinculados ao time.
            Confirma?
          </span>
        ),
        title: "Projeto aberto",
      });
    }
    return true;
  };

  const handleCreateProject = async () => {
    if (!validateForm()) return;
    const confirm = await verifyOpenProject();
    if (!confirm) return;

    dispatch(setLoading(true));
    try {
      
      const projectToSend = {
        ...project,
        businessUnitId: project.businessUnitId, // Salva apenas o ID da unidade selecionada
      };

      const response = await axios.post("/projects", projectToSend);
      if (response?.data.success) {
        dispatch(
          setAlert({
            message: "Projeto adicionado com sucesso",
            type: "success",
            open: true,
          })
        );
        history.push({
          pathname: "/project/view",
          state: response.data.project,
        });
      } else {
        dispatch(
          setAlert({
            message: response.data.message ?? "Erro ao criar projeto",
            type: "error",
            open: true,
          })
        );
      }
    } catch (error) {
      dispatch(
        setAlert({
          message: error.response.data.message ?? "Erro ao criar projeto",
          type: "error",
          open: true,
        })
      );
    }
    dispatch(setLoading(false));
  };
  const handleUpdateProject = async () => {
    if (!validateForm()) return;
    const confirm = await verifyOpenProject();
    if (!confirm) return;

    dispatch(setLoading(true));
    try {
      const response = await axios.put("/projects/" + project.id, project);
      if (response?.data.success) {
        dispatch(
          setAlert({
            message: "Projeto alterado com sucesso",
            type: "success",
            open: true,
          })
        );
      } else {
        dispatch(
          setAlert({
            message: response.data.message ?? "Erro ao alterar projeto",
            type: "error",
            open: true,
          })
        );
      }
    } catch (error) {
      dispatch(
        setAlert({
          message: error.response.data.message ?? "Erro ao alterar projeto",
          type: "error",
          open: true,
        })
      );
    }
    dispatch(setLoading(false));
  };

  useEffect(() => {
    const getClients = async () => {
      const response = await axios.get("/clients");
      setClients(response.data.clients);
    };
  
    const getUsers = async () => {
      const response = await axios.get("/users/project-responsible");
      setUsers(response.data.users);
    };
  
    function limpar(x) {
      return x.toLowerCase().normalize("NFD");
    }
  
    const getProject = async () => {
      dispatch(setLoading(true));
      const response = await axios.get("/projects/" + currentProject.id);
      const project = response.data.project;
      setProject({
        ...project,
        clientId: project.client.id,
        responsible: project.responsible.sort((a, b) =>
          limpar(a.name) > limpar(b.name) ? 1 : -1
        ),
        businessUnitId: project.businessUnit.id,
      });
      dispatch(setLoading(false));
    };
  
    const getBusinessUnits = async () => {
      try {
        const response = await axios.get("/business-units");
        setBusinessUnits(response.data.units);
      } catch (error) {
        console.error("Error fetching business units:", error);
      }
    };
  
    getClients();
    getUsers();
    if (currentProject) {
      getProject();
    }
    getBusinessUnits();
  
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container className="form-container">
      <div className="row">
        <TextField
          disabled={project.status === "INACTIVE"}
          className="width-50"
          required={true}
          variant="outlined"
          label="Nome"
          onChange={handleInputName}
          placeholder="Digite o nome do projeto"
          value={project.name}
          error={!!nameError}
          helperText={nameError}
          inputProps={{"data-testid":"nome-projeto"}} 
        />
      </div>
      <div className="row" style={{ marginTop: "30px" }}>
        <TextField
          disabled={project.status === "INACTIVE"}
          required={true}
          select
          label="Cliente"
          placeholder="Selecione o cliente"
          onChange={handleInputClient}
          variant="outlined"
          fullWidth
          value={project.clientId ?? ""}
          error={!!clientError}
          helperText={clientError}
          data-testid="clientes"
        >
          {clients.map((option) => (
            <MenuItem key={option.id} value={option.id} data-testid={`cliente-${option.name}`}>
              {option.name}
            </MenuItem>
          ))}
        </TextField>
      </div>
          
      <div className="row" style={{ marginTop: "30px" }}>
      <TextField
            required={true}
            disabled={project.status === "INACTIVE"}
            id="outlined-select-currency"
            select
            label="Unidade de Negócio"
            onChange={handleInputBusinessUnit}
            variant="outlined"
            fullWidth
            value={project.businessUnitId ?? ""}
            error={!!businessUnitError}
            helperText={businessUnitError}
          >
            {businessUnits?.map((option) => (
              <MenuItem key={option.id} value={option.id}>
                {option.name}
              </MenuItem>
            ))}
          </TextField>
      </div>


      <div className="row" style={{ marginTop: "50px" }}>
        <TextField
          disabled={project.status === "INACTIVE"}
          className="dates-style"
          required={true}
          variant="outlined"
          onChange={handleInputBegginingDate}
          label="Data de Início"
          fullWidth
          value={project.beginningDate}
          error={!!beginningDateError}
          helperText={beginningDateError}
          id="date"
          type="date"
          InputLabelProps={{
            shrink: true,
          }}
          InputProps={{ inputProps: { max: "5000-12-31", 'data-testid': "data-inicio" } }} 
        />
        <TextField
          disabled={project.status === "INACTIVE"}
          className="dates-style"
          variant="outlined"
          onChange={handleInputEndingDate}
          label="Data de Término"
          fullWidth
          value={project.endingDate}
          error={!!endingDateError}
          helperText={endingDateError}
          id="date"
          type="date"
          InputLabelProps={{
            shrink: true,
          }}
          InputProps={{ inputProps: { max: "5000-12-31", 'data-testid': "data-termino" } }}
        />
        <TextField
          disabled={project.status === "INACTIVE"}
          className="dates-style"
          required={true}
          variant="outlined"
          onChange={handleInputRecordsReleaseDate}
          value={project.recordsReleaseDate}
          label="Data de Liberação"
          fullWidth
          error={!!recordsReleaseDateError}
          helperText={recordsReleaseDateError}
          id="date"
          type="date"
          InputLabelProps={{
            shrink: true,
          }}
          InputProps={{ inputProps: { max: "5000-12-31", 'data-testid': "data-liberacao" } }}
        />
      </div>
      <div style={{ marginTop: "30px" }} className="row">
        <TextField
          disabled={project.status === "INACTIVE"}
          required={true}
          variant="outlined"
          onChange={handleInputDescription}
          multiline={true}
          row={5}
          label="Descrição"
          placeholder="Descreva o projeto"
          fullWidth
          value={project.description}
          error={!!descriptionError}
          helperText={descriptionError}
          inputProps={{"data-testid":"descricao-projeto"}}
        />
      </div>
      <div className="row" style={{ marginTop: "30px" }}>
        <Autocomplete
          limitTags={5}
          multiple
          disabled={project.status === "INACTIVE"}
          value={project.responsible}
          getOptionSelected={(option, value) => option.id === value.id}
          options={users}
          onChange={(event, newValue) => {
            setProject({ ...project, responsible: newValue });
          }}
          getOptionLabel={(option) => option.name}
          style={{ width: 300 }}
          renderInput={(params) => (
            <TextField
              label="Responsável"
              error={!!responsibleError}
              helperText={responsibleError}
              {...params}
              inputProps={{
                ...params.inputProps,
                "data-testid":"responsavel-projeto"
              }} 
            />
          )}
        />
      </div>
      <div style={{ marginTop: "30px" }}>
        <Checkbox
          disabled={project.status === "INACTIVE"}
          checked={project.isInnovation}
          onChange={handleInputIsInnovation}
          color="primary"
          // error={!!isInnovationError}
          // helperText={isInnovationError}
          inputProps={{ "aria-label": "primary checkbox" }}
        />
        Inovação
      </div>
      <div className="field-legend">
        * Aplicável a Lei do Bem - Ela regulamenta e direciona as parcerias que
        podem ser estabelecidas no desenvolvimento de novas tecnologias,
        oferecendo benefícios aos pesquisadores e as empresas que utilizem
        parques tecnológicos e ideias nacionais.
      </div>
      <div style={{ marginTop: "30px" }}>
        <Checkbox
          disabled = {true}
          checked={project.isOpen}
          onChange={handleInputIsOpen}
          color="primary"
          inputProps={{ "aria-label": "primary checkbox" }}
        />
        Projeto Aberto
      </div>
      <div className="field-legend">
        * O Projeto Aberto fica disponível para todos os colaboradores ativos no
        sistema
      </div>
      <div className="div-submit-button">
        {project.status !== "INACTIVE" ? (
          <Button disabled={project.status === "INACTIVE"} onClick={handleForm} data-testid="salvar-projeto">
            Salvar
          </Button>
        ) : null}
      </div>
    </Container>
  );
};
