import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import {Container} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';


const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    outline: 'none',
    width: '750px',
    maxHeight: '90%',
    overflowY: 'auto',
  },
  modalBody: {
    padding: theme.spacing(2, 4, 5),
    // overflowY: 'auto',
    // maxHeight: '650px',
  },
  modalHeader: {
    padding: '10px 32px 10px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderBottom: '1px solid #f5f5f5'
  },
  title: {
    color: theme.palette.primary.main,
    fontSize: '20px',
    fontWeight: '400'
  },
}));
export const CustomModal = ({title, open, onClose, children}) => {
  const classes = useStyles();
  return (
    <Container>
      <Modal 
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={onClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <div className={classes.modalHeader}>
              <span title={title} className={classes.title}>{title}</span>
              <IconButton title="Botão para fechar modal" className="modal-close" onClick={onClose} color="primary"component="span">
                <CloseIcon color="primary"/>
              </IconButton>
            </div>
            <div className={classes.modalBody}>
                {children}
            </div>
          </div>
        </Fade>
      </Modal>
    </Container>
  );
}
