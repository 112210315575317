import styled from "styled-components";

export const Container = styled.div`
  .manage-tasks {
    margin-top: 20px;
  }
`;

export const Project = styled.div`
  margin-top: 15px;
  & > span {
    font-size: 16px;
    font-weight: 500;
  }

  .customer {
    color: ${(props) => props.styles.colors.secondary};
    margin-right: 10px;
    margin-left: 20px;

    @media (max-width: ${(props) => props.styles.screenSizes.desktop}) {
      margin-left: 10px;
    }
  }

  .project {
    color: ${(props) => props.styles.colors.primary};
  }
`;

export const ConfigWeekDiv = styled.div`
  width: 100%;
  text-align: right;
`;
