import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Button } from "../Button";
import { Container, TextField } from "@material-ui/core";
import axios from "../../Engine/Infra/CustomAxios";
import { CustomModal } from "../CustomModal";
import { setAlert, setLoading } from "../../store/App/App.actions";
import { DateTime } from "luxon";
import "./styles.css";

export const TaskCommentModal = ({ currentRecord, open, onClose }) => {
  const MAX_COMMENT_CHARS = 500;
  const regexContainsUrl = RegExp(
    "([a-zA-Z0-9]+://)?([a-zA-Z0-9_]+:[a-zA-Z0-9_]+@)?([a-zA-Z0-9.-]+\\.[A-Za-z]{2,4})(:[0-9]+)?(/.*)?"
  );
  const [form, setForm] = useState({
    comment: "",
  });
  const dispatch = useDispatch();
  const [commentError, setCommentError] = useState("");
  const [modalTitle] = useState(
    `Editar Comentário - ${currentRecord.taskName} (${DateTime.fromFormat(
      currentRecord.realizationDate,
      "yyyy-MM-dd"
    ).toFormat("dd/MM/yyyy")})`
  );

  const handleInputComment = (e) => {
    const val = e.target.value;
    setForm({ ...form, comment: val });
    if (val.length > MAX_COMMENT_CHARS) {
      setCommentError("Campo deve possuir menos de 500 caracteres");
    } else if (regexContainsUrl.test(val)) {
      setCommentError("Campo não pode conter URL's");
    } else {
      setCommentError("");
    }
  };

  const handleForm = async () => {
    if (!validateForm()) return false;

    dispatch(setLoading(true));
    try {
      const data = {
        ...form,
      };
      await axios.put(`time/${currentRecord.recordId}/comment`, data);
      dispatch(
        setAlert({
          message: "Comentário adicionado com sucesso",
          type: "success",
          open: true,
        })
      );
      onClose();
    } catch (error) {
      dispatch(
        setAlert({
          message:
            error.response?.data?.message ?? "Erro ao alterar comentário",
          type: "error",
          open: true,
        })
      );
    }
    dispatch(setLoading(false));
  };

  const validateForm = () => {
    let valid = true;

    if (form.comment?.length > MAX_COMMENT_CHARS) {
      setCommentError("Campo deve possuir menos de 500 caracteres");
      valid = false;
    } else if (regexContainsUrl.test(form.comment)) {
      console.log("Erro contem url");
      setCommentError("Campo não pode conter URL's");
      valid = false;
    } else {
      setCommentError("");
    }

    return valid;
  };

  useEffect(() => {
    const getComment = async () => {
      const response = await axios.get(
        `/time/${currentRecord.recordId}/comment`
      );
      setForm({ comment: response.data.comment });
    };

    getComment();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container>
      <CustomModal title={modalTitle} onClose={onClose} open={open}>
        <div className="row">
          <TextField
            multiline={true}
            fullWidth
            variant="outlined"
            label="Comentário"
            value={form.comment}
            error={!!commentError}
            helperText={commentError}
            onChange={handleInputComment}
            rows={10}
            cols={60}
          />
        </div>
        <div style={{ width: "100%", textAlign: "right" }}>
          <Button
            style={{ marginTop: "20px", marginBottom: "30px" }}
            onClick={handleForm}
          >
            Salvar comentário
          </Button>
        </div>
      </CustomModal>
    </Container>
  );
};
