export const azureConfig = {
    appId: process.env.REACT_APP_AZURE_APP_ID,
    redirectUri: process.env.REACT_APP_AZURE_APP_CALLBACK_REDIRECT,
    directoryId: '25f499d9-9a9d-4289-ba44-e7d1c5358b61',
    postLogoutRedirectUri: process.env.REACT_APP_AZURE_APP_CALLBACK_POST_LOGOUT,
    APPINSIGHTS_KEY: process.env.REACT_APP_AZURE_APP_APPINSIGHTS_KEY || 'xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxxx',
    APP_NAME: process.env.REACT_APP_AZURE_APP_NAME,
    scopes: [
      'user.read'
    ],
    authority:'https://login.microsoftonline.com/25f499d9-9a9d-4289-ba44-e7d1c5358b61/'
  };