import React from 'react';
import { NavLink, useLocation } from 'react-router-dom'
import ListItem from '@material-ui/core/ListItem'

const SimpleMenuItem = ({ onClick, children, path, handlerClick }) => {
  const location = useLocation()
  if (!path) {
    return <ListItem button children={children} onClick={onClick} />
  } else {
    return (
      <ListItem
        button
        children={children}
        onClick={handlerClick}
        selected={path === location.pathname}
        component={React.forwardRef((props, ref) => (
          <NavLink exact {...props} innerRef={ref} onClick={ handlerClick}/>
        ))}
        to={path}
      />
    )
  }
}
 
export default SimpleMenuItem;