import React, { useState } from "react";
import InputMask from "react-input-mask";
import "./styles.css";

export const InputMaskHour = ({
  initialValue,
  onChange,
  disabled,
  iRef,
  position,
  name,
}) => {
  const onBLurInternal = (ev) => {
    onChange(ev.target.value, iRef, position);
  };
  const [value, setValue] = useState(initialValue);
  const onChangeInternal = (ev) => setValue(ev.target.value);

  return (
    <InputMask
      className={value === "00:00" ? "empty-input" : "filled-input"}
      name={`campo-${name}`}
      type="text"
      data-testid="registro-horas"
      mask="99:99"
      disabled={disabled}
      value={value}
      onChange={onChangeInternal}
      onBlur={onBLurInternal}
      required
      ref={iRef}
    />
  );
};
