import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import { Button } from "../Button";
import { Container } from "@material-ui/core";
import axios from "../../Engine/Infra/CustomAxios";
import { CustomModal } from "../CustomModal";
import { setAlert, setLoading } from "../../store/App/App.actions";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import { OutlinedDiv } from "./outlinedDiv";

import "./styles.css";
import Autocomplete from "@material-ui/lab/Autocomplete";

export const ProjectTaskModal = ({
  currentTask,
  open,
  onClose,
  handleTable,
}) => {
  const [form, setForm] = useState({
    name: currentTask?.name ?? "",
    beginningDate: currentTask?.beginningDate ?? "",
    endingDate: currentTask?.endingDate ?? "",
    isBillable: currentTask?.isBillable ?? true,
    isWarning: currentTask?.isWarning ?? false,
    isExtraHour: currentTask?.isExtraHour ?? false,
    isInnovation: currentTask?.isInnovation ?? false,
    isRework: currentTask?.isRework ?? false,
    openTask: currentTask?.openTask ?? false,
    module: currentTask?.module ?? "",
    taskTypeId: currentTask?.taskTypeId ?? "",
    team: currentTask?.team ?? [],
    requirement: currentTask?.requirement ?? "",
    history: currentTask?.history ?? "",
    timePlaned: currentTask?.timePlaned ?? "",
    grouper: currentTask?.grouper ?? "",
    profile: currentTask?.profile ?? "",
    clientArea: currentTask?.clientArea ?? "",
  });

  const dispatch = useDispatch();

  const [users, setUsers] = useState([]);
  const [taskTypes, setTaskTypes] = useState([]);

  const [nameError, setNameError] = useState("");
  const [beginningDateError, setBeginningDateError] = useState("");
  const [endingDateError, setEndingDateError] = useState("");
  const [teamError, setTeamError] = useState("");
  const [moduleError, setModuleError] = useState("");
  const [requirementError, setRequirementError] = useState("");
  const [historyError, setHistoryError] = useState("");
  const [grouperError, setGrouperError] = useState("");
  const [profileError, setProfileError] = useState("");
  const [clientAreaError, setClientAreaError] = useState("");
  const [timePlanedError, setTimeplanedError] = useState("");
  const [realizationTime, setRealizationTime] = useState(0);

  const [modalTitle] = useState("Editar Tarefa");

  const handleInputName = (e) => {
    const val = e.target.value;
    setForm({ ...form, name: val });
    if (val) {
      setNameError("");
    } else {
      setNameError("Campo obrigatório");
    }
  };
  const handleInputBeginningDate = (e) => {
    const val = e.target.value;
    setForm({ ...form, beginningDate: val });
    if (val) {
      setBeginningDateError("");
    } else {
      setBeginningDateError("Campo obrigatório");
    }
  };
  const handleInputEndingDate = (e) => {
    const val = e.target.value;
    setForm({ ...form, endingDate: val });
    if (val && form.beginningDate > val) {
      setEndingDateError("A data fim deve ser maior que a data de início");
    } else {
      setEndingDateError("");
    }
  };
  const handleInputModule = (e) => {
    const val = e.target.value;
    setForm({ ...form, module: val });
    if (val.length > 50) {
      setModuleError("O campo deve ter no máximo 50 caracteres");
    } else {
      setModuleError("");
    }
  };
  const handleInputRequirement = (e) => {
    const val = e.target.value;
    setForm({ ...form, requirement: val });
    if (val.length > 100) {
      setRequirementError("O campo deve ter no máximo 100 caracteres");
    } else {
      setRequirementError("");
    }
  };
  const handleInputHistory = (e) => {
    const val = e.target.value;
    setForm({ ...form, history: val });
    if (val.length > 2000) {
      setHistoryError("O campo deve ter no máximo 2000 caracteres");
    } else {
      setHistoryError("");
    }
  };
  const handleInputGrouper = (e) => {
    const val = e.target.value;
    setForm({ ...form, grouper: val });
    if (val.length > 50) {
      setGrouperError("O campo deve ter no máximo 50 caracteres");
    } else {
      setGrouperError("");
    }
  };
  const handleInputProfile = (e) => {
    const val = e.target.value;
    setForm({ ...form, profile: val });
    if (val.length > 100) {
      setProfileError("O campo deve ter no máximo 100 caracteres");
    } else {
      setProfileError("");
    }
  };
  const handleInputClientArea = (e) => {
    const val = e.target.value;
    setForm({ ...form, clientArea: val });
    if (val.length > 50) {
      setClientAreaError("O campo deve ter no máximo 100 caracteres");
    } else {
      setClientAreaError("");
    }
  };

  const handleInputTimePlaned = (e) => {
    const val = e.target.value;
    setForm({ ...form, timePlaned: val });
    if (val < 0) {
      setTimeplanedError("O campo não pode ser negativo");
    } else {
      setTimeplanedError("");
    }
  };

  const handleForm = async () => {
    if (!validateForm()) return false;

    dispatch(setLoading(true));
    try {
      const data = {
        ...form,
        id: currentTask.id,
        projectId: currentTask.projectId,
        team: form.openTask.toString() === "true" ? [] : form.team,
      };
      const response = await axios.put(`tasks/${data.id}`, data);
      dispatch(
        setAlert({
          message: "Tarefa alterada com sucesso",
          type: "success",
          open: true,
        })
      );
      onClose();
      handleTable({
        ...response.data.task,
        projectId: currentTask.projectId,
        team: data.team,
      });
    } catch (error) {
      dispatch(
        setAlert({
          message: error.response?.data?.message ?? "Erro ao alterar tarefa",
          type: "error",
          open: true,
        })
      );
    }
    dispatch(setLoading(false));
  };

  const validateForm = () => {
    let valid = true;

    if (!form.openTask && !form.team?.length > 0) {
      setTeamError("Selecione pelo menos um colaborador");
      valid = false;
    } else {
      setTeamError("");
    }
    if (!form.name) {
      setNameError("Campo obrigatório");
      valid = false;
    } else {
      setNameError("");
    }
    if (!form.beginningDate) {
      setBeginningDateError("Campo obrigatório");
      valid = false;
    } else {
      setBeginningDateError("");
    }

    if (form.endingDate && form.beginningDate > form.endingDate) {
      setEndingDateError("A data fim deve ser maior que a data de início");
      valid = false;
    } else {
      setEndingDateError("");
    }

    if (form.module.length > 50) {
      setModuleError("O campo deve ter no máximo 50 caracteres");
      valid = false;
    } else {
      setModuleError("");
    }
    if (form.requirement.length > 100) {
      setRequirementError("O campo deve ter no máximo 100 caracteres");
      valid = false;
    } else {
      setRequirementError("");
    }
    if (form.history.length > 2000) {
      setHistoryError("O campo deve ter no máximo 2000 caracteres");
      valid = false;
    } else {
      setHistoryError("");
    }
    if (form.grouper.length > 100) {
      setGrouperError("O campo deve ter no máximo 50 caracteres");
      valid = false;
    } else {
      setGrouperError("");
    }
    if (form.profile.length > 100) {
      setProfileError("O campo deve ter no máximo 100 caracteres");
      valid = false;
    } else {
      setProfileError("");
    }
    if (form.clientArea.length > 100) {
      setClientAreaError("O campo deve ter no máximo 100 caracteres");
      valid = false;
    } else {
      setClientAreaError("");
    }

    if (form.timePlaned < 0) {
      setTimeplanedError("O campo não pode ser negativo");
      valid = false;
    } else {
      setTimeplanedError("");
    }

    return valid;
  };

  useEffect(() => {
    function limpar(x) {
      return x.toLowerCase().normalize("NFD");
    }
    const getUsers = async () => {
      const response = await axios.get(
        `/projects/${currentTask.projectId}/users/active`
      );
      setUsers(
        response.data.users.sort((a, b) =>
          limpar(a.name) > limpar(b.name) ? 1 : -1
        ) ?? []
      );
    };
    const getTaskTypes = async () => {
      const response = await axios.get("/tasks/task-type");
      
      setTaskTypes(response.data.types ?? []);
    };


    const getTimeRecordTask = async () => {
      const response = await axios.get(`/tasks/${currentTask.id}`);
      setRealizationTime(response.data.task.realizationTime );
    }

    getTaskTypes();
    getUsers();
    getTimeRecordTask();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container>
      <CustomModal title={modalTitle} onClose={onClose} open={open}>
        <div className="row">
          <TextField
            disabled
            value={currentTask.sequence}
            className="sequencia-input"
            variant="outlined"
            label="Ordem"
          />
        </div>
        <div className="row">
          <TextField
            fullWidth
            value={form.name}
            required={true}
            variant="outlined"
            label="Nome da Tarefa"
            onChange={handleInputName}
            error={!!nameError}
            helperText={nameError}
          />
        </div>
        <div className="row">
          <TextField
            style={{ flex: 1 }}
            variant="outlined"
            value={form.beginningDate}
            label="Data de Início"
            required={true}
            fullWidth
            id="date"
            type="date"
            onChange={handleInputBeginningDate}
            error={!!beginningDateError}
            helperText={beginningDateError}
            InputLabelProps={{
              shrink: true,
            }}
            InputProps={{ inputProps: { max: "5000-12-31" } }}
          />
          <TextField
            style={{ flex: 1 }}
            variant="outlined"
            label="Data de Fim"
            value={form.endingDate}
            onChange={handleInputEndingDate}
            fullWidth
            id="date"
            type="date"
            error={!!endingDateError}
            helperText={endingDateError}
            InputLabelProps={{
              shrink: true,
            }}
            InputProps={{ inputProps: { max: "5000-12-31" } }}
          />
        </div>
        <div className="row">
          <TextField
            select
            value={form.taskTypeId ?? ""}
            label="Tipo de Tarefa"
            onChange={(e) => setForm({ ...form, taskTypeId: e.target.value })}
            variant="outlined"
            fullWidth
          >
            {taskTypes.map((t) => (
              <MenuItem key={t.id} value={t.id}>
                {t.name}
              </MenuItem>
            ))}
          </TextField>
        </div>
        <div className="row">
          <OutlinedDiv label="Faturável">
            <FormControl component="fieldset">
              <RadioGroup
                row
                aria-label="position"
                name="position"
                defaultValue={form.isBillable.toString()}
                value={form.isBillable.toString()}
                onChange={(event) =>
                  setForm({
                    ...form,
                    isBillable: event.target.value === "true",
                  })
                }
              >
                <FormControlLabel
                  value="true"
                  control={<Radio color="primary" />}
                  label="Sim"
                  labelPlacement="end"
                />
                <FormControlLabel
                  value="false"
                  control={<Radio color="primary" />}
                  label="Não"
                  labelPlacement="end"
                />
              </RadioGroup>
            </FormControl>
          </OutlinedDiv>
          <OutlinedDiv label="Sobreaviso">
            <FormControl component="fieldset">
              <RadioGroup
                row
                aria-label="position"
                name="position"
                defaultValue={form.isWarning.toString()}
                value={form.isWarning.toString()}
                onChange={(event) =>
                  setForm({ ...form, isWarning: event.target.value === "true" })
                }
              >
                <FormControlLabel
                  value="true"
                  control={<Radio color="primary" />}
                  label="Sim"
                  labelPlacement="end"
                />
                <FormControlLabel
                  value="false"
                  control={<Radio color="primary" />}
                  label="Não"
                  labelPlacement="end"
                />
              </RadioGroup>
            </FormControl>
          </OutlinedDiv>
          <OutlinedDiv label="Hora Extra">
            <FormControl component="fieldset">
              <RadioGroup
                row
                aria-label="position"
                name="position"
                defaultValue={form.isExtraHour.toString()}
                value={form.isExtraHour.toString()}
                onChange={(event) =>
                  setForm({
                    ...form,
                    isExtraHour: event.target.value === "true",
                  })
                }
              >
                <FormControlLabel
                  value="true"
                  control={<Radio color="primary" />}
                  label="Sim"
                  labelPlacement="end"
                />
                <FormControlLabel
                  value="false"
                  control={<Radio color="primary" />}
                  label="Não"
                  labelPlacement="end"
                />
              </RadioGroup>
            </FormControl>
          </OutlinedDiv>
          <OutlinedDiv label="Inovação">
            <FormControl component="fieldset">
              <RadioGroup
                row
                aria-label="position"
                name="position"
                defaultValue={form.isInnovation.toString()}
                value={form.isInnovation.toString()}
                onChange={(event) =>
                  setForm({
                    ...form,
                    isInnovation: event.target.value === "true",
                  })
                }
              >
                <FormControlLabel
                  value="true"
                  control={<Radio color="primary" />}
                  label="Sim"
                  labelPlacement="end"
                />
                <FormControlLabel
                  value="false"
                  control={<Radio color="primary" />}
                  label="Não"
                  labelPlacement="end"
                />
              </RadioGroup>
            </FormControl>
          </OutlinedDiv>
          <OutlinedDiv label="Retrabalho">
            <FormControl component="fieldset">
              <RadioGroup
                row
                aria-label="position"
                name="position"
                defaultValue={form.isRework.toString()}
                value={form.isRework.toString()}
                onChange={(event) =>
                  setForm({ ...form, isRework: event.target.value === "true" })
                }
              >
                <FormControlLabel
                  value="true"
                  control={<Radio color="primary" />}
                  label="Sim"
                  labelPlacement="end"
                />
                <FormControlLabel
                  value="false"
                  control={<Radio color="primary" />}
                  label="Não"
                  labelPlacement="end"
                />
              </RadioGroup>
            </FormControl>
          </OutlinedDiv>
        </div>
        <div className="row-open-task">
          <OutlinedDiv label="Tarefa Aberta">
            <FormControl component="fieldset">
              <RadioGroup
                row
                aria-label="position"
                name="position"
                defaultValue={form.openTask.toString()}
                value={form.openTask.toString()}
                onChange={(event) =>
                  setForm({ ...form, openTask: event.target.value === "true" })
                }
              >
                <FormControlLabel
                  value="true"
                  control={<Radio color="primary" />}
                  label="Sim"
                  labelPlacement="end"
                />
                <FormControlLabel
                  value="false"
                  control={<Radio color="primary" />}
                  label="Não"
                  labelPlacement="end"
                />
              </RadioGroup>
            </FormControl>
          </OutlinedDiv>
          {form.openTask ? (
            <OutlinedDiv
              style={{ marginLeft: "25px" }}
              label="Colaboradores da Tarefa"
            >
              <span>Todos os colaboradores</span>
            </OutlinedDiv>
          ) : (
            <Autocomplete
              style={{ marginLeft: "25px" }}
              limitTags={2}
              multiple
              defaultValue={form.team}
              getOptionSelected={(option, value) => option.id === value.id}
              onChange={(item, value) => setForm({ ...form, team: value })}
              options={users}
              getOptionLabel={(option) => option.name}
              renderInput={(params) => (
                <TextField
                  label="Colaboradores da Tarefa"
                  variant="outlined"
                  error={!!teamError}
                  helperText={teamError}
                  {...params}
                />
              )}
            />
          )}
        </div>
        <div className="row">
          <TextField
            fullWidth
            variant="outlined"
            label="Módulo"
            value={form.module}
            error={!!moduleError}
            helperText={moduleError}
            onChange={handleInputModule}
          />
        </div>
        <div className="row">
          <TextField
            fullWidth
            variant="outlined"
            label="Requisito"
            value={form.requirement}
            error={!!requirementError}
            helperText={requirementError}
            onChange={handleInputRequirement}
          />
        </div>
        <div className="row">
          <TextField
            fullWidth
            variant="outlined"
            multiline={true}
            label="Histórico"
            value={form.history}
            error={!!historyError}
            helperText={historyError}
            onChange={handleInputHistory}
          />
        </div>
        <div className="row">
          <TextField
            style={{ flex: 1 }}
            variant="outlined"
            label="Horas planejadas"
            fullWidth
            type="number"
            error={!!timePlanedError}
            helperText={timePlanedError}
            value={form.timePlaned}
            onChange={handleInputTimePlaned}
          />
          <TextField
            style={{ flex: 1 }}
            variant="outlined"
            label="Horas realizadas"
            disabled
            value={realizationTime ?? 0}
            fullWidth
            type="number"
          />
        </div>
        <div className="row">
          <TextField
            fullWidth
            variant="outlined"
            label="Agrupador"
            value={form.grouper}
            error={!!grouperError}
            helperText={grouperError}
            onChange={handleInputGrouper}
          />
        </div>
        <div className="row">
          <TextField
            fullWidth
            variant="outlined"
            label="Perfil"
            value={form.profile}
            error={!!profileError}
            helperText={profileError}
            onChange={handleInputProfile}
          />
        </div>
        <div className="row">
          <TextField
            fullWidth
            variant="outlined"
            label="Área do cliente"
            value={form.clientArea}
            error={!!clientAreaError}
            helperText={clientAreaError}
            onChange={handleInputClientArea}
          />
        </div>
        <div style={{ width: "100%", textAlign: "right" }}>
          <Button
            style={{ marginTop: "20px", marginBottom: "30px" }}
            onClick={handleForm}
          >
            Salvar tarefa
          </Button>
        </div>
      </CustomModal>
    </Container>
  );
};
