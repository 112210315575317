import React, { createRef } from "react";
import PropTypes from "prop-types";
import MaterialTable, { MTableToolbar } from "material-table";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";

import "./styles.css";

import * as tableProperties from "../../Shared/default_table_properties";
import {
  TableIcons,
  TableTextField,
  TableCustomFilterDatePicker,
} from "../MaterialTable/index";
import { qtyToTime } from "../../Engine/Infra/TimeConverter";

export const ProjectTaskTimeTable = ({ records, actions, nameTask }) => {
  const searchRef = new createRef();
  const handleOnchangeSearch = (value) =>
    searchRef.current.onSearchChange(value);

  const columns = [
    {
      title: "Data",
      field: "realizationDate",
      filterComponent: (props) => (
        <TableCustomFilterDatePicker {...props} id="field-filter-data-inicio" />
      ),
    },
    {
      title: "Colaborador",
      field: "userName",
      filterComponent: (props) => (
        <TableTextField
          className="input-filter"
          id="field-filter-nome"
          variant="outlined"
          size="small"
          onChange={(event) => {
            props.onFilterChanged(
              props.columnDef.tableData.id,
              event.target.value
            );
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
      ),
    },
    {
      title: "Quantidade de Horas",
      field: "time",
      render: (rowData) => qtyToTime(rowData.time),
      filtering: false,
      filterComponent: (props) => (
        <TableTextField
          className="input-filter"
          id="field-filter-nome"
          variant="outlined"
          size="small"
          type="number"
          onChange={(event) => {
            props.onFilterChanged(
              props.columnDef.tableData.id,
              event.target.value
            );
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
      ),
    },
    {
      title: "Comentário",
      field: "comment",
      filterComponent: (props) => (
        <TableTextField
          className="input-filter"
          id="field-filter-nome"
          variant="outlined"
          size="small"
          onChange={(event) => {
            props.onFilterChanged(
              props.columnDef.tableData.id,
              event.target.value
            );
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
      ),
    },
  ];
  return (
    <MaterialTable
      title=""
      columns={columns}
      data={records}
      icons={TableIcons}
      actions={actions}
      components={{
        Toolbar: (props) => (
          <div className="container-custom-toolbar">
            <h3>{nameTask}</h3>
            <TableTextField
              fullWidth
              placeholder="Pesquise aqui"
              variant="outlined"
              size="small"
              style={{}}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
              onChange={(event) => handleOnchangeSearch(event.target.value)}
            />
            <div className="custom-toolbar">
              <MTableToolbar ref={searchRef} {...props} />
            </div>
          </div>
        ),
      }}
      options={{
        ...tableProperties.options,
        search: false,
        headerStyle: {
          ...tableProperties.options.headerStyle,
        },
        searchFieldVariant: "outlined",
        searchFieldStyle: {
          background: "red",
        },
      }}
      localization={tableProperties.localization}
    />
  );
};

ProjectTaskTimeTable.propTypes = {
  records: PropTypes.array.isRequired,
  actions: PropTypes.array.isRequired,
  nameTask: PropTypes.string.isRequired,
};
