import { useState } from "react";

const menssageErrors = {
  required: "Campo obrigatório",
  timeZero: "O valor da hora não pode ser 00:00",
  justificationtMaxCaracter: "Campo de possuir até 500 caracters",
};

const useFormValidate = (
  initialValues,
  optionsValidate = {},
  callbackSuccess = null
) => {
  const [values, setValues] = useState(initialValues);
  const [errors, setErrors] = useState({});

  const validateForm = () => {
    const newErrors = {};
    for (const key in values) {
      const error = validateFields(key, values[key]);
      if (error) newErrors[key] = error;
    }
    return newErrors;
  };

  const validateFields = (name, value) => {
    switch (name) {
      case "userId":
        if (!value) return menssageErrors.required;
        if (
          value &&
          optionsValidate[name] &&
          !optionsValidate[name].custom.isValidate(value)
        )
          return optionsValidate[name].custom.menssage;
        break;
      case "realizationDate":
        if (!value) return menssageErrors.required;
        if (
          value &&
          optionsValidate[name] &&
          !optionsValidate[name].custom.isValidate(value)
        ) {
          return optionsValidate[name].custom.menssage;
        }
        break;
      case "time":
        if (!value) return menssageErrors.required;
        if (value && value === "00:00") return menssageErrors.timeZero;
        if (
          value &&
          optionsValidate[name] &&
          !optionsValidate[name].custom.isValidate(value)
        ) {
          return optionsValidate[name].custom.menssage;
        }

        break;
      case "justification":
        if (value && value.length > 500)
          return menssageErrors.justificationtMaxCaracter;
        if (
          value &&
          optionsValidate[name] &&
          !optionsValidate[name].custom.isValidate(value)
        ) {
          return optionsValidate[name].custom.menssage;
        }
        break;

      default:
        return null;
    }
  };

  const handleChange = (event) => {
    if (event.persist) event.persist();
    const { name, value } = event.target;
    setValues({ ...values, [name]: value });
  };

  const handleSubmit = (event) => {
    if (event) event.preventDefault();
    const _errors = validateForm();
    if (Object.keys(_errors).length > 0) {
      setErrors(_errors);
      return;
    } else {
      setErrors({});
      if (callbackSuccess) callbackSuccess(values);
    }
  };

  return {
    values,
    errors,
    handleChange,
    handleSubmit,
  };
};

export default useFormValidate;
